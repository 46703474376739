import { Icon } from '../icon';

export const iconsInteractions: Icon[] = [
  {path: 'icons/interactions/tap.svg', name: 'tap'},
  {path: 'icons/interactions/cursor-alt.svg', name: 'cursor-alt'},
  {path: 'icons/interactions/cursor-alt-click-2.svg', name: 'cursor-alt-click-2'},
  {path: 'icons/interactions/cursor.svg', name: 'cursor'},
  {path: 'icons/interactions/cursor-click-2.svg', name: 'cursor-click-2'},
  {path: 'icons/interactions/cursor-alt-click-3.svg', name: 'cursor-alt-click-3'},
  {path: 'icons/interactions/cursor-click-1.svg', name: 'cursor-click-1'},
  {path: 'icons/interactions/cursor-click-2.svg', name: 'cursor-click-2'},
  {path: 'icons/interactions/cursor-alt-click-1.svg', name: 'cursor-alt-click-1'},
  {path: 'icons/interactions/maximize.svg', name: 'maximize'},
  {path: 'icons/interactions/cursor-box.svg', name: 'cursor-box'},
  {path: 'icons/interactions/intersect-1.svg', name: 'intersect-1'},
  {path: 'icons/interactions/diagonal-expand-box.svg', name: 'diagonal-expand-box'},
  {path: 'icons/interactions/aim.svg', name: 'aim'},
  {path: 'icons/interactions/intersect-2.svg', name: 'intersect-2'},
  {path: 'icons/interactions/diagonal-expand-box-alt.svg', name: 'diagonal-expand-box-alt'},
  {path: 'icons/interactions/bounding-box.svg', name: 'bounding-box'},
  {path: 'icons/interactions/intersect-3.svg', name: 'intersect-3'},
  {path: 'icons/interactions/diagonal-contract-box.svg', name: 'diagonal-contract-box'},
  {path: 'icons/interactions/bounding-box-minus.svg', name: 'bounding-box-minus'},
  {path: 'icons/interactions/scale-horizontal.svg', name: 'scale-horizontal'},
  {path: 'icons/interactions/diagonal-contract-box-alt.svg', name: 'diagonal-contract-box-alt'},
  {path: 'icons/interactions/bounding-box-plus.svg', name: 'bounding-box-plus'},
  {path: 'icons/interactions/scale-vertical.svg', name: 'scale-vertical'},
  {path: 'icons/interactions/expand.svg', name: 'expand'},
  {path: 'icons/interactions/bounding-box-close.svg', name: 'bounding-box-close'},
  {path: 'icons/interactions/scale-horizontal-alt.svg', name: 'scale-horizontal-alt'},
  {path: 'icons/interactions/expand-left.svg', name: 'expand-left'},
  {path: 'icons/interactions/bounding-box-check.svg', name: 'bounding-box-check'},
  {path: 'icons/interactions/contract-horizontal.svg', name: 'contract-horizontal'},
  {path: 'icons/interactions/expand-right.svg', name: 'expand-right'},
  {path: 'icons/interactions/expand-alt.svg', name: 'expand-alt'},
  {path: 'icons/interactions/power-button.svg', name: 'power-button'},
  {path: 'icons/interactions/crop.svg', name: 'crop'},
  {path: 'icons/interactions/contract-1.svg', name: 'contract-1'},
  {path: 'icons/interactions/power-button-alt.svg', name: 'power-button-alt'},
  {path: 'icons/interactions/bounding-circle.svg', name: 'bounding-circle'},
  {path: 'icons/interactions/scale.svg', name: 'scale'},
  {path: 'icons/interactions/contract-2.svg', name: 'contract-2'},
  {path: 'icons/interactions/buttons-box.svg', name: 'buttons-box'},
  {path: 'icons/interactions/buttons.svg', name: 'buttons'},
  {path: 'icons/interactions/contract-3.svg', name: 'contract-3'},
  {path: 'icons/interactions/buttons-box-alt.svg', name: 'buttons-box-alt'},
  {path: 'icons/interactions/buttons-alt.svg', name: 'buttons-alt'},
  {path: 'icons/interactions/minimize.svg', name: 'minimize'},
  {path: 'icons/interactions/buttons-circle.svg', name: 'buttons-circle'},
  {path: 'icons/interactions/plus-minus-box.svg', name: 'plus-minus-box'}
];
