export interface Task {
  id: number;
  state: TaskStep;
  name: string;
  horseName?: string;
  date: Date;
}

export enum TaskStep {
  "todo" = "todo",
  "done" = "done"
}
