import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from '@env/environment';
import { Barn } from '@btr/modules/barn/models/barn.model';

@Injectable({providedIn: 'root'})
export class BarnService {
  constructor(private http: HttpClient) {}

  loadAll(): Observable<Barn[]> {
    return this.http.get<Barn[]>(`${environment.api()}/barn`);
  }

  loadMy(barnId: number): Observable<Barn> {
    return this.http.get<Barn>(`${environment.api()}/barn/${barnId}`);
  }

  save(barn: Barn): Observable<Barn> {
    return this.http.patch<Barn>(`${environment.api()}/barn/${barn.id}`, barn);
  }
}
