import { TaskAction, TaskTypes } from './task.action';
import { Task } from '@btr/modules/task/models/task.model';

export interface TaskState {
  tasks: Task[];
  task: Task;
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  saved: boolean;
}

export const initialState: TaskState = {
  tasks: [],
  task: null,
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
};

export function taskReducer(state: TaskState = initialState, action: TaskAction) {
  switch (action.type) {
    case TaskTypes.LoadAll: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case TaskTypes.LoadAllSuccess: {
      return {
        ...state,
        tasks: action.payload,
        loading: false,
        loaded: true
      };
    }
    case TaskTypes.LoadAllError: {
      return {
        ...state,
        tasks: null,
        loading: false,
        loaded: false
      };
    }

    case TaskTypes.LoadOne: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case TaskTypes.LoadOneSuccess: {
      return {
        ...state,
        task: action.payload,
        loading: false,
        loaded: true
      };
    }
    case TaskTypes.LoadOneError: {
      return {
        ...state,
        task: null,
        loading: false,
        loaded: false
      };
    }

    case TaskTypes.Save: {
      return {
        ...state,
        saving: true,
        saved: false
      };
    }
    case TaskTypes.SaveSuccess: {
      return {
        ...state,
        saving: false,
        saved: true
      };
    }
    case TaskTypes.SaveError: {
      return {
        ...state,
        saving: false,
        saved: false
      };
    }

    default: {
      return state;
    }
  }
}
