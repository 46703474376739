import { Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { of } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';

@Component({
  selector: 'kit-date-picker',
  templateUrl: './date-picker.component.html'
})
export class DatePickerComponent implements ControlValueAccessor {
  @Input() label: string = '';
  @Input() min: Date = null;
  @Input() max: Date = null;
  @Input() placeholder: string = '____/__/__';
  @Input() clear: boolean = true;
  @Output() onSelect: EventEmitter<Date> = new EventEmitter();

  fc: UntypedFormControl = new UntypedFormControl();

  state: 'default' | 'disabled' = 'default';

  onChange = (value: string) => {
  };
  onTouched = () => {
  };

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    if (this.ngControl) {
      of(this.ngControl.control).pipe(skipWhile(fc => !fc), take(1)).subscribe(fc => this.fc = fc as UntypedFormControl);
    }
  }

  calendrierOnly(event: MouseEvent, datePicker: any) {
    this.state = 'default';
    event.stopPropagation();
    datePicker.open();
  }

  writeValue(input: any) {
    this.onSelect.emit(input);
  }

  dateSelected(input: any) {
    if (input?.value instanceof Date) {
      this.fc.patchValue(input?.value, {emitEvent: false});
    }
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.state = 'disabled';
    } else {
      this.state = 'default';
    }
  }

  onClear() {
    if (this.fc.enabled) {
      this.fc.patchValue(null);
    }
  }
}
