import { NoteAction, NoteTypes } from './note.action';
import { Note } from '@btr/modules/note/models/note.model';

export interface NoteState {
  notes: Note[];
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  saved: boolean;
}

export const initialState: NoteState = {
  notes: [],
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
};

export function noteReducer(state: NoteState = initialState, action: NoteAction) {
  switch (action.type) {
    case NoteTypes.LoadAllByHorse: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case NoteTypes.LoadAllByHorseSuccess: {
      return {
        ...state,
        notes: action.payload,
        loading: false,
        loaded: true
      };
    }
    case NoteTypes.LoadAllByHorseError: {
      return {
        ...state,
        notes: null,
        loading: false,
        loaded: false
      };
    }

    case NoteTypes.Save: {
      return {
        ...state,
        saving: true,
        saved: false
      };
    }
    case NoteTypes.SaveSuccess: {
      return {
        ...state,
        // notes: [...state.notes, action.payload], // Reload instead
        saving: false,
        saved: true
      };
    }
    case NoteTypes.SaveError: {
      return {
        ...state,
        saving: false,
        saved: false
      };
    }

    case NoteTypes.Delete: {
      return {
        ...state,
        saving: true,
        saved: false
      };
    }
    case NoteTypes.DeleteSuccess: {
      return {
        ...state,
        saving: false,
        saved: true
      };
    }
    case NoteTypes.DeleteError: {
      return {
        ...state,
        saving: false,
        saved: false
      };
    }

    default: {
      return state;
    }
  }
}
