export interface Consumption {
  id: number;
  date: Date;
  amount: number;
  sensorId: number;
}

export interface Consumptions {
  alarm: boolean;
  consumptions: Consumption[];
}

export interface ConsumptionMode {
  horseId: string | number;
  mode: FilterMode;
}

export interface ConsumptionsSummary {
  today: ConsumptionSummaryDay;
  yesterday: ConsumptionSummaryDay;
  week: ConsumptionSummaryWeek;
}

export interface ConsumptionSummaryDay {
  sum?: number;
  percentageSinceTheDayBefore?: number;
}

export interface ConsumptionSummaryWeek {
  average?: number;
  percentageSinceLastWeek?: number;
}

export enum FilterMode {
  "TODAY" = "TODAY",
  "WEEK" = "WEEK",
  "YESTERDAY" = "YESTERDAY",
}
