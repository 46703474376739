import { Icon } from '../icon';

export const iconsNature: Icon[] = [
  {path: 'icons/nature/cactus.svg', name: 'cactus'},
  {path: 'icons/nature/tent.svg', name: 'tent'},
  {path: 'icons/nature/mushroom.svg', name: 'mushroom'},
  {path: 'icons/nature/leaf.svg', name: 'leaf'},
  {path: 'icons/nature/recycle.svg', name: 'recycle'},
  {path: 'icons/nature/plant.svg', name: 'plant'},
  {path: 'icons/nature/new-moon.svg', name: 'new-moon'},
  {path: 'icons/nature/eco-light-bulb.svg', name: 'eco-light-bulb'},
  {path: 'icons/nature/water.svg', name: 'water'},
  {path: 'icons/nature/light-bulb-alt.svg', name: 'light-bulb-alt'},
  {path: 'icons/nature/mountain.svg', name: 'mountain'},
  {path: 'icons/nature/umbrela.svg', name: 'umbrela'},
  {path: 'icons/nature/tree.svg', name: 'tree'},
  {path: 'icons/nature/water-drop.svg', name: 'water-drop'},
  {path: 'icons/nature/christmas-tree.svg', name: 'christmas-tree'},
  {path: 'icons/nature/flowerpot.svg', name: 'flowerpot'},
  {path: 'icons/nature/cloud.svg', name: 'cloud'},
  {path: 'icons/nature/sun.svg', name: 'sun'},
  {path: 'icons/nature/flower.svg', name: 'flower'}
];
