import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { User, UserRequest } from '@btr/modules/user/models/user.model';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class UserService {
  constructor(private http: HttpClient) {}

  loadAll(): Observable<User[]> {
    return this.http.get<User[]>(`${environment.api()}/users`);
  }

  load(userId: number, meId: number): Observable<User> {
    return this.http.get<User>(`${environment.api()}/users/${userId}`).pipe(map((u) => {
      return {...u, me: u.id === meId};
    }));
  }

  save(user: UserRequest): Observable<User> {
    if (user.id) {
      return this.http.patch<User>(`${environment.api()}/users/${user.id}`, user);
    }
    return this.http.post<User>(`${environment.api()}/users`, user);
  }
}
