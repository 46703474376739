import { HorseAction, HorseTypes } from './horse.action';
import { Horse } from '@btr/modules/horse/models/horse.model';

export interface HorseState {
  horses: Horse[];
  horse: Horse;
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  saved: boolean;
}

export const initialState: HorseState = {
  horses: [],
  horse: null,
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
};

export function horseReducer(state: HorseState = initialState, action: HorseAction) {
  switch (action.type) {
    case HorseTypes.LoadAll: {
      return {
        ...state,
        horses: [],
        loading: true,
        loaded: false
      };
    }
    case HorseTypes.LoadAllSuccess: {
      return {
        ...state,
        horses: action.payload,
        loading: false,
        loaded: true
      };
    }
    case HorseTypes.LoadAllError: {
      return {
        ...state,
        horses: null,
        loading: false,
        loaded: false
      };
    }

    case HorseTypes.LoadOne: {
      return {
        ...state,
        horse: null,
        loading: true,
        loaded: false
      };
    }
    case HorseTypes.LoadOneSuccess: {
      return {
        ...state,
        horse: action.payload,
        loading: false,
        loaded: true
      };
    }
    case HorseTypes.LoadOneError: {
      return {
        ...state,
        horse: null,
        loading: false,
        loaded: false
      };
    }

    case HorseTypes.Save: {
      return {
        ...state,
        saving: true,
        saved: false
      };
    }
    case HorseTypes.SaveSuccess: {
      return {
        ...state,
        horses: [...state.horses, action.payload],
        saving: false,
        saved: true
      };
    }
    case HorseTypes.SaveError: {
      return {
        ...state,
        saving: false,
        saved: false
      };
    }

    case HorseTypes.SaveFavorite: {
      return {
        ...state,
        saving: true,
        saved: false
      };
    }
    case HorseTypes.SaveFavoriteSuccess: {
      return {
        ...state,
        saving: false,
        saved: true
      };
    }
    case HorseTypes.SaveFavoriteError: {
      return {
        ...state,
        saving: false,
        saved: false
      };
    }

    default: {
      return state;
    }
  }
}
