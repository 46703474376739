import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ExamFormService } from '../services/exam-form.service';
import { ExamForm, ExamFormSummary } from '@btr/modules/exam-form/models/exam-form.model';
import {
  ExamFormTypes,
  LoadOneExamForm,
  LoadOneExamFormError,
  LoadOneExamFormSuccess,
  ExamFormSave,
  ExamFormSaveError,
  ExamFormSaveSuccess,
  LoadAllExamForm,
  LoadAllExamFormSuccess,
  LoadAllExamFormError,
  ExamFormDelete,
  ExamFormDeleteSuccess,
  ExamFormDeleteError
} from '@btr/modules/exam-form/stores/exam-form.action';
import { Go } from '@core/store/router/router.action';

@Injectable()
export class ExamFormEffect {
  constructor(private toastrService: ToastrService, private examFormService: ExamFormService) {}

  load$ = createEffect(() => inject(Actions).pipe(
    ofType<LoadOneExamForm>(ExamFormTypes.LoadOne),
    switchMap((action) => this.examFormService.load(action.payload).pipe(
        map((profile: ExamForm) => new LoadOneExamFormSuccess(profile)),
        catchError(error => of(new LoadOneExamFormError(error)))
      )
    ))
  );

  loadAll$ = createEffect(() => inject(Actions).pipe(
    ofType<LoadAllExamForm>(ExamFormTypes.LoadAll),
    switchMap(() => this.examFormService.loadAll().pipe(
        map((users: ExamFormSummary[]) => new LoadAllExamFormSuccess(users)),
        catchError(error => of(new LoadAllExamFormError(error)))
      )
    ))
  );

  save$ = createEffect(() => inject(Actions).pipe(
    ofType<ExamFormSave>(ExamFormTypes.Save),
    switchMap((action) => this.examFormService.save(action.payload).pipe(
        map((profile: ExamForm) => new ExamFormSaveSuccess(profile)),
        catchError(error => of(new ExamFormSaveError(error)))
      )
    ))
  );

  saveSuccess$ = createEffect(() => inject(Actions).pipe(
      ofType<ExamFormSaveSuccess>(ExamFormTypes.SaveSuccess),
      tap((action) => {
        this.toastrService.success("Changes saved");
      })
    ), {dispatch: false}
  );

  delete$ = createEffect(() => inject(Actions).pipe(
    ofType<ExamFormDelete>(ExamFormTypes.Delete),
    switchMap((action) => this.examFormService.delete(action.payload).pipe(
        map(() => new ExamFormDeleteSuccess()),
        catchError(error => of(new ExamFormDeleteError(error)))
      )
    ))
  );

  deleteSuccess$ = createEffect(() => inject(Actions).pipe(
      ofType<ExamFormDeleteSuccess>(ExamFormTypes.DeleteSuccess),
      map((action) => {
        this.toastrService.success("Exam form deleted with success");
        return new Go({path: ['/exam-forms']});
      })
    ),
  );

  error$ = createEffect(() => inject(Actions).pipe(
      ofType(ExamFormTypes.LoadOneError, ExamFormTypes.LoadAllError, ExamFormTypes.SaveError, ExamFormTypes.DeleteError),
      tap(_ => this.toastrService.error("An error has occured, try again"))),
    {dispatch: false}
  );
}
