import { Action } from '@ngrx/store';
import { Note, NoteForHorse, NoteToDelete } from '@btr/modules/note/models/note.model';

export enum NoteTypes {
  LoadAllByHorse = '[NOTE] LOAD ALL',
  LoadAllByHorseSuccess = '[NOTE] LOAD ALL SUCCESS',
  LoadAllByHorseError = '[NOTE] LOAD ALL ERROR',

  Save = '[NOTE] ADD',
  SaveSuccess = '[NOTE] ADD SUCCESS',
  SaveError = '[NOTE] ADD ERROR',

  Delete = '[NOTE] DELETE',
  DeleteSuccess = '[NOTE] DELETE SUCCESS',
  DeleteError = '[NOTE] DELETE ERROR',
}

/**
 * Load all notes by horse
 */
export class LoadAllNoteByHorse implements Action {
  readonly type = NoteTypes.LoadAllByHorse;
  constructor(public payload: string) {}
}
export class LoadAllNoteByHorseSuccess implements Action {
  readonly type = NoteTypes.LoadAllByHorseSuccess;
  constructor(public payload: Note[]) {}
}
export class LoadAllNoteByHorseError implements Action {
  readonly type = NoteTypes.LoadAllByHorseError;
  constructor(public payload: any) {}
}


/**
 * Save one note
 */
export class NoteSave implements Action {
  readonly type = NoteTypes.Save;
  constructor(public payload: NoteForHorse) {}
}
export class NoteSaveSuccess implements Action {
  readonly type = NoteTypes.SaveSuccess;
  constructor(public payload: Note) {}
}
export class NoteSaveError implements Action {
  readonly type = NoteTypes.SaveError;
  constructor(public payload: any) {}
}


/**
 * Delete one note
 */
export class NoteDelete implements Action {
  readonly type = NoteTypes.Delete;
  constructor(public payload: NoteToDelete) {}
}
export class NoteDeleteSuccess implements Action {
  readonly type = NoteTypes.DeleteSuccess;
}
export class NoteDeleteError implements Action {
  readonly type = NoteTypes.DeleteError;
  constructor(public payload: any) {}
}

export type NoteAction = LoadAllNoteByHorse
  | LoadAllNoteByHorseSuccess
  | LoadAllNoteByHorseError
  | NoteSave
  | NoteSaveSuccess
  | NoteSaveError
  | NoteDelete
  | NoteDeleteSuccess
  | NoteDeleteError;
