import { Icon } from '../icon';

export const iconsFurniture: Icon[] = [
  {path: 'icons/furniture/sofa.svg', name: 'sofa'},
  {path: 'icons/furniture/door.svg', name: 'door'},
  {path: 'icons/furniture/plant.svg', name: 'plant'},
  {path: 'icons/furniture/sofa-alt.svg', name: 'sofa-alt'},
  {path: 'icons/furniture/mirror.svg', name: 'mirror'},
  {path: 'icons/furniture/drawer-alt.svg', name: 'drawer-alt'},
  {path: 'icons/furniture/bed.svg', name: 'bed'},
  {path: 'icons/furniture/window-1.svg', name: 'window-1'},
  {path: 'icons/furniture/table-lamp.svg', name: 'table-lamp'},
  {path: 'icons/furniture/roller.svg', name: 'roller'},
  {path: 'icons/furniture/window-2.svg', name: 'window-2'},
  {path: 'icons/furniture/lamp.svg', name: 'lamp'},
  {path: 'icons/furniture/pillow.svg', name: 'pillow'},
  {path: 'icons/furniture/window-3.svg', name: 'window-3'},
  {path: 'icons/furniture/watch.svg', name: 'watch'},
  {path: 'icons/furniture/fridge.svg', name: 'fridge'},
  {path: 'icons/furniture/picture.svg', name: 'picture'},
  {path: 'icons/furniture/hammer.svg', name: 'hammer'},
  {path: 'icons/furniture/wardrobe.svg', name: 'wardrobe'},
  {path: 'icons/furniture/picture.svg', name: 'picture'},
  {path: 'icons/furniture/washing-machine.svg', name: 'washing-machine'},
  {path: 'icons/furniture/wardrobe-alt.svg', name: 'wardrobe-alt'},
  {path: 'icons/furniture/table.svg', name: 'table'},
  {path: 'icons/furniture/bath.svg', name: 'bath'},
  {path: 'icons/furniture/drawer.svg', name: 'drawer'},
  {path: 'icons/furniture/hanger.svg', name: 'hanger'},
  {path: 'icons/furniture/light-bulb.svg', name: 'light-bulb'}
];
