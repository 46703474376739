import { Icon } from '../icon';

export const iconsMenus: Icon[] = [
  {path: 'icons/menus/dot-menu-circle.svg', name: 'dot-menu-circle'},
  {path: 'icons/menus/bento-menu-1.svg', name: 'bento-menu-1'},
  {path: 'icons/menus/box.svg', name: 'box'},
  {path: 'icons/menus/dot-menu-box.svg', name: 'dot-menu-box'},
  {path: 'icons/menus/bento-menu-2.svg', name: 'bento-menu-2'},
  {path: 'icons/menus/box-split-diagonal.svg', name: 'box-split-diagonal'},
  {path: 'icons/menus/dot-menu.svg', name: 'dot-menu'},
  {path: 'icons/menus/bento-menu-3.svg', name: 'bento-menu-3'},
  {path: 'icons/menus/box-inside-box.svg', name: 'box-inside-box'},
  {path: 'icons/menus/dots-menu-vertical.svg', name: 'dots-menu-vertical'},
  {path: 'icons/menus/bento-menu-4.svg', name: 'bento-menu-4'},
  {path: 'icons/menus/box-split-horizontal.svg', name: 'box-split-horizontal'},
  {path: 'icons/menus/command.svg', name: 'command'},
  {path: 'icons/menus/bento-menu-5.svg', name: 'bento-menu-5'},
  {path: 'icons/menus/box-double-vertical.svg', name: 'box-double-vertical'},
  {path: 'icons/menus/burger-menu-small.svg', name: 'burger-menu-small'},
  {path: 'icons/menus/kebab-menu-1.svg', name: 'kebab-menu-1'},
  {path: 'icons/menus/box-split-vetical.svg', name: 'box-split-vetical'},
  {path: 'icons/menus/burger-menu-big.svg', name: 'burger-menu-big'},
  {path: 'icons/menus/meatball-menu-1.svg', name: 'meatball-menu-1'},
  {path: 'icons/menus/box-double-horizontal.svg', name: 'box-double-horizontal'},
  {path: 'icons/menus/burger-menu-alt.svg', name: 'burger-menu-alt'},
  {path: 'icons/menus/doner-menu.svg', name: 'doner-menu'},
  {path: 'icons/menus/box-split-1-3.svg', name: 'box-split-1-3'},
  {path: 'icons/menus/burger-menu-circle.svg', name: 'burger-menu-circle'},
  {path: 'icons/menus/doner-menu-alt.svg', name: 'doner-menu-alt'},
  {path: 'icons/menus/box-halved.svg', name: 'box-halved'},
  {path: 'icons/menus/burger-menu-box.svg', name: 'burger-menu-box'},
  {path: 'icons/menus/meatball-menu-2.svg', name: 'meatball-menu-2'},
  {path: 'icons/menus/layout-8.svg', name: 'layout-8'},
  {path: 'icons/menus/layout-1.svg', name: 'layout-1'},
  {path: 'icons/menus/candy-box-menu.svg', name: 'candy-box-menu'},
  {path: 'icons/menus/meatball-menu-3.svg', name: 'meatball-menu-3'},
  {path: 'icons/menus/layout-2.svg', name: 'layout-2'},
  {path: 'icons/menus/layout-5.svg', name: 'layout-5'},
  {path: 'icons/menus/kebab-menu-2.svg', name: 'kebab-menu-2'},
  {path: 'icons/menus/layout-3.svg', name: 'layout-3'},
  {path: 'icons/menus/layout-6.svg', name: 'layout-6'},
  {path: 'icons/menus/meatball-menu-4.svg', name: 'meatball-menu-4'},
  {path: 'icons/menus/layout-4.svg', name: 'layout-4'},
  {path: 'icons/menus/layout-7.svg', name: 'layout-7'},
  {path: 'icons/menus/candy-box-menu-alt.svg', name: 'candy-box-menu-alt'},
  {path: 'icons/menus/home-1.svg', name: 'home-1'},
  {path: 'icons/menus/home-2.svg', name: 'home-2'},
  {path: 'icons/menus/home-4.svg', name: 'home-4'},
  {path: 'icons/menus/home-plus.svg', name: 'home-plus'},
  {path: 'icons/menus/home-2-alt.svg', name: 'home-2-alt'},
  {path: 'icons/menus/home-box.svg', name: 'home-box'},
  {path: 'icons/menus/home-minus.svg', name: 'home-minus'},
  {path: 'icons/menus/home-3.svg', name: 'home-3'},
  {path: 'icons/menus/home-circle.svg', name: 'home-circle'},
  {path: 'icons/menus/home-close.svg', name: 'home-close'},
  {path: 'icons/menus/home-3-alt.svg', name: 'home-3-alt'},
  {path: 'icons/menus/home-5.svg', name: 'home-5'},
  {path: 'icons/menus/home-check.svg', name: 'home-check'}
];
