import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '@core/store/app.reducer';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { selectUrl } from '@core/store/router/router.selector';
import { Go } from '@core/store/router/router.action';
import {
  SIDEBAR_BUTTON_DASHBOARD,
  SIDEBAR_BUTTON_HORSE_FORM,
  SIDEBAR_BUTTON_HORSES,
  SIDEBAR_BUTTON_LOGOUT,
  SIDEBAR_BUTTON_NOTIFICATIONS,
  SIDEBAR_BUTTON_SETTINGS,
  SIDEBAR_BUTTON_TASKS,
  SIDEBAR_BUTTON_USERS
} from '@btr/modules/layout/models/sidebar.model';
import { Profile } from '@btr/modules/profile/models/profile.model';
import { selectProfile } from '@btr/modules/profile/stores/profile.selector';

@Component({
  selector: 'btr-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  pathName$: Observable<any>;
  profile$: Observable<Profile>;

  activePath!: string;

  sub: Subscription;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.pathName$ = this.store.select(selectUrl);
    this.profile$ = this.store.select(selectProfile);

    this.sub = this.pathName$.subscribe(path => {
      if (path) {
        this.activePath = path;
      }
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  onProfile() {
    this.store.dispatch(new Go({path: ['/users/user/me']}));
  }

  logout() {
    this.store.dispatch(new Go({path: ['/logout']}));
  }

  protected readonly SIDEBAR_BUTTON_DASHBOARD = SIDEBAR_BUTTON_DASHBOARD;
  protected readonly SIDEBAR_BUTTON_NOTIFICATIONS = SIDEBAR_BUTTON_NOTIFICATIONS;
  protected readonly SIDEBAR_BUTTON_TASKS = SIDEBAR_BUTTON_TASKS;
  protected readonly SIDEBAR_BUTTON_HORSES = SIDEBAR_BUTTON_HORSES;
  protected readonly SIDEBAR_BUTTON_USERS = SIDEBAR_BUTTON_USERS;
  protected readonly SIDEBAR_BUTTON_HORSE_FORM = SIDEBAR_BUTTON_HORSE_FORM;
  protected readonly SIDEBAR_BUTTON_SETTINGS = SIDEBAR_BUTTON_SETTINGS;
  protected readonly SIDEBAR_BUTTON_LOGOUT = SIDEBAR_BUTTON_LOGOUT;
}
