import { ProfileAction, ProfileTypes } from './profile.action';
import { Profile } from '@btr/modules/profile/models/profile.model';

export interface ProfileState {
  profile: Profile;
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  saved: boolean;
}

export const initialState: ProfileState = {
  profile: null,
  loading: false,
  loaded: false,
  saving: false,
  saved: false
};

export function profileReducer(state: ProfileState = initialState, action: ProfileAction) {
  switch (action.type) {
    case ProfileTypes.Load: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case ProfileTypes.LoadSuccess: {
      return {
        ...state,
        profile: action.payload,
        loading: false,
        loaded: true
      };
    }

    case ProfileTypes.LoadError: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case ProfileTypes.Save: {
      return {
        ...state,
        saving: true,
        saved: false
      };
    }

    case ProfileTypes.SaveSuccess: {
      return {
        ...state,
        profile: action.payload,
        saving: false,
        saved: true
      };
    }

    case ProfileTypes.SaveError: {
      return {
        ...state,
        saving: false,
        saved: false
      };
    }

    default: {
      return state;
    }
  }
}
