import { RouterStateUrl } from "./router/router.reducer";
import { ActionReducerMap, MetaReducer } from "@ngrx/store";
import { routerReducer, RouterReducerState } from "@ngrx/router-store";
import { barnReducer, BarnState } from '@btr/modules/barn/stores/barn.reducer';
import { consumptionReducer, ConsumptionState } from '@btr/modules/consumption/stores/consumption.reducer';
import { dashboardReducer, DashboardState } from '@btr/modules/dashboard/stores/dashboard.reducer';
import { examFormReducer, ExamFormState } from '@btr/modules/exam-form/stores/exam-form.reducer';
import { horseReducer, HorseState } from '@btr/modules/horse/stores/horse.reducer';
import { metricsReducer, MetricsState } from '@btr/modules/dashboard/stores/metrics.reducer';
import { noteReducer, NoteState } from '@btr/modules/note/stores/note.reducer';
import { profileReducer, ProfileState } from '@btr/modules/profile/stores/profile.reducer';
import { tenantReducer, TenantState, initialState } from '@btr/modules/login/stores/tenant.reducer';
import { taskReducer, TaskState } from '@btr/modules/task/stores/task.reducer';
import { userReducer, UserState } from '@btr/modules/user/stores/user.reducer';
import { TenantTypes } from '@btr/modules/login/stores/tenant.action';
import { waterMonitorsReducer, WaterMonitorsState } from '@btr/modules/water-monitors/stores/water-monitors.reducer';

export interface AppState {
  barn: BarnState;
  consumption: ConsumptionState;
  dashboard: DashboardState;
  examForm: ExamFormState;
  horse: HorseState;
  metrics: MetricsState;
  note: NoteState;
  profile: ProfileState;
  task: TaskState;
  tenant: TenantState;
  user: UserState;
  waterMonitors: WaterMonitorsState;

  router: RouterReducerState<RouterStateUrl>;
}

export const reducers: ActionReducerMap<AppState> = {
  barn: barnReducer,
  consumption: consumptionReducer,
  dashboard: dashboardReducer,
  examForm: examFormReducer,
  horse: horseReducer,
  metrics: metricsReducer,
  note: noteReducer,
  profile: profileReducer,
  task: taskReducer,
  tenant: tenantReducer,
  user: userReducer,
  waterMonitors: waterMonitorsReducer,

  router: routerReducer
};

export const metaReducers: MetaReducer<AppState>[] = [userSessionMetaReducer];

/**
 * Reset of store in case of log off
 */
export function userSessionMetaReducer(reducer: (arg0: any, arg1: any) => any) {
  return function newReducer(state: any, action: any) {
    let newState = state;

    if (action.type === TenantTypes.LoginBtr) {
      newState = {
        ...state,
        tenant: initialState,
      };
    }

    return reducer(newState, action);
  };
}
