export interface SidebarButton {
  icon: string;
  text?: string;
  routerLink: string;
}

export const SIDEBAR_BUTTON_DASHBOARD: SidebarButton = {
  icon: 'home-2',
  text: 'Dashboard',
  routerLink: '/dashboard'
}
export const SIDEBAR_BUTTON_NOTIFICATIONS: SidebarButton = {
  icon: 'ring-notification-1',
  text: 'Notifications',
  routerLink: '/notifications'
}
export const SIDEBAR_BUTTON_TASKS: SidebarButton = {
  icon: 'checkbox',
  text: 'Tasks',
  routerLink: '/tasks'
}
export const SIDEBAR_BUTTON_HORSES: SidebarButton = {
  icon: 'chess-knight',
  text: 'Horses',
  routerLink: '/horses'
}
export const SIDEBAR_BUTTON_USERS: SidebarButton = {
  icon: 'user-circle',
  text: 'Users',
  routerLink: '/users'
}
export const SIDEBAR_BUTTON_HORSE_FORM: SidebarButton = {
  icon: 'document-heart',
  text: 'Health form',
  routerLink: '/exam-forms'
}
export const SIDEBAR_BUTTON_SETTINGS: SidebarButton = {
  icon: 'gear-1',
  text: 'Settings',
  routerLink: '/settings'
}
export const SIDEBAR_BUTTON_LOGOUT: SidebarButton = {
  icon: 'out',
  text: 'Log out',
  routerLink: '/logout'
}
