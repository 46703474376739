import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from '@env/environment';
import { Task, TaskStep } from '@btr/modules/task/models/task.model';

@Injectable({providedIn: 'root'})
export class TaskService {
  constructor(private http: HttpClient) {}

  loadAll(): Observable<Task[]> {
    return of( [
      {id: 1, state: TaskStep.todo,  name: "Morning feeding", horseName: "Vanna", date: new Date()},
      {id: 2, state: TaskStep.done, name: "Brushing", horseName: "Summer", date: new Date()},
      {id: 3, state: TaskStep.todo, name: "Hoof care", horseName: "Vanna", date: new Date()},
      {id: 4, state: TaskStep.done, name: "Training assistance", horseName: "Charlotte", date: new Date()},
      {id: 5, state: TaskStep.todo, name: "Cleaning Stall", horseName: "Storm", date: new Date()},
      {id: 6, state: TaskStep.done, name: "Brushing", horseName: "Storm", date: new Date()},
    ]);
    // return this.http.get<Task[]>(`${environment.api()}/tasks`);
  }

  loadOne(taskId: number): Observable<Task> {
    return this.http.get<Task>(`${environment.api()}/tasks/${taskId}`);
  }

  save(task: Task): Observable<Task> {
    return this.http.patch<Task>(`${environment.api()}/tasks/${task.id}`, task);
  }
}
