import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { environment } from "@env/environment";

@Injectable({
  providedIn: "root"
})
/* Angular side */
export class WorkerService {
  readonly DEBUG = true;

  private _newVersionReady = false;
  get newVersionReady(): boolean {
    return this._newVersionReady;
  }

  constructor(private updates: SwUpdate) {
    navigator.serviceWorker.ready.then((registration: ServiceWorkerRegistration) => {
      registration.active.postMessage({
        action: "init",
        payload: {
          environment: {
            production: environment.production,
            api: environment.api(),
          },
          DEBUG: this.DEBUG
        }
      });
      this.printl("prêt", registration.active);
    });

    updates.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case "VERSION_DETECTED":
          this.printl("New version", evt.version.hash);
          break;
        case "VERSION_READY":
          this.printl("Version", evt.currentVersion.hash);
          this.printl("New version is ready!", evt.latestVersion.hash);
          this._newVersionReady = true;
          this.tryCleanupOldVersions(evt.latestVersion.hash);
          break;
        case "VERSION_INSTALLATION_FAILED":
          this.printl("Failed to install new version", evt.version.hash, evt.error);
          break;
      }
    });
  }

  private tryCleanupOldVersions(newHash: string): void {
    try {
      const cachesToKeep = [newHash, ":db:control"];
      caches.keys().then((keyList) =>
        Promise.all(
          keyList
            .filter((key) => key.startsWith("ngsw:"))
            .filter((key) => cachesToKeep.every((keep) => !key.includes(keep)))
            .map((key) => {
              return caches.delete(key);
            }),
        ),
      )
    } catch (e) {
      this.printl("Failed to clean the storage", e);
    }
  }

  private async requestNotificationPermission(): Promise<NotificationPermission> {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      this.printl("Notification request allowed.");
    } else {
      this.printl("Notification request refused.");
    }
    return permission;
  }

  private printl(...elements: any[]): void {
    if (environment.production || !this.DEBUG) {
      return;
    }
    const css = "background: #D90; color: black; padding: 2px 4px";
    console.log("%cWorkerService", css, ...elements);
  }
}
