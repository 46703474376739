import { Icon } from '../icon';

export const iconsMedicine: Icon[] = [
  {path: 'icons/medicine/stethoscope.svg', name: 'stethoscope'},
  {path: 'icons/medicine/woman.svg', name: 'woman'},
  {path: 'icons/medicine/brain.svg', name: 'brain'},
  {path: 'icons/medicine/patch.svg', name: 'patch'},
  {path: 'icons/medicine/medical-document.svg', name: 'medical-document'},
  {path: 'icons/medicine/ambulance.svg', name: 'ambulance'},
  {path: 'icons/medicine/syringe.svg', name: 'syringe'},
  {path: 'icons/medicine/bone-break.svg', name: 'bone-break'},
  {path: 'icons/medicine/lungs.svg', name: 'lungs'},
  {path: 'icons/medicine/medicine.svg', name: 'medicine'},
  {path: 'icons/medicine/medical-soft.svg', name: 'medical-soft'},
  {path: 'icons/medicine/pill-box.svg', name: 'pill-box'},
  {path: 'icons/medicine/bulb.svg', name: 'bulb'},
  {path: 'icons/medicine/medical-card.svg', name: 'medical-card'},
  {path: 'icons/medicine/liver.svg', name: 'liver'},
  {path: 'icons/medicine/mixing-bowl.svg', name: 'mixing-bowl'},
  {path: 'icons/medicine/tooth.svg', name: 'tooth'},
  {path: 'icons/medicine/dropping-bottle.svg', name: 'dropping-bottle'},
  {path: 'icons/medicine/medical-sign.svg', name: 'medical-sign'},
  {path: 'icons/medicine/bulb.svg', name: 'bulb'},
  {path: 'icons/medicine/heart.svg', name: 'heart'},
  {path: 'icons/medicine/heart-beat.svg', name: 'heart-beat'},
  {path: 'icons/medicine/thermometer.svg', name: 'thermometer'},
  {path: 'icons/medicine/probe.svg', name: 'probe'},
  {path: 'icons/medicine/medical-kit.svg', name: 'medical-kit'},
  {path: 'icons/medicine/probe.svg', name: 'probe'},
  {path: 'icons/medicine/dna.svg', name: 'dna'},
  {path: 'icons/medicine/pills.svg', name: 'pills'},
  {path: 'icons/medicine/man.svg', name: 'man'},
  {path: 'icons/medicine/opened-pill.svg', name: 'opened-pill'},
  {path: 'icons/medicine/virus.svg', name: 'virus'},
  {path: 'icons/medicine/scissors.svg', name: 'scissors'},
  {path: 'icons/medicine/pulse.svg', name: 'pulse'},
  {path: 'icons/medicine/medical-sign-circle.svg', name: 'medical-sign-circle'}
];
