import { Component, OnInit } from "@angular/core";
import { Store } from '@ngrx/store';
import { AppState } from '@core/store/app.reducer';
import { Go } from '@core/store/router/router.action';

@Component({
  selector: "btr-loading-screen",
  templateUrl: "./loading-screen.component.html",
  styleUrls: ["./loading-screen.component.scss"],
})
export class LoadingScreenComponent implements OnInit {
  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    setTimeout(() => {
      this.store.dispatch(new Go({path: ['/login']}));
    }, 3000); // Screen saver for 3s
  }
}
