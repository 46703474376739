import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RouterStateUrl} from "./router.reducer";

export const selectRouter = createFeatureSelector<RouterStateUrl>('router');

export const selectUrl = createSelector(
  selectRouter,
  (routerState: any) => routerState && routerState.state && routerState.state.url,
);

export const selectUrlParam = (paramKey: string) =>
  createSelector(
    selectRouter,
    (routerState: any) =>
      routerState &&
      routerState.state &&
      routerState.state.params &&
      routerState.state.params[paramKey],
  );
