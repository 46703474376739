export const environment = {
  reduxTool: true,
  production: false,
  qualif: true,

  home() {
    return 'https://btr.solrsystems.ca';
  },

  api() {
    return 'https://btr.solrsystems.ca/api/v1';
  },

  keycloak() {
    return 'https://keycloak.solrsystems.ca/realms/BTR';
  },

  clientId() {
    return 'btr-rest-api';
  },

  clientSecret() {
    return 'gvwHXAxvSCacbvEzAJJqQwRzNQj8j7Xz';
  }
};
