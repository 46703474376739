import { Action } from '@ngrx/store';
import { Account, Login } from '@btr/modules/login/models/login.model';
import { Tenant } from '@btr/modules/login/models/tenant.model';

export enum TenantTypes {
  LoginBtr = '[TENANT] LOGIN',
  LoginBtrSuccess = '[TENANT] LOGIN SUCCESS',
  LoginBtrError = '[TENANT] LOGIN ERROR',

  RefreshToken = '[TENANT] REFRESH',
  RefreshTokenSuccess = '[TENANT] REFRESH SUCCESS',
  RefreshTokenError = '[TENANT] REFRESH ERROR',

  Logout = '[TENANT] LOGOUT',

  CreateAccount = '[TENANT] CREATE ACCOUNT',
  CreateAccountSuccess = '[TENANT] CREATE ACCOUNT SUCCESS',
  CreateAccountError = '[TENANT] CREATE ACCOUNT ERROR',

  SetBarnId = '[TENANT] SET BARN ID',
}

/**
 * Login
 */
export class LoginBtr implements Action {
  readonly type = TenantTypes.LoginBtr;
  constructor(public payload: string) {}
}

export class LoginBtrSuccess implements Action {
  readonly type = TenantTypes.LoginBtrSuccess;
  constructor(public payload: Tenant) {}
}

export class LoginBtrError implements Action {
  readonly type = TenantTypes.LoginBtrError;
  constructor(public payload: any) {}
}

/**
 * Refresh Keycloak token
 */
export class RefreshToken implements Action {
  readonly type = TenantTypes.RefreshToken;
  constructor(public payload: string) {}
}

export class RefreshTokenSuccess implements Action {
  readonly type = TenantTypes.RefreshTokenSuccess;
  constructor(public payload: Tenant) {}
}

export class RefreshTokenError implements Action {
  readonly type = TenantTypes.RefreshTokenError;
  constructor(public payload: any) {}
}

/**
 * Log out
 */
export class LogoutBtr implements Action {
  readonly type = TenantTypes.Logout;
}

/**
 * Create account
 */
export class CreateAccount implements Action {
  readonly type = TenantTypes.CreateAccount;
  constructor(public payload: Account) {}
}

export class CreateAccountSuccess implements Action {
  readonly type = TenantTypes.CreateAccountSuccess;
}

export class CreateAccountError implements Action {
  readonly type = TenantTypes.CreateAccountError;
  constructor(public payload: any) {}
}

/**
 * Set barn id
 */
export class SetBarnId implements Action {
  readonly type = TenantTypes.SetBarnId;
  constructor(public payload: number) {}
}

export type TenantAction = LoginBtr
  | LoginBtrSuccess
  | LoginBtrError
  | RefreshToken
  | RefreshTokenSuccess
  | RefreshTokenError
  | LogoutBtr
  | CreateAccount
  | CreateAccountSuccess
  | CreateAccountError
  | SetBarnId;
