<div class="topbar">
<!--  <div class="flex flex-1 place-content-center">-->
<!--  </div>-->

  <ng-container *ngIf="myBarnId$ | async as my">
    <kit-dropdown *ngIf="barns$ | async as barns">
      <ng-container drop>
        <div
          class="flex h-8 min-w-[180px] barn-hover cursor-pointer rounded-xl border border-border-inverse-secondary text-txt-inverse-secondary hover:text-txt-inverse-secondary-hover hover:border-border-inverse-secondary-hover p-2 place-content-between items-center">
          <div class="flex space-x-2">
            <div class="h-4 w-4 rounded-full overflow-hidden bg-black">
              <img src="static-assets/temp/barn.png" alt="barn_temp" class="m-auto"/>
            </div>
            <div class="text-xs">{{myBarn(barns, my)?.name}}</div>
          </div>
          <kit-icon size="md" fill="inverse" name="chevron-down"/>
        </div>
      </ng-container>
      <ng-container options>
        <div class="flex flex-col w-[200px] border-b border-b-border-component-hover">
          <div *ngFor="let barn of barns" class="flex items-center cursor-pointer p-2 space-x-2 hover:bg-bg-component-hover {{myBarn(barns, my)?.id === barn.id ? 'bg-bg-component-pressed fond-bold' : ''}}" (click)="onBarn(barn.id)">
            <div class="h-4 w-4 rounded-md overflow-hidden bg-black">
              <img src="static-assets/temp/barn.png" alt="barn_temp" class="m-auto"/>
            </div>
            <div class="text-xs">{{barn.name}}</div>
          </div>
        </div>
        <div class="flex items-center p-2 space-x-2 cursor-not-allowed hover:bg-bg-component-hover" tooltip tooltipPosition="left" tooltipText="Unavailable" (click)="onAddBarn()">
          <kit-icon size="small" fill="icon" name="plus"/>
          <div class="text-xs">Create new barn</div>
        </div>
      </ng-container>
    </kit-dropdown>
  </ng-container>

  <div *ngIf="weather$ | async as weather" class="flex items-center space-x-2.5">
    <img [src]="weather.icon" width="32" height="32" class="bg-bg rounded-full"/>
    <label class="text-sm text-txt-inverse-secondary" tooltip tooltipPosition="bottom" [tooltipText]="weather.weatherDescription | titlecase">{{weather.weather}}, {{weather.temperature}}°C</label>
  </div>
</div>
