import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TooltipService {
  private notifySubject = new Subject<{ overflow: string, amount?: number }>();

  notify(val: any) {
    this.notifySubject.next(val);
  }

  getNotifyObservable() {
    return this.notifySubject.asObservable();
  }
}
