import { Icon } from '../icon';

export const iconsArrows: Icon[] = [
  {path: 'icons/arrows/arrow-right.svg', name: 'arrow-right'},
  {path: 'icons/arrows/arrows-diagonal-expand.svg', name: 'arrows-diagonal-expand'},
  {path: 'icons/arrows/chevron-right-double.svg', name: 'chevron-right-double'},
  {path: 'icons/arrows/arrow-left.svg', name: 'arrow-left'},
  {path: 'icons/arrows/arrows-diagonal-expand-alt.svg', name: 'arrows-diagonal-expand-alt'},
  {path: 'icons/arrows/chevron-left-double.svg', name: 'chevron-left-double'},
  {path: 'icons/arrows/arrow-down.svg', name: 'arrow-down'},
  {path: 'icons/arrows/switch-up-down.svg', name: 'switch-up-down'},
  {path: 'icons/arrows/chevron-down-double.svg', name: 'chevron-down-double'},
  {path: 'icons/arrows/arrow-up.svg', name: 'arrow-up'},
  {path: 'icons/arrows/arrows-vertical-maximize.svg', name: 'arrows-vertical-maximize'},
  {path: 'icons/arrows/chevron-up-double.svg', name: 'chevron-up-double'},
  {path: 'icons/arrows/arrow-up-right.svg', name: 'arrow-up-right'},
  {path: 'icons/arrows/arrows-veritcal-expand.svg', name: 'arrows-veritcal-expand'},
  {path: 'icons/arrows/chevron-expand-verical.svg', name: 'chevron-expand-verical'},
  {path: 'icons/arrows/arrow-down-right.svg', name: 'arrow-down-right'},
  {path: 'icons/arrows/switch-left-right.svg', name: 'switch-left-right'},
  {path: 'icons/arrows/chevron-contract-vertical.svg', name: 'chevron-contract-vertical'},
  {path: 'icons/arrows/arrow-up-left.svg', name: 'arrow-up-left'},
  {path: 'icons/arrows/arrows-horizotal-maximize.svg', name: 'arrows-horizotal-maximize'},
  {path: 'icons/arrows/chevron-expand-horizontal.svg', name: 'chevron-expand-horizontal'},
  {path: 'icons/arrows/arrow-down-left.svg', name: 'arrow-down-left'},
  {path: 'icons/arrows/arrows-horizontal-expand.svg', name: 'arrows-horizontal-expand'},
  {path: 'icons/arrows/chevron-contract-horizontal.svg', name: 'chevron-contract-horizontal'},
  {path: 'icons/arrows/chevron-right.svg', name: 'chevron-right'},
  {path: 'icons/arrows/arrows-diagonal-maximize.svg', name: 'arrows-diagonal-maximize'},
  {path: 'icons/arrows/arrow-bar-right.svg', name: 'arrow-bar-right'},
  {path: 'icons/arrows/chevron-left.svg', name: 'chevron-left'},
  {path: 'icons/arrows/arrows-diagonal-maximize-alt.svg', name: 'arrows-diagonal-maximize-alt'},
  {path: 'icons/arrows/arrow-bar-right-alt.svg', name: 'arrow-bar-right-alt'},
  {path: 'icons/arrows/chevron-down.svg', name: 'chevron-down'},
  {path: 'icons/arrows/arrows-horizotal-minimize.svg', name: 'arrows-horizotal-minimize'},
  {path: 'icons/arrows/arrow-bar-left-alt.svg', name: 'arrow-bar-left-alt'},
  {path: 'icons/arrows/chevron-up.svg', name: 'chevron-up'},
  {path: 'icons/arrows/arrows-vertical-minimize.svg', name: 'arrows-vertical-minimize'},
  {path: 'icons/arrows/arrow-bar-up.svg', name: 'arrow-bar-up'},
  {path: 'icons/arrows/bent-arrow-right-down.svg', name: 'bent-arrow-right-down'},
  {path: 'icons/arrows/arrows-diagonal-contract.svg', name: 'arrows-diagonal-contract'},
  {path: 'icons/arrows/arrow-bar-up-alt.svg', name: 'arrow-bar-up-alt'},
  {path: 'icons/arrows/bent-arrow-right-up.svg', name: 'bent-arrow-right-up'},
  {path: 'icons/arrows/arrows-diagonal-contract-alt.svg', name: 'arrows-diagonal-contract-alt'},
  {path: 'icons/arrows/arrow-bar-down-alt.svg', name: 'arrow-bar-down-alt'},
  {path: 'icons/arrows/bent-arrow-left-down.svg', name: 'bent-arrow-left-down'},
  {path: 'icons/arrows/switch-up-down-alt.svg', name: 'switch-up-down-alt'},
  {path: 'icons/arrows/arrow-bar-down.svg', name: 'arrow-bar-down'},
  {path: 'icons/arrows/bent-arrow-left-up.svg', name: 'bent-arrow-left-up'},
  {path: 'icons/arrows/switch-left-right-alt.svg', name: 'switch-left-right-alt'},
  {path: 'icons/arrows/arrow-bar-left.svg', name: 'arrow-bar-left'},
  {path: 'icons/arrows/bent-arrow-up-left.svg', name: 'bent-arrow-up-left'},
  {path: 'icons/arrows/arrows-left-up.svg', name: 'arrows-left-up'},
  {path: 'icons/arrows/arrow-bar-up-dotted.svg', name: 'arrow-bar-up-dotted'},
  {path: 'icons/arrows/bent-arrow-down-right.svg', name: 'bent-arrow-down-right'},
  {path: 'icons/arrows/arrows-left-down.svg', name: 'arrows-left-down'},
  {path: 'icons/arrows/arrow-bar-up-alt-dotted.svg', name: 'arrow-bar-up-alt-dotted'},
  {path: 'icons/arrows/bent-arrow-down-left.svg', name: 'bent-arrow-down-left'},
  {path: 'icons/arrows/arrows-right-down.svg', name: 'arrows-right-down'},
  {path: 'icons/arrows/arrow-bar-down-alt-dotted.svg', name: 'arrow-bar-down-alt-dotted'},
  {path: 'icons/arrows/bent-arrow-up-right.svg', name: 'bent-arrow-up-right'},
  {path: 'icons/arrows/arrows-right-up.svg', name: 'arrows-right-up'},
  {path: 'icons/arrows/arrow-bar-right-dotted.svg', name: 'arrow-bar-right-dotted'},
  {path: 'icons/arrows/arrow-right-circle.svg', name: 'arrow-right-circle'},
  {path: 'icons/arrows/minimize.svg', name: 'minimize'},
  {path: 'icons/arrows/arrow-bar-left-alt-dotted.svg', name: 'arrow-bar-left-alt-dotted'},
  {path: 'icons/arrows/arrow-down-circle.svg', name: 'arrow-down-circle'},
  {path: 'icons/arrows/maximize-1.svg', name: 'maximize-1'},
  {path: 'icons/arrows/arrow-bar-left-dotted.svg', name: 'arrow-bar-left-dotted'},
  {path: 'icons/arrows/arrow-up-circle.svg', name: 'arrow-up-circle'},
  {path: 'icons/arrows/maximize-2.svg', name: 'maximize-2'},
  {path: 'icons/arrows/arrow-bar-down-dotted.svg', name: 'arrow-bar-down-dotted'},
  {path: 'icons/arrows/arrow-left-circle.svg', name: 'arrow-left-circle'},
  {path: 'icons/arrows/maximize-3.svg', name: 'maximize-3'},
  {path: 'icons/arrows/arrow-bar-right-alt-dotted.svg', name: 'arrow-bar-right-alt-dotted'},
  {path: 'icons/arrows/arrow-left-circle-alt.svg', name: 'arrow-left-circle-alt'},
  {path: 'icons/arrows/arrow-scale-diagonal.svg', name: 'arrow-scale-diagonal'},
  {path: 'icons/arrows/unfold-horizontal.svg', name: 'unfold-horizontal'},
  {path: 'icons/arrows/arrow-down-circle-alt.svg', name: 'arrow-down-circle-alt'},
  {path: 'icons/arrows/arrow-scale-diagonal-alt.svg', name: 'arrow-scale-diagonal-alt'},
  {path: 'icons/arrows/unfold-vertical.svg', name: 'unfold-vertical'},
  {path: 'icons/arrows/arrow-up-circle-alt.svg', name: 'arrow-up-circle-alt'},
  {path: 'icons/arrows/arrow-scale-horizontal.svg', name: 'arrow-scale-horizontal'},
  {path: 'icons/arrows/fold-horizontal.svg', name: 'fold-horizontal'},
  {path: 'icons/arrows/arrow-right-circle-alt.svg', name: 'arrow-right-circle-alt'},
  {path: 'icons/arrows/arrow-scale-vertical.svg', name: 'arrow-scale-vertical'},
  {path: 'icons/arrows/fold-vertical.svg', name: 'fold-vertical'},
  {path: 'icons/arrows/chevron-right-circle.svg', name: 'chevron-right-circle'},
  {path: 'icons/arrows/split.svg', name: 'split'},
  {path: 'icons/arrows/big-arrow-up.svg', name: 'big-arrow-up'},
  {path: 'icons/arrows/chevron-left-circle.svg', name: 'chevron-left-circle'},
  {path: 'icons/arrows/switch.svg', name: 'switch'},
  {path: 'icons/arrows/big-arrow-down.svg', name: 'big-arrow-down'},
  {path: 'icons/arrows/chevron-down-circle.svg', name: 'chevron-down-circle'},
  {path: 'icons/arrows/repeat.svg', name: 'repeat'},
  {path: 'icons/arrows/big-arrow-left.svg', name: 'big-arrow-left'},
  {path: 'icons/arrows/chevron-up-circle.svg', name: 'chevron-up-circle'},
  {path: 'icons/arrows/repeat-alt.svg', name: 'repeat-alt'},
  {path: 'icons/arrows/big-arrow-right.svg', name: 'big-arrow-right'},
  {path: 'icons/arrows/triangle.svg', name: 'triangle'}
];
