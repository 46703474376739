<div class="h-full xl:w-[200px] w-[50px] relative overflow-auto pt-5 bg-bg-inverse border-r border-r-border-inverse">
  <div class="cursor-pointer xl:block hidden mx-5">
    <img src="static-assets/logo_btr_equestrian_white.png" alt="btr" class="m-auto"/>
  </div>
  <div class="cursor-pointer xl:hidden block mx-1">
    <img src="static-assets/logo_btr_white.png" alt="btr" class="m-auto"/>
  </div>

  <div class="sidebar-section">
    <app-sidebar-button [button]="SIDEBAR_BUTTON_DASHBOARD" [path]="activePath"></app-sidebar-button>
    <app-sidebar-button [disabled]="true" [button]="SIDEBAR_BUTTON_NOTIFICATIONS" [path]="activePath"></app-sidebar-button>
    <app-sidebar-button [disabled]="true" [button]="SIDEBAR_BUTTON_TASKS" [path]="activePath"></app-sidebar-button>
  </div>

  <div class="divider"></div>

  <div class="sidebar-section">
    <app-sidebar-button [button]="SIDEBAR_BUTTON_HORSES" [path]="activePath"></app-sidebar-button>
    <app-sidebar-button [button]="SIDEBAR_BUTTON_USERS" [path]="activePath"></app-sidebar-button>
  </div>

  <div class="divider"></div>

  <div class="sidebar-section">
    <app-sidebar-button [button]="SIDEBAR_BUTTON_HORSE_FORM" [path]="activePath"></app-sidebar-button>
  </div>

  <div class="absolute w-full bottom-0">
    <div class="sidebar-section">
      <app-sidebar-button [button]="SIDEBAR_BUTTON_SETTINGS" [path]="activePath"></app-sidebar-button>
      <app-sidebar-button [button]="SIDEBAR_BUTTON_LOGOUT" [path]="activePath"></app-sidebar-button>
    </div>
    <div class="divider"></div>
    <div *ngIf="profile$ | async as p" class="flex h-12 cursor-pointer items-center hover:opacity-60 xl:mx-5 mx-1 mb-5 mt-7" (click)="onProfile()">
      <div class="rounded-full overflow-hidden">
        <img src="static-assets/temp/user.png" height="48px" width="48px" alt="user_temp" class="m-auto"/>
      </div>
      <div class="flex flex-col xl:block hidden pl-3">
        <div class="leading-4 font-bold text-txt-inverse">{{p.firstName + " " + p.lastName}}</div>
        <div class="leading-4 text-xs text-txt-inverse-secondary">{{p.userRole | titlecase}}</div>
      </div>
    </div>
  </div>
</div>
