import { Action } from '@ngrx/store';
import { Horse, HorseFavorite } from '@btr/modules/horse/models/horse.model';

export enum HorseTypes {
  LoadAll = '[HORSE] LOAD ALL',
  LoadAllSuccess = '[HORSE] LOAD ALL SUCCESS',
  LoadAllError = '[HORSE] LOAD ALL ERROR',

  LoadOne = '[HORSE] LOAD ONE',
  LoadOneSuccess = '[HORSE] LOAD ONE SUCCESS',
  LoadOneError = '[HORSE] LOAD ONE ERROR',

  Save = '[HORSE] SAVE',
  SaveSuccess = '[HORSE] SAVE SUCCESS',
  SaveError = '[HORSE] SAVE ERROR',

  SaveFavorite = '[HORSE] SAVE FAVORITE',
  SaveFavoriteSuccess = '[HORSE] SAVE FAVORITE SUCCESS',
  SaveFavoriteError = '[HORSE] SAVE FAVORITE ERROR',
}

/**
 * Load all horses
 */
export class LoadAllHorse implements Action {
  readonly type = HorseTypes.LoadAll;
}
export class LoadAllHorseSuccess implements Action {
  readonly type = HorseTypes.LoadAllSuccess;
  constructor(public payload: Horse[]) {}
}
export class LoadAllHorseError implements Action {
  readonly type = HorseTypes.LoadAllError;
  constructor(public payload: any) {}
}

/**
 * Load all horses
 */
export class LoadOneHorse implements Action {
  readonly type = HorseTypes.LoadOne;
  constructor(public payload: number) {}
}
export class LoadOneHorseSuccess implements Action {
  readonly type = HorseTypes.LoadOneSuccess;
  constructor(public payload: Horse) {}
}
export class LoadOneHorseError implements Action {
  readonly type = HorseTypes.LoadOneError;
  constructor(public payload: any) {}
}

/**
 * Save one horse
 */
export class HorseSave implements Action {
  readonly type = HorseTypes.Save;
  constructor(public payload: Horse) {}
}
export class HorseSaveSuccess implements Action {
  readonly type = HorseTypes.SaveSuccess;
  constructor(public payload: Horse) {}
}
export class HorseSaveError implements Action {
  readonly type = HorseTypes.SaveError;
  constructor(public payload: any) {}
}

/**
 * Save horse favorite
 */
export class HorseFavoriteSave implements Action {
  readonly type = HorseTypes.SaveFavorite;
  constructor(public payload: HorseFavorite) {}
}
export class HorseFavoriteSaveSuccess implements Action {
  readonly type = HorseTypes.SaveFavoriteSuccess;
  constructor(public payload: Horse) {}
}
export class HorseFavoriteSaveError implements Action {
  readonly type = HorseTypes.SaveFavoriteError;
  constructor(public payload: any) {}
}

export type HorseAction = LoadAllHorse
  | LoadAllHorseSuccess
  | LoadAllHorseError
  | LoadOneHorse
  | LoadOneHorseSuccess
  | LoadOneHorseError
  | HorseSave
  | HorseSaveSuccess
  | HorseSaveError
  | HorseFavoriteSave
  | HorseFavoriteSaveSuccess
  | HorseFavoriteSaveError;
