import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Optional,
  Output,
  Self
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl, FormControl } from '@angular/forms';
import { of } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { elements } from 'chart.js';

@Component({
  selector: 'kit-combobox',
  templateUrl: './combobox.component.html',
  styleUrls: ['./combobox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComboboxComponent implements OnInit, ControlValueAccessor {
  @Input() elements: Combo[];
  @Input() default: string;
  @Input() placeholder: string;
  @Input() empty: string;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  state: 'default' | 'valid' | 'error' | 'disabled' = 'default';

  fc: FormControl<string> = new FormControl<string>(null);

  onChange = (value: string) => {
  };
  onTouched = () => {
  };

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    if (this.ngControl) {
      of(this.ngControl.control).pipe(skipWhile(fc => !fc), take(1)).subscribe(fc => {
        this.fc = fc as UntypedFormControl;
      });
    }

    if (this.default) {
      this.fc.patchValue(this.default);
    }
  }

  get el(): Combo {
    return this.elements.find(e => e.value === this.fc.value);
  }

  writeValue(input: any) {
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.state = 'disabled';
      this.fc.disable();
    } else {
      this.state = 'default';
    }
  }

  onChoose(element: any) {
    this.fc.patchValue(element.value);
    this.valueChange.emit(element.value);
  }
}

export class Combo {
  label: string;
  avatar?: string;
  value: any;
}
