import { Action } from '@ngrx/store';
import { Barn } from '@btr/modules/barn/models/barn.model';

export enum BarnTypes {
  LoadAll = '[BARN] LOAD ALL',
  LoadAllSuccess = '[BARN] LOAD ALL SUCCESS',
  LoadAllError = '[BARN] LOAD ALL ERROR',

  LoadMy = '[BARN] LOAD MY',
  LoadMySuccess = '[BARN] LOAD MY SUCCESS',
  LoadMyError = '[BARN] LOAD MY ERROR',

  Save = '[BARN] SAVE',
  SaveSuccess = '[BARN] SAVE SUCCESS',
  SaveError = '[BARN] SAVE ERROR',
}

/**
 * Load all barns
 */
export class LoadAllBarn implements Action {
  readonly type = BarnTypes.LoadAll;
}
export class LoadAllBarnSuccess implements Action {
  readonly type = BarnTypes.LoadAllSuccess;
  constructor(public payload: Barn[]) {}
}
export class LoadAllBarnError implements Action {
  readonly type = BarnTypes.LoadAllError;
  constructor(public payload: any) {}
}

/**
 * Load my barn
 */
export class LoadMyBarn implements Action {
  readonly type = BarnTypes.LoadMy;
}
export class LoadMyBarnSuccess implements Action {
  readonly type = BarnTypes.LoadMySuccess;
  constructor(public payload: Barn) {}
}
export class LoadMyBarnError implements Action {
  readonly type = BarnTypes.LoadMyError;
  constructor(public payload: any) {}
}

/**
 * Save one barn
 */
export class BarnSave implements Action {
  readonly type = BarnTypes.Save;
  constructor(public payload: Barn) {}
}
export class BarnSaveSuccess implements Action {
  readonly type = BarnTypes.SaveSuccess;
  constructor(public payload: Barn) {}
}
export class BarnSaveError implements Action {
  readonly type = BarnTypes.SaveError;
  constructor(public payload: any) {}
}

export type BarnAction = LoadAllBarn
  | LoadAllBarnSuccess
  | LoadAllBarnError
  | LoadMyBarn
  | LoadMyBarnSuccess
  | LoadMyBarnError
  | BarnSave
  | BarnSaveSuccess
  | BarnSaveError;
