import {ActivatedRouteSnapshot, Params, RouterStateSnapshot} from '@angular/router';
import {RouterStateSerializer} from '@ngrx/router-store';
import {Injectable} from "@angular/core";

export interface RouterStateUrl {
  url: string;
  params?: Params;
  title: string;
}

@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const {url} = routerState;
    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }
    const {params} = state;
    const {title} = state.data;
    return {url, params, title};
  }
}
