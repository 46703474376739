import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TaskService } from '@btr/modules/task/services/task.service';
import { TaskSave, TaskSaveError, TaskSaveSuccess, TaskTypes, LoadAllTask, LoadAllTaskError, LoadAllTaskSuccess, LoadOneTask, LoadOneTaskError, LoadOneTaskSuccess } from '@btr/modules/task/stores/task.action';
import { Task } from '@btr/modules/task/models/task.model';

@Injectable()
export class TaskEffect {
  constructor(private toastrService: ToastrService, private taskService: TaskService) {}

  loadAll$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<LoadAllTask>(TaskTypes.LoadAll),
      switchMap(() => this.taskService.loadAll().pipe(
          map((task: Task[]) => new LoadAllTaskSuccess(task)),
          catchError(error => of(new LoadAllTaskError(error)))
        )
      )
    )
  );

  loadOne$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<LoadOneTask>(TaskTypes.LoadOne),
      switchMap((action) => this.taskService.loadOne(action.payload).pipe(
          map((waterMonitor: Task) => new LoadOneTaskSuccess(waterMonitor)),
          catchError(error => of(new LoadOneTaskError(error)))
        )
      )
    )
  );

  save$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<TaskSave>(TaskTypes.Save),
      switchMap((action) => this.taskService.save(action.payload).pipe(
          map((waterMonitor: Task) => new TaskSaveSuccess(waterMonitor)),
          catchError(error => of(new TaskSaveError(error)))
        )
      )
    )
  );

  saveSuccess$ = createEffect(() =>
      inject(Actions).pipe(
        ofType<TaskSaveSuccess>(TaskTypes.SaveSuccess),
        tap(() => this.toastrService.success("Changes saved"))
      ),
    {dispatch: false}
  );

  error$ = createEffect(() =>
      inject(Actions).pipe(
        ofType(TaskTypes.LoadAllError, TaskTypes.LoadOneError, TaskTypes.SaveError),
        tap(_ => this.toastrService.error("An error has occured, try again"))),
    {dispatch: false}
  );
}
