import { Icon } from '../icon';

export const iconsSoftware: Icon[] = [
  {path: 'icons/software/cloud-server.svg', name: 'cloud-server'},
  {path: 'icons/software/terminal-double.svg', name: 'terminal-double'},
  {path: 'icons/software/option-key.svg', name: 'option-key'},
  {path: 'icons/software/cloud-downlaod.svg', name: 'cloud-downlaod'},
  {path: 'icons/software/window-css.svg', name: 'window-css'},
  {path: 'icons/software/command-key.svg', name: 'command-key'},
  {path: 'icons/software/cloud-downlaod-alt.svg', name: 'cloud-downlaod-alt'},
  {path: 'icons/software/file-css.svg', name: 'file-css'},
  {path: 'icons/software/nfc-box.svg', name: 'nfc-box'},
  {path: 'icons/software/cloud-Upload.svg', name: 'cloud-Upload'},
  {path: 'icons/software/window-php.svg', name: 'window-php'},
  {path: 'icons/software/zip-box.svg', name: 'zip-box'},
  {path: 'icons/software/cloud-Upload-alt.svg', name: 'cloud-Upload-alt'},
  {path: 'icons/software/file-php.svg', name: 'file-php'},
  {path: 'icons/software/backspace.svg', name: 'backspace'},
  {path: 'icons/software/bezie-curve.svg', name: 'bezie-curve'},
  {path: 'icons/software/window-py.svg', name: 'window-py'},
  {path: 'icons/software/text-insert.svg', name: 'text-insert'},
  {path: 'icons/software/bezie-curve-alt.svg', name: 'bezie-curve-alt'},
  {path: 'icons/software/file-py.svg', name: 'file-py'},
  {path: 'icons/software/incognito.svg', name: 'incognito'},
  {path: 'icons/software/brush.svg', name: 'brush'},
  {path: 'icons/software/window-js.svg', name: 'window-js'},
  {path: 'icons/software/window.svg', name: 'window'},
  {path: 'icons/software/text-box.svg', name: 'text-box'},
  {path: 'icons/software/file-js.svg', name: 'file-js'},
  {path: 'icons/software/translate.svg', name: 'translate'},
  {path: 'icons/software/fill.svg', name: 'fill'},
  {path: 'icons/software/window-api.svg', name: 'window-api'},
  {path: 'icons/software/face-id.svg', name: 'face-id'},
  {path: 'icons/software/cmyk.svg', name: 'cmyk'},
  {path: 'icons/software/file-api.svg', name: 'file-api'},
  {path: 'icons/software/touch-id.svg', name: 'touch-id'},
  {path: 'icons/software/color-pallete.svg', name: 'color-pallete'},
  {path: 'icons/software/window-code-1.svg', name: 'window-code-1'},
  {path: 'icons/software/finger-print.svg', name: 'finger-print'},
  {path: 'icons/software/add-layer.svg', name: 'add-layer'},
  {path: 'icons/software/file-code-1.svg', name: 'file-code-1'},
  {path: 'icons/software/finder.svg', name: 'finder'},
  {path: 'icons/software/intersect.svg', name: 'intersect'},
  {path: 'icons/software/file-terminal.svg', name: 'file-terminal'},
  {path: 'icons/software/qr-code.svg', name: 'qr-code'},
  {path: 'icons/software/delete-top.svg', name: 'delete-top'},
  {path: 'icons/software/window-code-2.svg', name: 'window-code-2'},
  {path: 'icons/software/apps-add.svg', name: 'apps-add'},
  {path: 'icons/software/delete-bottom.svg', name: 'delete-bottom'},
  {path: 'icons/software/file-2.svg', name: 'file-2'},
  {path: 'icons/software/widgets.svg', name: 'widgets'},
  {path: 'icons/software/arange-front.svg', name: 'arange-front'},
  {path: 'icons/software/window-code-3.svg', name: 'window-code-3'},
  {path: 'icons/software/database.svg', name: 'database'},
  {path: 'icons/software/pantone.svg', name: 'pantone'},
  {path: 'icons/software/window-code-4.svg', name: 'window-code-4'},
  {path: 'icons/software/edit-1.svg', name: 'edit-1'},
  {path: 'icons/software/pencil-circle.svg', name: 'pencil-circle'},
  {path: 'icons/software/window-terminal.svg', name: 'window-terminal'},
  {path: 'icons/software/edit-2.svg', name: 'edit-2'},
  {path: 'icons/software/perspective-edit.svg', name: 'perspective-edit'},
  {path: 'icons/software/computer-terminal.svg', name: 'computer-terminal'},
  {path: 'icons/software/magic-wand.svg', name: 'magic-wand'},
  {path: 'icons/software/perspective-edit-alt.svg', name: 'perspective-edit-alt'},
  {path: 'icons/software/shield-code.svg', name: 'shield-code'},
  {path: 'icons/software/brush.svg', name: 'brush'},
  {path: 'icons/software/rotate.svg', name: 'rotate'},
  {path: 'icons/software/shield-code-alt.svg', name: 'shield-code-alt'},
  {path: 'icons/software/edit-3.svg', name: 'edit-3'},
  {path: 'icons/software/spray-paint.svg', name: 'spray-paint'},
  {path: 'icons/software/shield-terminal.svg', name: 'shield-terminal'},
  {path: 'icons/software/pen.svg', name: 'pen'},
  {path: 'icons/software/magnet-diagonal.svg', name: 'magnet-diagonal'},
  {path: 'icons/software/folder-terminal.svg', name: 'folder-terminal'},
  {path: 'icons/software/color-picker.svg', name: 'color-picker'},
  {path: 'icons/software/cut-box.svg', name: 'cut-box'},
  {path: 'icons/software/laptop-code.svg', name: 'laptop-code'},
  {path: 'icons/software/drawing-pin.svg', name: 'drawing-pin'},
  {path: 'icons/software/cut.svg', name: 'cut'},
  {path: 'icons/software/phone-code.svg', name: 'phone-code'},
  {path: 'icons/software/pen-tool.svg', name: 'pen-tool'},
  {path: 'icons/software/align-right.svg', name: 'align-right'},
  {path: 'icons/software/terminal-box.svg', name: 'terminal-box'},
  {path: 'icons/software/ruller-1.svg', name: 'ruller-1'},
  {path: 'icons/software/align-bottom.svg', name: 'align-bottom'},
  {path: 'icons/software/bits.svg', name: 'bits'},
  {path: 'icons/software/ruller-2.svg', name: 'ruller-2'},
  {path: 'icons/software/align-top.svg', name: 'align-top'},
  {path: 'icons/software/error-404.svg', name: 'error-404'},
  {path: 'icons/software/brush-alt.svg', name: 'brush-alt'},
  {path: 'icons/software/align-left.svg', name: 'align-left'},
  {path: 'icons/software/terminal-error.svg', name: 'terminal-error'},
  {path: 'icons/software/etherium-circle.svg', name: 'etherium-circle'},
  {path: 'icons/software/align-center-vertical.svg', name: 'align-center-vertical'},
  {path: 'icons/software/terminal-search.svg', name: 'terminal-search'},
  {path: 'icons/software/bitcoin-sun.svg', name: 'bitcoin-sun'},
  {path: 'icons/software/autolayout-horizontal.svg', name: 'autolayout-horizontal'},
  {path: 'icons/software/terminal-information.svg', name: 'terminal-information'},
  {path: 'icons/software/shield-btc.svg', name: 'shield-btc'},
  {path: 'icons/software/autolayout-vertical.svg', name: 'autolayout-vertical'},
  {path: 'icons/software/branching-vertical.svg', name: 'branching-vertical'},
  {path: 'icons/software/computer-btc.svg', name: 'computer-btc'},
  {path: 'icons/software/align-center-horizontal.svg', name: 'align-center-horizontal'},
  {path: 'icons/software/curly-braces.svg', name: 'curly-braces'},
  {path: 'icons/software/bitcoin.svg', name: 'bitcoin'},
  {path: 'icons/software/stamp.svg', name: 'stamp'},
  {path: 'icons/software/code-box.svg', name: 'code-box'},
  {path: 'icons/software/etherium.svg', name: 'etherium'},
  {path: 'icons/software/swap-items.svg', name: 'swap-items'},
  {path: 'icons/software/code.svg', name: 'code'},
  {path: 'icons/software/window-search.svg', name: 'window-search'},
  {path: 'icons/software/eraser.svg', name: 'eraser'},
  {path: 'icons/software/curly-braces-plus.svg', name: 'curly-braces-plus'},
  {path: 'icons/software/phone-search.svg', name: 'phone-search'},
  {path: 'icons/software/eraser-alt.svg', name: 'eraser-alt'},
  {path: 'icons/software/curly-braces-minus.svg', name: 'curly-braces-minus'},
  {path: 'icons/software/search-trend.svg', name: 'search-trend'},
  {path: 'icons/software/fether.svg', name: 'fether'},
  {path: 'icons/software/curly-braces-close.svg', name: 'curly-braces-close'},
  {path: 'icons/software/search-link.svg', name: 'search-link'},
  {path: 'icons/software/mirror-vertical.svg', name: 'mirror-vertical'},
  {path: 'icons/software/curly-braces-check.svg', name: 'curly-braces-check'},
  {path: 'icons/software/key-circle-check.svg', name: 'key-circle-check'},
  {path: 'icons/software/mirror-horizontal.svg', name: 'mirror-horizontal'},
  {path: 'icons/software/code-box-alt.svg', name: 'code-box-alt'},
  {path: 'icons/software/link-circle-check.svg', name: 'link-circle-check'},
  {path: 'icons/software/mesh.svg', name: 'mesh'},
  {path: 'icons/software/terminal-breanch.svg', name: 'terminal-breanch'},
  {path: 'icons/software/world-search.svg', name: 'world-search'},
  {path: 'icons/software/scale.svg', name: 'scale'},
  {path: 'icons/software/branching-horizontal.svg', name: 'branching-horizontal'},
  {path: 'icons/software/computer-search.svg', name: 'computer-search'},
  {path: 'icons/software/eth-exchange.svg', name: 'eth-exchange'},
  {path: 'icons/software/message-square-code.svg', name: 'message-square-code'},
  {path: 'icons/software/key-refresh.svg', name: 'key-refresh'},
  {path: 'icons/software/btc-exchange.svg', name: 'btc-exchange'},
  {path: 'icons/software/message-round-code.svg', name: 'message-round-code'},
  {path: 'icons/software/website-n-search.svg', name: 'website-n-search'},
  {path: 'icons/software/bitcoin-circle.svg', name: 'bitcoin-circle'},
  {path: 'icons/software/window-check.svg', name: 'window-check'},
  {path: 'icons/software/seo-box.svg', name: 'seo-box'},
  {path: 'icons/software/bitcoin-reload.svg', name: 'bitcoin-reload'},
  {path: 'icons/software/window-click.svg', name: 'window-click'},
  {path: 'icons/software/seo-dashed.svg', name: 'seo-dashed'},
  {path: 'icons/software/laso-tool.svg', name: 'laso-tool'},
  {path: 'icons/software/window-information.svg', name: 'window-information'},
  {path: 'icons/software/window-framed.svg', name: 'window-framed'},
  {path: 'icons/software/deconstruct.svg', name: 'deconstruct'},
  {path: 'icons/software/window-trend.svg', name: 'window-trend'},
  {path: 'icons/software/block-search.svg', name: 'block-search'}
];
