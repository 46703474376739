import { DashboardAction, DashboardTypes } from './dashboard.action';
import { Dashboard, Weather } from '@btr/modules/dashboard/models/dashboard.model';

export interface DashboardState {
  dashboard: Dashboard;
  weather: Weather;
  loading: boolean;
  loaded: boolean;
}

export const initialState: DashboardState = {
  dashboard: null,
  weather: null,
  loading: false,
  loaded: false
};

export function dashboardReducer(state: DashboardState = initialState, action: DashboardAction) {
  switch (action.type) {
    case DashboardTypes.Load: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DashboardTypes.LoadSuccess: {
      return {
        ...state,
        dashboard: action.payload,
        loading: false,
        loaded: true
      };
    }
    case DashboardTypes.LoadError: {
      return {
        ...state,
        dashboard: null,
        loading: false,
        loaded: false
      };
    }

    case DashboardTypes.LoadWeather: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DashboardTypes.LoadWeatherSuccess: {
      return {
        ...state,
        weather: action.payload,
        loading: false,
        loaded: true
      };
    }
    case DashboardTypes.LoadWeatherError: {
      return {
        ...state,
        weather: null,
        loading: false,
        loaded: false
      };
    }

    default: {
      return state;
    }
  }
}
