import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ConsumptionService } from '@btr/modules/consumption/services/consumption.service';
import {
  ConsumptionSave,
  ConsumptionSaveError,
  ConsumptionSaveSuccess,
  ConsumptionTypes,
  LoadAllConsumptionByHorse,
  LoadAllConsumptionByHorseError,
  LoadAllConsumptionByHorseSuccess
} from '@btr/modules/consumption/stores/consumption.action';
import {Consumption, Consumptions} from '@btr/modules/consumption/models/consumption.model';

@Injectable()
export class ConsumptionEffect {
  constructor(private toastrService: ToastrService, private consumptionService: ConsumptionService) {}

  loadAllByHorse$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<LoadAllConsumptionByHorse>(ConsumptionTypes.LoadAllByHorse),
      switchMap((action) => this.consumptionService.loadAllByHorse(action.payload).pipe(
          map((consumptions: Consumptions) => new LoadAllConsumptionByHorseSuccess(consumptions.consumptions)),
          catchError(error => of(new LoadAllConsumptionByHorseError(error)))
        )
      )
    )
  );

  save$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<ConsumptionSave>(ConsumptionTypes.Save),
      switchMap((action) => this.consumptionService.save(action.payload).pipe(
          map((consumption: Consumption) => new ConsumptionSaveSuccess(consumption)),
          catchError(error => of(new ConsumptionSaveError(error)))
        )
      )
    )
  );

  saveSuccess$ = createEffect(() =>
      inject(Actions).pipe(
        ofType<ConsumptionSaveSuccess>(ConsumptionTypes.SaveSuccess),
        tap(() => this.toastrService.success("Changes saved"))
      ),
    {dispatch: false}
  );

  error$ = createEffect(() =>
      inject(Actions).pipe(
        ofType(ConsumptionTypes.LoadAllByHorseError, ConsumptionTypes.SaveError),
        tap(_ => this.toastrService.error("An error has occured, try again"))),
    {dispatch: false}
  );
}
