import { Icon } from '../icon';

export const iconsAnimals: Icon[] = [
  {path: 'icons/animals/aquarium.svg', name: 'aquarium'},
  {path: 'icons/animals/dog.svg', name: 'dog'},
  {path: 'icons/animals/dragon-fly.svg', name: 'dragon-fly'},
  {path: 'icons/animals/bird-cage.svg', name: 'bird-cage'},
  {path: 'icons/animals/cat.svg', name: 'cat'},
  {path: 'icons/animals/animal-food.svg', name: 'animal-food'},
  {path: 'icons/animals/birdhouse.svg', name: 'birdhouse'},
  {path: 'icons/animals/snake.svg', name: 'snake'},
  {path: 'icons/animals/paw.svg', name: 'paw'},
  {path: 'icons/animals/bug.svg', name: 'bug'},
  {path: 'icons/animals/butterfly.svg', name: 'butterfly'},
  {path: 'icons/animals/turtle.svg', name: 'turtle'},
  {path: 'icons/animals/pet-tent.svg', name: 'pet-tent'},
  {path: 'icons/animals/spider.svg', name: 'spider'},
  {path: 'icons/animals/jelly-fish.svg', name: 'jelly-fish'},
  {path: 'icons/animals/fish.svg', name: 'fish'},
  {path: 'icons/animals/bone.svg', name: 'bone'},
  {path: 'icons/animals/peewee.svg', name: 'peewee'},
  {path: 'icons/animals/bear.svg', name: 'bear'},
  {path: 'icons/animals/shit.svg', name: 'shit'},
  {path: 'icons/animals/squid.svg', name: 'squid'},
  {path: 'icons/animals/chess-knight.svg', name: 'chess-knight'}
];
