import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  ContentChild,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  Injector,
  Input,
  OnDestroy,
  TemplateRef
} from '@angular/core';
import { TooltipDirectiveComponent } from './tooltip-directive.component';
import { fromEvent, Subscription } from 'rxjs';
import { TooltipService } from './tooltip.service';

/**
 * Tooltip - Directive
 * @version 2.3 - 15/01/24
 * @todo REFACTOR car factory est deprecated
 */
@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective implements OnDestroy {
  @ContentChild('tooltipContent') tooltipContent: TemplateRef<any> | null;
  @Input() tooltipText: string = '';
  @Input() tooltipColor: 'default' = 'default';
  @Input() tooltipPosition: 'top' | 'bottom' | 'left' | 'right' = 'top';
  @Input() tooltipEnabled: boolean = true;

  private componentRef: ComponentRef<any> | null = null;

  private sub: Subscription = new Subscription();

  constructor(private tooltipService: TooltipService, private elementRef: ElementRef, private appRef: ApplicationRef, private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector) {
    this.sub.add(
      fromEvent<MouseEvent>(elementRef.nativeElement, 'mouseenter').subscribe(e => {
        this.initializeTooltip();
      })
    );
    this.sub.add(
      fromEvent<MouseEvent>(elementRef.nativeElement, 'mouseleave').subscribe(e => {
        this.setHideTooltipTimeout();
      })
    );
    this.sub.add(
      this.tooltipService.getNotifyObservable().subscribe(value => {
        if (this.componentRef !== null) {
          this.componentRef.instance.tooltip = this.tooltipText;

          const {left, right, top, bottom} = this.elementRef.nativeElement.getBoundingClientRect();
          const positionYaxis = this.componentRef.instance.position === "top" || this.componentRef.instance.position === "bottom";

          if (value.overflow === 'bottom') {
            this.componentRef.instance.position = "top";
            this.componentRef.instance.left = Math.round(left + (right - left) / 2);
            this.componentRef.instance.top = Math.round(top);
          }
          if (value.overflow === 'top') {
            this.componentRef.instance.position = "bottom";
            this.componentRef.instance.left = Math.round(left + (right - left) / 2);
            this.componentRef.instance.top = Math.round(bottom);
          }
          if (value.overflow === 'left') {
            if (positionYaxis) {
              this.componentRef.instance.xOffset = value.amount
            } else {
              this.componentRef.instance.position = "right";
              this.componentRef.instance.left = Math.round(right);
              this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
            }
          }
          if (value.overflow === 'right') {
            if (positionYaxis) {
              this.componentRef.instance.xOffset = value.amount
            } else {
              this.componentRef.instance.position = "left";
              this.componentRef.instance.left = Math.round(left);
              this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
            }
          }
        }
      })
    )
  }

  private initializeTooltip() {
    if (!this.tooltipEnabled) {
      return;
    }
    if (this.componentRef === null) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TooltipDirectiveComponent);
      this.componentRef = componentFactory.create(this.injector);
      this.componentRef.instance.color = this.tooltipColor;
      this.componentRef.instance.tooltipContent = this.tooltipContent;
      this.appRef.attachView(this.componentRef.hostView);
      const [tooltipDOMElement] = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes;
      this.setTooltipComponentProperties();
      document.body.appendChild(tooltipDOMElement);
    }
  }

  private setTooltipComponentProperties() {
    if (!this.tooltipEnabled) {
      return;
    }
    if (this.componentRef !== null) {
      this.componentRef.instance.tooltip = this.tooltipText;
      this.componentRef.instance.position = this.tooltipPosition;
      this.componentRef.instance.elWidth = this.elementRef.nativeElement.getBoundingClientRect().width;

      const {left, right, top, bottom} = this.elementRef.nativeElement.getBoundingClientRect();

      if (this.tooltipPosition === 'top') {
        this.componentRef.instance.left = Math.round(left + (right - left) / 2);
        this.componentRef.instance.top = Math.round(top);
      }
      if (this.tooltipPosition === 'bottom') {
        this.componentRef.instance.left = Math.round(left + (right - left) / 2);
        this.componentRef.instance.top = Math.round(bottom);
      }
      if (this.tooltipPosition === 'right') {
        this.componentRef.instance.left = Math.round(right);
        this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
      }
      if (this.tooltipPosition === 'left') {
        this.componentRef.instance.left = Math.round(left);
        this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
      }
    }
  }

  private setHideTooltipTimeout() {
    this.destroy();
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
    this.destroy();
  }

  destroy() {
    if (this.componentRef !== null) {
      this.appRef?.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
