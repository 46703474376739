import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kit-tabs',
  templateUrl: './tabs.component.html'
})
export class TabsComponent {
  @Input() default: string;
  @Input() tabs: Tab[];
  @Output() action: EventEmitter<string> = new EventEmitter<string>();

  onClick(tab: Tab) {
    if (this.default === tab.name) {
      return;
    }
    this.default = tab.name;
    this.action.emit(tab.name);
  }
}

export class Tab {
  name: string;
  label: string;
}
