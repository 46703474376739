import { Icon } from '../icon';

export const iconsClothing: Icon[] = [
  {path: 'icons/clothing/t-shirt-1.svg', name: 't-shirt-1'},
  {path: 'icons/clothing/underwear.svg', name: 'underwear'},
  {path: 'icons/clothing/cloth.svg', name: 'cloth'},
  {path: 'icons/clothing/jeans.svg', name: 'jeans'},
  {path: 'icons/clothing/shorts.svg', name: 'shorts'},
  {path: 'icons/clothing/jacket.svg', name: 'jacket'},
  {path: 'icons/clothing/dress.svg', name: 'dress'},
  {path: 'icons/clothing/t-shirt-3.svg', name: 't-shirt-3'},
  {path: 'icons/clothing/shoe.svg', name: 'shoe'},
  {path: 'icons/clothing/t-shirt-2.svg', name: 't-shirt-2'},
  {path: 'icons/clothing/briefcase-alt.svg', name: 'briefcase-alt'},
  {path: 'icons/clothing/bra.svg', name: 'bra'},
  {path: 'icons/clothing/glasses.svg', name: 'glasses'},
  {path: 'icons/clothing/hanger.svg', name: 'hanger'},
  {path: 'icons/clothing/bikini.svg', name: 'bikini'},
  {path: 'icons/clothing/tie.svg', name: 'tie'},
  {path: 'icons/clothing/butterfly-tie.svg', name: 'butterfly-tie'},
  {path: 'icons/clothing/bath-clothes.svg', name: 'bath-clothes'},
  {path: 'icons/clothing/briefcase.svg', name: 'briefcase'},
  {path: 'icons/clothing/dress.svg', name: 'dress'},
  {path: 'icons/clothing/shirt.svg', name: 'shirt'},
  {path: 'icons/clothing/underwear.svg', name: 'underwear'},
  {path: 'icons/clothing/skirt.svg', name: 'skirt'},
  {path: 'icons/clothing/cullinary-cloth.svg', name: 'cullinary-cloth'},
  {path: 'icons/clothing/hand-watch.svg', name: 'hand-watch'}
];
