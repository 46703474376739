import { TenantAction, TenantTypes } from './tenant.action';
import { Tenant } from '@btr/modules/login/models/tenant.model';

export interface TenantState {
  tenant: Tenant;
  loaded: boolean;
  loading: boolean;
}

export const initialState: TenantState = {
  tenant: null,
  loading: false,
  loaded: false
};

export function tenantReducer(state: TenantState = initialState, action: TenantAction) {
  switch (action.type) {
    case TenantTypes.LoginBtr:
    case TenantTypes.RefreshToken: {
      return {
        ...state,
        loaded: false,
        loading: true
      };
    }
    case TenantTypes.LoginBtrSuccess:
    case TenantTypes.RefreshTokenSuccess: {
      const {accessToken} = action.payload;
      const {refreshToken} = action.payload;
      const {idToken} = action.payload;

      return {
        ...state,
        tenant: {...state.tenant, accessToken, refreshToken, idToken},
        loaded: false, // Wait until barn is set in memory
        loading: false
      };
    }
    case TenantTypes.LoginBtrError:
    case TenantTypes.RefreshTokenError:
    case TenantTypes.Logout: {
      return {
        ...state,
        tenant: null,
        loaded: false,
        loading: false
      };
    }

    case TenantTypes.SetBarnId: {
      return {
        ...state,
        tenant: {...state.tenant, barnId: action.payload},
        loaded: true,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
}
