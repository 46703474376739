import { Icon } from '../icon';

export const iconsTexts: Icon[] = [
  {path: 'icons/texts/align.svg', name: 'align'},
  {path: 'icons/texts/text-box.svg', name: 'text-box'},
  {path: 'icons/texts/tap.svg', name: 'tap'},
  {path: 'icons/texts/full-justify.svg', name: 'full-justify'},
  {path: 'icons/texts/align-right-box.svg', name: 'align-right-box'},
  {path: 'icons/texts/tap-alt.svg', name: 'tap-alt'},
  {path: 'icons/texts/align-center.svg', name: 'align-center'},
  {path: 'icons/texts/align-left-box.svg', name: 'align-left-box'},
  {path: 'icons/texts/pointer-arrow-up.svg', name: 'pointer-arrow-up'},
  {path: 'icons/texts/align-right.svg', name: 'align-right'},
  {path: 'icons/texts/layout-box.svg', name: 'layout-box'},
  {path: 'icons/texts/pointer-arrow-down.svg', name: 'pointer-arrow-down'},
  {path: 'icons/texts/align-left.svg', name: 'align-left'},
  {path: 'icons/texts/layout-box-alt.svg', name: 'layout-box-alt'},
  {path: 'icons/texts/pointer-minimize.svg', name: 'pointer-minimize'},
  {path: 'icons/texts/align-center-vertical.svg', name: 'align-center-vertical'},
  {path: 'icons/texts/pointer-box.svg', name: 'pointer-box'},
  {path: 'icons/texts/pointer-maximize.svg', name: 'pointer-maximize'},
  {path: 'icons/texts/pointer-up.svg', name: 'pointer-up'},
  {path: 'icons/texts/pointer-circle.svg', name: 'pointer-circle'},
  {path: 'icons/texts/pointer-framed.svg', name: 'pointer-framed'},
  {path: 'icons/texts/pointer-down.svg', name: 'pointer-down'},
  {path: 'icons/texts/double-tap.svg', name: 'double-tap'},
  {path: 'icons/texts/pointer-expand.svg', name: 'pointer-expand'},
  {path: 'icons/texts/pointer-right.svg', name: 'pointer-right'},
  {path: 'icons/texts/align-center-horizontal.svg', name: 'align-center-horizontal'},
  {path: 'icons/texts/layout.svg', name: 'layout'},
  {path: 'icons/texts/pointer-left.svg', name: 'pointer-left'},
  {path: 'icons/texts/italic-box.svg', name: 'italic-box'},
  {path: 'icons/texts/layout-alt.svg', name: 'layout-alt'},
  {path: 'icons/texts/align-bottom-left.svg', name: 'align-bottom-left'},
  {path: 'icons/texts/move-text-vertical.svg', name: 'move-text-vertical'},
  {path: 'icons/texts/sort-up-alt.svg', name: 'sort-up-alt'},
  {path: 'icons/texts/align-top-right.svg', name: 'align-top-right'},
  {path: 'icons/texts/paragrph.svg', name: 'paragrph'},
  {path: 'icons/texts/sort-down-alt.svg', name: 'sort-down-alt'},
  {path: 'icons/texts/all-caps.svg', name: 'all-caps'},
  {path: 'icons/texts/paragraph-indent.svg', name: 'paragraph-indent'},
  {path: 'icons/texts/space-between.svg', name: 'space-between'},
  {path: 'icons/texts/smart-align-horizontal.svg', name: 'smart-align-horizontal'},
  {path: 'icons/texts/move-text-right-alt.svg', name: 'move-text-right-alt'},
  {path: 'icons/texts/bold.svg', name: 'bold'},
  {path: 'icons/texts/smart-align-vertical.svg', name: 'smart-align-vertical'},
  {path: 'icons/texts/move-text-left-alt.svg', name: 'move-text-left-alt'},
  {path: 'icons/texts/strike-trough.svg', name: 'strike-trough'},
  {path: 'icons/texts/v-a.svg', name: 'v-a'},
  {path: 'icons/texts/upper-lower-case.svg', name: 'upper-lower-case'},
  {path: 'icons/texts/italic.svg', name: 'italic'},
  {path: 'icons/texts/underline-box.svg', name: 'underline-box'},
  {path: 'icons/texts/text-close.svg', name: 'text-close'},
  {path: 'icons/texts/underline.svg', name: 'underline'},
  {path: 'icons/texts/text-bounding-box.svg', name: 'text-bounding-box'},
  {path: 'icons/texts/auto-layout.svg', name: 'auto-layout'},
  {path: 'icons/texts/sort-alphabetical.svg', name: 'sort-alphabetical'},
  {path: 'icons/texts/move-text-left.svg', name: 'move-text-left'},
  {path: 'icons/texts/line-spacing.svg', name: 'line-spacing'},
  {path: 'icons/texts/sort-alphabetical-alt.svg', name: 'sort-alphabetical-alt'},
  {path: 'icons/texts/move-text-right.svg', name: 'move-text-right'},
  {path: 'icons/texts/sort-up.svg', name: 'sort-up'},
  {path: 'icons/texts/sort-numbers.svg', name: 'sort-numbers'},
  {path: 'icons/texts/smart-align-left.svg', name: 'smart-align-left'},
  {path: 'icons/texts/sort-down.svg', name: 'sort-down'}
];
