import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { SidebarComponent } from '@btr/modules/layout/containers/sidebar/sidebar.component';
import { RouterLink } from '@angular/router';
import { SidebarButtonComponent } from '@btr/modules/layout/components/sidebar-button/sidebar-button.component';
import { TopbarComponent } from '@btr/modules/layout/containers/topbar/topbar.component';
import { KitModule } from '@kit/kit.module';

const materialModules = [
  MatIconModule
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterLink,

    ...materialModules,
    KitModule
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {}
    }
  ],
  exports: [
    SidebarComponent,
    TopbarComponent
  ],
  declarations: [
    SidebarComponent,
    SidebarButtonComponent,
    TopbarComponent
  ]
})
export class LayoutModule {}
