import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NoteService } from '@btr/modules/note/services/note.service';
import { NoteSave, NoteSaveError, NoteSaveSuccess, NoteTypes, LoadAllNoteByHorse, LoadAllNoteByHorseError, LoadAllNoteByHorseSuccess, NoteDelete, NoteDeleteSuccess, NoteDeleteError } from '@btr/modules/note/stores/note.action';
import { Note } from '@btr/modules/note/models/note.model';

@Injectable()
export class NoteEffect {
  constructor(private toastrService: ToastrService, private noteService: NoteService) {}

  loadAll$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<LoadAllNoteByHorse>(NoteTypes.LoadAllByHorse),
      switchMap((action) => this.noteService.loadAll(action.payload).pipe(
          map((notes: Note[]) => new LoadAllNoteByHorseSuccess(notes)),
          catchError(error => of(new LoadAllNoteByHorseError(error)))
        )
      )
    )
  );

  save$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<NoteSave>(NoteTypes.Save),
      switchMap((action) => this.noteService.save(action.payload.note, action.payload.horseId).pipe(
          map((note: Note) => new NoteSaveSuccess(note)),
          catchError(error => of(new NoteSaveError(error)))
        )
      )
    )
  );

  delete$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<NoteDelete>(NoteTypes.Delete),
      switchMap((action) => this.noteService.delete(action.payload.noteId, action.payload.horseId).pipe(
          map(() => new NoteDeleteSuccess()),
          catchError(error => of(new NoteDeleteError(error)))
        )
      )
    )
  );

  deleteSuccess$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<NoteDeleteSuccess>(NoteTypes.DeleteSuccess),
      tap(() => this.toastrService.success("Changes saved"))
    ),
    {dispatch: false}
  );

  error$ = createEffect(() =>
      inject(Actions).pipe(
        ofType(NoteTypes.LoadAllByHorseError, NoteTypes.SaveError, NoteTypes.DeleteError),
        tap(_ => {
          this.toastrService.error("An error has occured, try again");
        })),
    {dispatch: false}
  );
}
