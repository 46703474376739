import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../services/user.service';
import { UserTypes, LoadOneUser, LoadOneUserError, LoadOneUserSuccess, UserSave, UserSaveError, UserSaveSuccess, LoadAllUser, LoadAllUserSuccess, LoadAllUserError } from '@btr/modules/user/stores/user.action';
import { User } from '@btr/modules/user/models/user.model';
import { selectProfile } from '@btr/modules/profile/stores/profile.selector';
import { AppState } from '@core/store/app.reducer';
import { Store } from '@ngrx/store';

@Injectable()
export class UserEffect {
  constructor(private actions$: Actions, private store: Store<AppState>, private toastrService: ToastrService, private userService: UserService) {}

  load$ = createEffect(() => this.actions$.pipe(
    ofType<LoadOneUser>(UserTypes.LoadOne),
    withLatestFrom(this.store?.select(selectProfile)),
    switchMap(([action, profile]) => this.userService.load(action.payload, profile?.id).pipe(
        map((profile: User) => new LoadOneUserSuccess(profile)),
        catchError(error => of(new LoadOneUserError(error)))
      )
    ))
  );

  loadAll$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<LoadAllUser>(UserTypes.LoadAll),
      switchMap(() => this.userService.loadAll().pipe(
          map((users: User[]) => new LoadAllUserSuccess(users)),
          catchError(error => of(new LoadAllUserError(error)))
        )
      )
    )
  );

  save$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<UserSave>(UserTypes.Save),
      switchMap((action) => this.userService.save(action.payload).pipe(
          map((profile: User) => new UserSaveSuccess(profile)),
          catchError(error => of(new UserSaveError(error)))
        )
      )
    )
  );

  saveSuccess$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<UserSaveSuccess>(UserTypes.SaveSuccess),
      tap((action) => {
        this.toastrService.success("Changes saved");
      })
    ), {dispatch: false}
  );

  error$ = createEffect(() =>
      inject(Actions).pipe(
        ofType(UserTypes.LoadOneError, UserTypes.LoadAllError, UserTypes.SaveError),
        tap(_ => this.toastrService.error("An error has occured, try again"))),
    {dispatch: false}
  );
}
