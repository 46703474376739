import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any) {
    const chunkFailedMessage = /Loading chunk (.*?)+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      this.showPopUp();
    }

    if (error.status === 0) {
      this.showPopUp();
    }

    if (error.stack) {
      console.log(error.stack.toString()); // Obligatoire pour afficher les erreurs
    }
  }

  private showPopUp() {
    if (confirm('Erreur de chargement des données, appuyer sur Valider pour recharger la page')) {
      window.location.reload();
    }
  }
}
