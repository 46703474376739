import { Icon } from '../icon';

export const iconsTechnology: Icon[] = [
  {path: 'icons/technology/computer.svg', name: 'computer'},
  {path: 'icons/technology/plug.svg', name: 'plug'},
  {path: 'icons/technology/game-controler.svg', name: 'game-controler'},
  {path: 'icons/technology/laptop.svg', name: 'laptop'},
  {path: 'icons/technology/disk.svg', name: 'disk'},
  {path: 'icons/technology/folder-closed.svg', name: 'folder-closed'},
  {path: 'icons/technology/printer.svg', name: 'printer'},
  {path: 'icons/technology/disk-pause.svg', name: 'disk-pause'},
  {path: 'icons/technology/disk-pause-alt.svg', name: 'disk-pause-alt'},
  {path: 'icons/technology/mouse.svg', name: 'mouse'},
  {path: 'icons/technology/folder-opened.svg', name: 'folder-opened'},
  {path: 'icons/technology/sim-card.svg', name: 'sim-card'},
  {path: 'icons/technology/memory-card.svg', name: 'memory-card'},
  {path: 'icons/technology/keyboard.svg', name: 'keyboard'},
  {path: 'icons/technology/plug-alt.svg', name: 'plug-alt'},
  {path: 'icons/technology/card.svg', name: 'card'},
  {path: 'icons/technology/folder-alt.svg', name: 'folder-alt'},
  {path: 'icons/technology/on-off-button.svg', name: 'on-off-button'},
  {path: 'icons/technology/floppy-disk.svg', name: 'floppy-disk'},
  {path: 'icons/technology/printer-alt.svg', name: 'printer-alt'},
  {path: 'icons/technology/mouse.svg', name: 'mouse'},
  {path: 'icons/technology/mobile.svg', name: 'mobile'},
  {path: 'icons/technology/bettery-medium.svg', name: 'bettery-medium'},
  {path: 'icons/technology/mouse-alt.svg', name: 'mouse-alt'},
  {path: 'icons/technology/mobile-ring-1.svg', name: 'mobile-ring-1'},
  {path: 'icons/technology/bettery-high.svg', name: 'bettery-high'},
  {path: 'icons/technology/mobile-signal-low.svg', name: 'mobile-signal-low'},
  {path: 'icons/technology/mobile-ring-2.svg', name: 'mobile-ring-2'},
  {path: 'icons/technology/batery.svg', name: 'batery'},
  {path: 'icons/technology/lightning.svg', name: 'lightning'},
  {path: 'icons/technology/mobile-ring-3.svg', name: 'mobile-ring-3'},
  {path: 'icons/technology/paper-plane.svg', name: 'paper-plane'},
  {path: 'icons/technology/bluetooth.svg', name: 'bluetooth'},
  {path: 'icons/technology/mobile-user.svg', name: 'mobile-user'},
  {path: 'icons/technology/wifi.svg', name: 'wifi'},
  {path: 'icons/technology/battery-charging.svg', name: 'battery-charging'},
  {path: 'icons/technology/mobile-swipe-right.svg', name: 'mobile-swipe-right'},
  {path: 'icons/technology/telephone-tower.svg', name: 'telephone-tower'},
  {path: 'icons/technology/wifi.svg', name: 'wifi'},
  {path: 'icons/technology/mobile-swipe-left.svg', name: 'mobile-swipe-left'},
  {path: 'icons/technology/mobile-signal-high.svg', name: 'mobile-signal-high'},
  {path: 'icons/technology/wifi-heart.svg', name: 'wifi-heart'},
  {path: 'icons/technology/bettery-low.svg', name: 'bettery-low'},
  {path: 'icons/technology/mobile-signal-medium.svg', name: 'mobile-signal-medium'},
  {path: 'icons/technology/phone-ring-3.svg', name: 'phone-ring-3'},
  {path: 'icons/technology/phone.svg', name: 'phone'},
  {path: 'icons/technology/call-out.svg', name: 'call-out'},
  {path: 'icons/technology/table-phone.svg', name: 'table-phone'},
  {path: 'icons/technology/phone-block.svg', name: 'phone-block'},
  {path: 'icons/technology/call-in.svg', name: 'call-in'},
  {path: 'icons/technology/phone-station.svg', name: 'phone-station'},
  {path: 'icons/technology/phone-circle.svg', name: 'phone-circle'},
  {path: 'icons/technology/phone-ring-2.svg', name: 'phone-ring-2'},
  {path: 'icons/technology/end-call-3.svg', name: 'end-call-3'},
  {path: 'icons/technology/audio-message.svg', name: 'audio-message'},
  {path: 'icons/technology/end-call-1.svg', name: 'end-call-1'},
  {path: 'icons/technology/phone-book.svg', name: 'phone-book'},
  {path: 'icons/technology/phone-ring-1.svg', name: 'phone-ring-1'},
  {path: 'icons/technology/end-call-2.svg', name: 'end-call-2'},
  {path: 'icons/technology/phone-off.svg', name: 'phone-off'}
];
