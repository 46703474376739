import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadComponent } from './upload.component';
import { IconModule } from '../icon/icon.module';

@NgModule({
  declarations: [UploadComponent],
  imports: [CommonModule, IconModule],
  exports: [UploadComponent],
})
export class UploadModule {}
