import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'kit-dropdown',
  templateUrl: './dropdown.component.html'
})
export class DropdownComponent {
  @Input() disabled: boolean = false;
  @Input() dropFull: boolean = false;
  @Output() action: EventEmitter<void> = new EventEmitter<void>();

  onMenuOpened() {
    if (!this.disabled) {
      this.action.emit();
    }
  }
}
