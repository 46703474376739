import { Action } from '@ngrx/store';
import { Task } from '@btr/modules/task/models/task.model';

export enum TaskTypes {
  LoadAll = '[TASK] LOAD ALL',
  LoadAllSuccess = '[TASK] LOAD ALL SUCCESS',
  LoadAllError = '[TASK] LOAD ALL ERROR',

  LoadOne = '[TASK] LOAD ONE',
  LoadOneSuccess = '[TASK] LOAD ONE SUCCESS',
  LoadOneError = '[TASK] LOAD ONE ERROR',

  Save = '[TASK] SAVE',
  SaveSuccess = '[TASK] SAVE SUCCESS',
  SaveError = '[TASK] SAVE ERROR',
}

/**
 * Load all tasks
 */
export class LoadAllTask implements Action {
  readonly type = TaskTypes.LoadAll;
}
export class LoadAllTaskSuccess implements Action {
  readonly type = TaskTypes.LoadAllSuccess;
  constructor(public payload: Task[]) {}
}
export class LoadAllTaskError implements Action {
  readonly type = TaskTypes.LoadAllError;
  constructor(public payload: any) {}
}

/**
 * Load one task
 */
export class LoadOneTask implements Action {
  readonly type = TaskTypes.LoadOne;
  constructor(public payload: number) {}
}
export class LoadOneTaskSuccess implements Action {
  readonly type = TaskTypes.LoadOneSuccess;
  constructor(public payload: Task) {}
}
export class LoadOneTaskError implements Action {
  readonly type = TaskTypes.LoadOneError;
  constructor(public payload: any) {}
}

/**
 * Save one task
 */
export class TaskSave implements Action {
  readonly type = TaskTypes.Save;
  constructor(public payload: Task) {}
}
export class TaskSaveSuccess implements Action {
  readonly type = TaskTypes.SaveSuccess;
  constructor(public payload: Task) {}
}
export class TaskSaveError implements Action {
  readonly type = TaskTypes.SaveError;
  constructor(public payload: any) {}
}

export type TaskAction = LoadAllTask
  | LoadAllTaskSuccess
  | LoadAllTaskError
  | LoadOneTask
  | LoadOneTaskSuccess
  | LoadOneTaskError
  | TaskSave
  | TaskSaveSuccess
  | TaskSaveError;
