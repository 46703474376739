import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, NavigationExtras, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { WorkerService } from "@core/services/worker/worker.service";
import { AppState } from '@core/store/app.reducer';
import { Go } from '@core/store/router/router.action';

@Injectable({ providedIn: "root" })
export class RouterService {

  private newVersionReloaded = false;

  constructor(private store: Store<AppState>, private router: Router, private workerService: WorkerService) {
    this.initReloadIfNewVersion();
  }

  private initReloadIfNewVersion() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.workerService.newVersionReady && !this.newVersionReloaded && event.url.split("/").length < 3) {
          this.newVersionReloaded = true;
          location.reload();
        }
      }
    });
  }

  gotoParent(route: ActivatedRoute | ActivatedRouteSnapshot, extra?: NavigationExtras) {
    let snapshot = route instanceof ActivatedRoute ? route.snapshot : route;
    const segmentSelf = snapshot.url[0];
    const segments: string[] = (snapshot as any)._routerState.url?.split("/");
    segments.shift();
    const index = segments?.findIndex(s => s === segmentSelf.path);
    if (index < 1) {
      this.store.dispatch(new Go({ path: [""], extra }));
      return;
    }
    const prevPathConcat = segments.reduce((acc, s, i) => i < index ? acc + "/" + s : acc, "");
    this.store.dispatch(new Go({ path: [prevPathConcat], extra }));
  }

  gotoParentAt(positionRelative: number, extra?: NavigationExtras) {
    const segments = this.router.routerState.snapshot.url.split("/");
    const position =  segments.length + positionRelative;
    if (position < 0 || position > segments.length) {
      throw new Error("Position gotoParentAt impossible");
    }
    const segmentSpliced = segments.splice(0, position);
    this.store.dispatch(new Go({ path: [segmentSpliced.join("/")], extra }));
  }

  gotoChild(child: string, extra?: NavigationExtras) {
    const prefix = this.router.routerState.snapshot.url;
    this.store.dispatch(new Go({ path: [prefix + "/" + child], extra }));
  }

  reload(route?: ActivatedRoute | ActivatedRouteSnapshot, extras?: NavigationExtras) {
    let snapshot = route instanceof ActivatedRoute ? route.snapshot : route;
    if (!snapshot) {
      snapshot = this.router.routerState.snapshot.root;
    }
    const oldFunc = this.router.routeReuseStrategy.shouldReuseRoute;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate([(snapshot as any)._routerState.url], extras).then(() => {
      this.router.routeReuseStrategy.shouldReuseRoute = oldFunc;
    });
  }
}
