import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Horse, HorseFavorite } from '@btr/modules/horse/models/horse.model';
import { environment } from '@env/environment';

@Injectable({providedIn: 'root'})
export class HorseService {
  date: Date = new Date();

  constructor(private http: HttpClient) {}

  loadAll(): Observable<Horse[]> {
    return this.http.get<Horse[]>(`${environment.api()}/horse`);
  }

  loadOne(horseId: number): Observable<Horse> {
    return this.http.get<Horse>(`${environment.api()}/horse/${horseId}`);
  }

  save(horse: Horse): Observable<Horse> {
    if (horse.id) {
      return this.http.patch<Horse>(`${environment.api()}/horse/${horse.id}`, horse);
    }
    return this.http.post<Horse>(`${environment.api()}/horse`, horse);
  }

  saveFavorite(horse: HorseFavorite): Observable<Horse> {
    return this.http.patch<Horse>(`${environment.api()}/horse/${horse.id}/favorite`, horse);
  }
}
