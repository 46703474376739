import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { RouterActionTypes } from "./router.action";
import { map } from 'rxjs';

@Injectable()
export class RouterEffect {
  constructor(private router: Router) {}

  go$ = createEffect(() =>
    inject(Actions).pipe(
      ofType(RouterActionTypes.Go, RouterActionTypes.Back),
      map((action: any) => action.payload),
      tap(({path, extra}) => {
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(path, extra).then();
      })
    ), {dispatch: false});
}
