import { Injectable } from "@angular/core";

export const TENANT: string = 'TENANT';
export const BARN: string = 'BARN';

@Injectable({providedIn: 'root'})
export class StorageService {
  getRefreshToken(): string {
    const storageJSON: string = localStorage.getItem(TENANT);
    return storageJSON ? JSON.parse(storageJSON) : null;
  }

  postRefreshToken(token: string) {
    if (token) {
      localStorage.setItem(TENANT, JSON.stringify(token));
    }
  }

  getBarnId(): string {
    const storageJSON: string = localStorage.getItem(BARN);
    return storageJSON ? JSON.parse(storageJSON) : null;
  }

  postBarnId(id: number) {
    if (id) {
      localStorage.setItem(BARN, JSON.stringify(id));
    }
  }

  clearStorage() {
    localStorage.clear();
  }
}
