import { Icon } from '../icon';

export const iconsEssentials: Icon[] = [
  {path: 'icons/essentials/placeholder.svg', name: 'placeholder'},
  {path: 'icons/essentials/question.svg', name: 'question'},
  {path: 'icons/essentials/happy.svg', name: 'happy'},
  {path: 'icons/essentials/plus.svg', name: 'plus'},
  {path: 'icons/essentials/information.svg', name: 'information'},
  {path: 'icons/essentials/neutral.svg', name: 'neutral'},
  {path: 'icons/essentials/minus.svg', name: 'minus'},
  {path: 'icons/essentials/block.svg', name: 'block'},
  {path: 'icons/essentials/sad.svg', name: 'sad'},
  {path: 'icons/essentials/close-cross.svg', name: 'close-cross'},
  {path: 'icons/essentials/alert.svg', name: 'alert'},
  {path: 'icons/essentials/shareplay.svg', name: 'shareplay'},
  {path: 'icons/essentials/check.svg', name: 'check'},
  {path: 'icons/essentials/alert-octo.svg', name: 'alert-octo'},
  {path: 'icons/essentials/video.svg', name: 'video'},
  {path: 'icons/essentials/slash.svg', name: 'slash'},
  {path: 'icons/essentials/stop-minus.svg', name: 'stop-minus'},
  {path: 'icons/essentials/presentation.svg', name: 'presentation'},
  {path: 'icons/essentials/hashtag.svg', name: 'hashtag'},
  {path: 'icons/essentials/stop-sign.svg', name: 'stop-sign'},
  {path: 'icons/essentials/airplay-video.svg', name: 'airplay-video'},
  {path: 'icons/essentials/checkbox.svg', name: 'checkbox'},
  {path: 'icons/essentials/alert-hex.svg', name: 'alert-hex'},
  {path: 'icons/essentials/connect-tv.svg', name: 'connect-tv'},
  {path: 'icons/essentials/eye.svg', name: 'eye'},
  {path: 'icons/essentials/alert-fire.svg', name: 'alert-fire'},
  {path: 'icons/essentials/at-symbol.svg', name: 'at-symbol'},
  {path: 'icons/essentials/eye-off.svg', name: 'eye-off'},
  {path: 'icons/essentials/alert-sign.svg', name: 'alert-sign'},
  {path: 'icons/essentials/airplay.svg', name: 'airplay'},
  {path: 'icons/essentials/asterisk.svg', name: 'asterisk'},
  {path: 'icons/essentials/alert-sign-alt.svg', name: 'alert-sign-alt'},
  {path: 'icons/essentials/subtitles.svg', name: 'subtitles'},
  {path: 'icons/essentials/asterisk-box.svg', name: 'asterisk-box'},
  {path: 'icons/essentials/alert-wifi.svg', name: 'alert-wifi'},
  {path: 'icons/essentials/instagram-reels.svg', name: 'instagram-reels'},
  {path: 'icons/essentials/asterisk-circle.svg', name: 'asterisk-circle'},
  {path: 'icons/essentials/layers-1.svg', name: 'layers-1'},
  {path: 'icons/essentials/video-camera.svg', name: 'video-camera'},
  {path: 'icons/essentials/asterisk-reload.svg', name: 'asterisk-reload'},
  {path: 'icons/essentials/layers-2.svg', name: 'layers-2'},
  {path: 'icons/essentials/send.svg', name: 'send'},
  {path: 'icons/essentials/human-box.svg', name: 'human-box'},
  {path: 'icons/essentials/layers-3.svg', name: 'layers-3'},
  {path: 'icons/essentials/security-camera.svg', name: 'security-camera'},
  {path: 'icons/essentials/human-circle.svg', name: 'human-circle'},
  {path: 'icons/essentials/book.svg', name: 'book'},
  {path: 'icons/essentials/camera-shutter.svg', name: 'camera-shutter'},
  {path: 'icons/essentials/human.svg', name: 'human'},
  {path: 'icons/essentials/academic-cap.svg', name: 'academic-cap'},
  {path: 'icons/essentials/photo-camera.svg', name: 'photo-camera'},
  {path: 'icons/essentials/gear-1.svg', name: 'gear-1'},
  {path: 'icons/essentials/book-opened.svg', name: 'book-opened'},
  {path: 'icons/essentials/photo-double.svg', name: 'photo-double'},
  {path: 'icons/essentials/gear-2.svg', name: 'gear-2'},
  {path: 'icons/essentials/pin.svg', name: 'pin'},
  {path: 'icons/essentials/pin-filled.svg', name: 'pin-filled'},
  {path: 'icons/essentials/frame-close.svg', name: 'frame-close'},
  {path: 'icons/essentials/wreach.svg', name: 'wreach'},
  {path: 'icons/essentials/link-1-diagonal.svg', name: 'link-1-diagonal'},
  {path: 'icons/essentials/frame-person.svg', name: 'frame-person'},
  {path: 'icons/essentials/filter.svg', name: 'filter'},
  {path: 'icons/essentials/link-1.svg', name: 'link-1'},
  {path: 'icons/essentials/frame-check.svg', name: 'frame-check'},
  {path: 'icons/essentials/filter-off.svg', name: 'filter-off'},
  {path: 'icons/essentials/link-2.svg', name: 'link-2'},
  {path: 'icons/essentials/photo.svg', name: 'photo'},
  {path: 'icons/essentials/settings-sliders.svg', name: 'settings-sliders'},
  {path: 'icons/essentials/link-2-diagonal.svg', name: 'link-2-diagonal'},
  {path: 'icons/essentials/like.svg', name: 'like'},
  {path: 'icons/essentials/settings-3.svg', name: 'settings-3'},
  {path: 'icons/essentials/link-3-diagonal.svg', name: 'link-3-diagonal'},
  {path: 'icons/essentials/dislike.svg', name: 'dislike'},
  {path: 'icons/essentials/settings-2.svg', name: 'settings-2'},
  {path: 'icons/essentials/link-3.svg', name: 'link-3'},
  {path: 'icons/essentials/heart.svg', name: 'heart'},
  {path: 'icons/essentials/list-full.svg', name: 'list-full'},
  {path: 'icons/essentials/link-break.svg', name: 'link-break'},
  {path: 'icons/essentials/broken-heart.svg', name: 'broken-heart'},
  {path: 'icons/essentials/list-unchecked.svg', name: 'list-unchecked'},
  {path: 'icons/essentials/external-link.svg', name: 'external-link'},
  {path: 'icons/essentials/double-heart.svg', name: 'double-heart'},
  {path: 'icons/essentials/search-list.svg', name: 'search-list'},
  {path: 'icons/essentials/zoom-search.svg', name: 'zoom-search'},
  {path: 'icons/essentials/sent.svg', name: 'sent'},
  {path: 'icons/essentials/list.svg', name: 'list'},
  {path: 'icons/essentials/zoom-plus.svg', name: 'zoom-plus'},
  {path: 'icons/essentials/user.svg', name: 'user'},
  {path: 'icons/essentials/list-small.svg', name: 'list-small'},
  {path: 'icons/essentials/zoom-minus.svg', name: 'zoom-minus'},
  {path: 'icons/essentials/user-heart.svg', name: 'user-heart'},
  {path: 'icons/essentials/toggle.svg', name: 'toggle'},
  {path: 'icons/essentials/zoom-close.svg', name: 'zoom-close'},
  {path: 'icons/essentials/user-block.svg', name: 'user-block'},
  {path: 'icons/essentials/bookmark.svg', name: 'bookmark'},
  {path: 'icons/essentials/zoom-check.svg', name: 'zoom-check'},
  {path: 'icons/essentials/user-search.svg', name: 'user-search'},
  {path: 'icons/essentials/bookmark-plus.svg', name: 'bookmark-plus'},
  {path: 'icons/essentials/rotate-right.svg', name: 'rotate-right'},
  {path: 'icons/essentials/user-cloud.svg', name: 'user-cloud'},
  {path: 'icons/essentials/bookmark-minus.svg', name: 'bookmark-minus'},
  {path: 'icons/essentials/rotate-left.svg', name: 'rotate-left'},
  {path: 'icons/essentials/user-male.svg', name: 'user-male'},
  {path: 'icons/essentials/bookmark-close.svg', name: 'bookmark-close'},
  {path: 'icons/essentials/rotate-left-plus.svg', name: 'rotate-left-plus'},
  {path: 'icons/essentials/user-female.svg', name: 'user-female'},
  {path: 'icons/essentials/bookmark-check.svg', name: 'bookmark-check'},
  {path: 'icons/essentials/rotate-left-minus.svg', name: 'rotate-left-minus'},
  {path: 'icons/essentials/user-circle.svg', name: 'user-circle'},
  {path: 'icons/essentials/alarm-clock.svg', name: 'alarm-clock'},
  {path: 'icons/essentials/rotate-left-close.svg', name: 'rotate-left-close'},
  {path: 'icons/essentials/user-double.svg', name: 'user-double'},
  {path: 'icons/essentials/alarm-clock-alt.svg', name: 'alarm-clock-alt'},
  {path: 'icons/essentials/rotate-left-check.svg', name: 'rotate-left-check'},
  {path: 'icons/essentials/user-messaging.svg', name: 'user-messaging'},
  {path: 'icons/essentials/alarm-clock-alt-sleep.svg', name: 'alarm-clock-alt-sleep'},
  {path: 'icons/essentials/rotate-360.svg', name: 'rotate-360'},
  {path: 'icons/essentials/user-security.svg', name: 'user-security'},
  {path: 'icons/essentials/alarm-clock-alert.svg', name: 'alarm-clock-alert'},
  {path: 'icons/essentials/loading.svg', name: 'loading'},
  {path: 'icons/essentials/user-note.svg', name: 'user-note'},
  {path: 'icons/essentials/timer.svg', name: 'timer'},
  {path: 'icons/essentials/loading-alt.svg', name: 'loading-alt'},
  {path: 'icons/essentials/switch-user.svg', name: 'switch-user'},
  {path: 'icons/essentials/timer-lightning.svg', name: 'timer-lightning'},
  {path: 'icons/essentials/archive.svg', name: 'archive'},
  {path: 'icons/essentials/out.svg', name: 'out'},
  {path: 'icons/essentials/timer-off.svg', name: 'timer-off'},
  {path: 'icons/essentials/bookmark-alt.svg', name: 'bookmark-alt'},
  {path: 'icons/essentials/in.svg', name: 'in'},
  {path: 'icons/essentials/timer-alt.svg', name: 'timer-alt'},
  {path: 'icons/essentials/bookmark-alt-plus.svg', name: 'bookmark-alt-plus'},
  {path: 'icons/essentials/inbox-close.svg', name: 'inbox-close'},
  {path: 'icons/essentials/check-loading.svg', name: 'check-loading'},
  {path: 'icons/essentials/bookmark-alt-minus.svg', name: 'bookmark-alt-minus'},
  {path: 'icons/essentials/trash.svg', name: 'trash'},
  {path: 'icons/essentials/time-loading.svg', name: 'time-loading'},
  {path: 'icons/essentials/bookmark-alt-close.svg', name: 'bookmark-alt-close'},
  {path: 'icons/essentials/trash-alt.svg', name: 'trash-alt'},
  {path: 'icons/essentials/time-sleep.svg', name: 'time-sleep'},
  {path: 'icons/essentials/label-1.svg', name: 'label-1'},
  {path: 'icons/essentials/ring-notification-1.svg', name: 'ring-notification-1'},
  {path: 'icons/essentials/sandclock.svg', name: 'sandclock'},
  {path: 'icons/essentials/label-2.svg', name: 'label-2'},
  {path: 'icons/essentials/ring-notification-2.svg', name: 'ring-notification-2'},
  {path: 'icons/essentials/sleep.svg', name: 'sleep'},
  {path: 'icons/essentials/label-3.svg', name: 'label-3'},
  {path: 'icons/essentials/ring-notification-3.svg', name: 'ring-notification-3'},
  {path: 'icons/essentials/calendar.svg', name: 'calendar'},
  {path: 'icons/essentials/label-double.svg', name: 'label-double'},
  {path: 'icons/essentials/ring-notification-2-close.svg', name: 'ring-notification-2-close'},
  {path: 'icons/essentials/calendar-check-alt.svg', name: 'calendar-check-alt'},
  {path: 'icons/essentials/enter.svg', name: 'enter'},
  {path: 'icons/essentials/ring-notification-off.svg', name: 'ring-notification-off'},
  {path: 'icons/essentials/calendar-minus.svg', name: 'calendar-minus'},
  {path: 'icons/essentials/exit.svg', name: 'exit'},
  {path: 'icons/essentials/star.svg', name: 'star'},
  {path: 'icons/essentials/star-filled.svg', name: 'star-filled'},
  {path: 'icons/essentials/calendar-blocked.svg', name: 'calendar-blocked'},
  {path: 'icons/essentials/exit-alt.svg', name: 'exit-alt'},
  {path: 'icons/essentials/star-plus.svg', name: 'star-plus'},
  {path: 'icons/essentials/calendar-check.svg', name: 'calendar-check'},
  {path: 'icons/essentials/enter-alt.svg', name: 'enter-alt'},
  {path: 'icons/essentials/star-minus.svg', name: 'star-minus'},
  {path: 'icons/essentials/calendar-2.svg', name: 'calendar-2'},
  {path: 'icons/essentials/open-door.svg', name: 'open-door'},
  {path: 'icons/essentials/star-close.svg', name: 'star-close'},
  {path: 'icons/essentials/calendar-3.svg', name: 'calendar-3'},
  {path: 'icons/essentials/puzzle-pice.svg', name: 'puzzle-pice'},
  {path: 'icons/essentials/star-check.svg', name: 'star-check'},
  {path: 'icons/essentials/3d-space.svg', name: '3d-space'},
  {path: 'icons/essentials/sticker.svg', name: 'sticker'},
  {path: 'icons/essentials/star-box.svg', name: 'star-box'},
  {path: 'icons/essentials/cube.svg', name: 'cube'},
  {path: 'icons/essentials/magnet.svg', name: 'magnet'},
  {path: 'icons/essentials/star-circle.svg', name: 'star-circle'},
  {path: 'icons/essentials/spin.svg', name: 'spin'},
  {path: 'icons/essentials/diamond.svg', name: 'diamond'},
  {path: 'icons/essentials/half-star-left.svg', name: 'half-star-left'},
  {path: 'icons/essentials/comand-line.svg', name: 'comand-line'},
  {path: 'icons/essentials/offer-check.svg', name: 'offer-check'},
  {path: 'icons/essentials/half-star-right.svg', name: 'half-star-right'}
];
