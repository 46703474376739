import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Profile } from '@btr/modules/profile/models/profile.model';
import { environment } from '@env/environment';

@Injectable({providedIn: 'root'})
export class ProfileService {
  constructor(private http: HttpClient) {}

  load(): Observable<Profile> {
    return this.http.get<Profile>(`${environment.api()}/users/current`);
  }

  save(profile: Profile): Observable<Profile> {
    return this.http.patch<Profile>(`${environment.api()}/users/${profile.id}`, profile);
  }
}
