import { MetricsAction, MetricsTypes } from './metrics.action';

export interface MetricsState {
  gallons: boolean;
}

export const initialState: MetricsState = {
  gallons: false
};

export function metricsReducer(state: MetricsState = initialState, action: MetricsAction) {
  switch (action.type) {
    case MetricsTypes.SetGallons: {
      return {
        ...state,
        gallons: action.payload
      }
    }

    default: {
      return state;
    }
  }
}
