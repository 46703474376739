import { BarnAction, BarnTypes } from './barn.action';
import { Barn } from '@btr/modules/barn/models/barn.model';

export interface BarnState {
  barns: Barn[];
  barn: Barn;
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  saved: boolean;
}

export const initialState: BarnState = {
  barns: [],
  barn: null,
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
};

export function barnReducer(state: BarnState = initialState, action: BarnAction) {
  switch (action.type) {
    case BarnTypes.LoadAll: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case BarnTypes.LoadAllSuccess: {
      return {
        ...state,
        barns: action.payload,
        loading: false,
        loaded: true
      };
    }
    case BarnTypes.LoadAllError: {
      return {
        ...state,
        barns: null,
        loading: false,
        loaded: false
      };
    }

    case BarnTypes.LoadMy: {
      return {
        ...state,
        barn: null,
        loading: true,
        loaded: false
      };
    }
    case BarnTypes.LoadMySuccess: {
      return {
        ...state,
        barn: action.payload,
        loading: false,
        loaded: true
      };
    }
    case BarnTypes.LoadMyError: {
      return {
        ...state,
        barn: null,
        loading: false,
        loaded: false
      };
    }

    case BarnTypes.Save: {
      return {
        ...state,
        saving: true,
        saved: false
      };
    }
    case BarnTypes.SaveSuccess: {
      return {
        ...state,
        saving: false,
        saved: true
      };
    }
    case BarnTypes.SaveError: {
      return {
        ...state,
        saving: false,
        saved: false
      };
    }

    default: {
      return state;
    }
  }
}
