import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BarnService } from '@btr/modules/barn/services/barn.service';
import { BarnSave, BarnSaveError, BarnSaveSuccess, BarnTypes, LoadAllBarn, LoadAllBarnError, LoadAllBarnSuccess, LoadMyBarn, LoadMyBarnError, LoadMyBarnSuccess } from '@btr/modules/barn/stores/barn.action';
import { Barn } from '@btr/modules/barn/models/barn.model';
import { AppState } from '@core/store/app.reducer';
import { Store } from '@ngrx/store';
import { selectMyBarnId } from '@btr/modules/login/stores/tenant.selector';

@Injectable()
export class BarnEffect {
  constructor(private store: Store<AppState>, private toastrService: ToastrService, private barnService: BarnService) {}

  loadAll$ = createEffect(() => inject(Actions).pipe(
    ofType<LoadAllBarn>(BarnTypes.LoadAll),
    switchMap(() => this.barnService.loadAll().pipe(
      map((barns: Barn[]) => new LoadAllBarnSuccess(barns)),
      catchError(error => of(new LoadAllBarnError(error)))
    ))
  ));

  loadMy$ = createEffect(() => inject(Actions).pipe(
    ofType<LoadMyBarn>(BarnTypes.LoadMy),
    withLatestFrom(this.store?.select(selectMyBarnId)),
    switchMap(([_, barnId]) => this.barnService.loadMy(barnId).pipe(
      map((barn: Barn) => new LoadMyBarnSuccess(barn)),
      catchError(error => of(new LoadMyBarnError(error)))
    ))
  ));

  save$ = createEffect(() => inject(Actions).pipe(
    ofType<BarnSave>(BarnTypes.Save),
    switchMap((action) => this.barnService.save(action.payload).pipe(
      map((barn: Barn) => new BarnSaveSuccess(barn)),
      catchError(error => of(new BarnSaveError(error)))
    ))
  ));

  saveSuccess$ = createEffect(() => inject(Actions).pipe(
      ofType<BarnSaveSuccess>(BarnTypes.SaveSuccess),
      tap(() => this.toastrService.success("Changes saved"))
    ), {dispatch: false}
  );

  error$ = createEffect(() => inject(Actions).pipe(
        ofType(BarnTypes.LoadAllError, BarnTypes.LoadMyError, BarnTypes.SaveError),
        tap(_ => this.toastrService.error("An error has occured, try again"))),
    {dispatch: false}
  );
}
