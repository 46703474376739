import { Icon } from '../icon';

export const iconsMessages: Icon[] = [
  {path: 'icons/messages/message-circle.svg', name: 'message-circle'},
  {path: 'icons/messages/message-circle-3.svg', name: 'message-circle-3'},
  {path: 'icons/messages/message-square.svg', name: 'message-square'},
  {path: 'icons/messages/message-circle-plus.svg', name: 'message-circle-plus'},
  {path: 'icons/messages/message-circle-3-plus.svg', name: 'message-circle-3-plus'},
  {path: 'icons/messages/message-square-plus.svg', name: 'message-square-plus'},
  {path: 'icons/messages/message-circle-minus.svg', name: 'message-circle-minus'},
  {path: 'icons/messages/message-circle-3-minus.svg', name: 'message-circle-3-minus'},
  {path: 'icons/messages/message-square-minus.svg', name: 'message-square-minus'},
  {path: 'icons/messages/message-circle-close.svg', name: 'message-circle-close'},
  {path: 'icons/messages/message-circle-3-close.svg', name: 'message-circle-3-close'},
  {path: 'icons/messages/message-square-close.svg', name: 'message-square-close'},
  {path: 'icons/messages/message-circle-check.svg', name: 'message-circle-check'},
  {path: 'icons/messages/message-circle-3-check.svg', name: 'message-circle-3-check'},
  {path: 'icons/messages/message-square-check.svg', name: 'message-square-check'},
  {path: 'icons/messages/message-circle-text.svg', name: 'message-circle-text'},
  {path: 'icons/messages/message-circle-3-text.svg', name: 'message-circle-3-text'},
  {path: 'icons/messages/message-square-text.svg', name: 'message-square-text'},
  {path: 'icons/messages/message-circle-cloud.svg', name: 'message-circle-cloud'},
  {path: 'icons/messages/message-circle-3-cloud.svg', name: 'message-circle-3-cloud'},
  {path: 'icons/messages/message-square-cloud.svg', name: 'message-square-cloud'},
  {path: 'icons/messages/message-circle-down.svg', name: 'message-circle-down'},
  {path: 'icons/messages/message-circle-3-down.svg', name: 'message-circle-3-down'},
  {path: 'icons/messages/message-square-down.svg', name: 'message-square-down'},
  {path: 'icons/messages/message-circle-up.svg', name: 'message-circle-up'},
  {path: 'icons/messages/message-circle-3-up.svg', name: 'message-circle-3-up'},
  {path: 'icons/messages/message-square-up.svg', name: 'message-square-up'},
  {path: 'icons/messages/message-circle-cancel.svg', name: 'message-circle-cancel'},
  {path: 'icons/messages/message-circle-3-close.svg', name: 'message-circle-3-close'},
  {path: 'icons/messages/message-square-block.svg', name: 'message-square-block'},
  {path: 'icons/messages/message-circle-information.svg', name: 'message-circle-information'},
  {path: 'icons/messages/message-circle-3-time.svg', name: 'message-circle-3-time'},
  {path: 'icons/messages/message-square-alt.svg', name: 'message-square-alt'},
  {path: 'icons/messages/message-circle-2.svg', name: 'message-circle-2'},
  {path: 'icons/messages/message-circle-3-text-center.svg', name: 'message-circle-3-text-center'},
  {path: 'icons/messages/message-square-alt-plus.svg', name: 'message-square-alt-plus'},
  {path: 'icons/messages/message-circle-2-plus.svg', name: 'message-circle-2-plus'},
  {path: 'icons/messages/message-circle-3-call.svg', name: 'message-circle-3-call'},
  {path: 'icons/messages/message-square-alt-minus.svg', name: 'message-square-alt-minus'},
  {path: 'icons/messages/message-circle-2-minus.svg', name: 'message-circle-2-minus'},
  {path: 'icons/messages/message-circle-3-double.svg', name: 'message-circle-3-double'},
  {path: 'icons/messages/message-square-alt-close.svg', name: 'message-square-alt-close'},
  {path: 'icons/messages/message-circle-2-close.svg', name: 'message-circle-2-close'},
  {path: 'icons/messages/message-square-alt-check.svg', name: 'message-square-alt-check'},
  {path: 'icons/messages/message-circle-2-check.svg', name: 'message-circle-2-check'},
  {path: 'icons/messages/message-circle-and-square.svg', name: 'message-circle-and-square'},
  {path: 'icons/messages/message-square-alt-text.svg', name: 'message-square-alt-text'},
  {path: 'icons/messages/message-circle-2-text.svg', name: 'message-circle-2-text'},
  {path: 'icons/messages/message-double.svg', name: 'message-double'},
  {path: 'icons/messages/message-square-alt-cloud.svg', name: 'message-square-alt-cloud'},
  {path: 'icons/messages/message-circle-2-cloud.svg', name: 'message-circle-2-cloud'},
  {path: 'icons/messages/drop-box.svg', name: 'drop-box'},
  {path: 'icons/messages/message-square-alt-down.svg', name: 'message-square-alt-down'},
  {path: 'icons/messages/message-circle-2-down.svg', name: 'message-circle-2-down'},
  {path: 'icons/messages/card.svg', name: 'card'},
  {path: 'icons/messages/message-square-alt-up.svg', name: 'message-square-alt-up'},
  {path: 'icons/messages/message-circle-2-up.svg', name: 'message-circle-2-up'},
  {path: 'icons/messages/card-double.svg', name: 'card-double'},
  {path: 'icons/messages/message-square-alt-blck.svg', name: 'message-square-alt-blck'},
  {path: 'icons/messages/message-circle-2-cancel.svg', name: 'message-circle-2-cancel'},
  {path: 'icons/messages/letter.svg', name: 'letter'},
  {path: 'icons/messages/message-square-alt-text-center.svg', name: 'message-square-alt-text-center'},
  {path: 'icons/messages/mail.svg', name: 'mail'},
  {path: 'icons/messages/message-writing.svg', name: 'message-writing'},
  {path: 'icons/messages/message-square-alt-call-1.svg', name: 'message-square-alt-call-1'},
  {path: 'icons/messages/mail-opened.svg', name: 'mail-opened'},
  {path: 'icons/messages/edit-message.svg', name: 'edit-message'},
  {path: 'icons/messages/message-square-alt-call-2.svg', name: 'message-square-alt-call-2'},
  {path: 'icons/messages/mail-circle.svg', name: 'mail-circle'},
  {path: 'icons/messages/mail-box.svg', name: 'mail-box'},
  {path: 'icons/messages/message-square-alt-dots.svg', name: 'message-square-alt-dots'},
  {path: 'icons/messages/download.svg', name: 'download'},
  {path: 'icons/messages/open-letter-2.svg', name: 'open-letter-2'},
  {path: 'icons/messages/message-square-double-1.svg', name: 'message-square-double-1'},
  {path: 'icons/messages/Upload.svg', name: 'Upload'},
  {path: 'icons/messages/open-letter-2-plus.svg', name: 'open-letter-2-plus'},
  {path: 'icons/messages/inbox.svg', name: 'inbox'},
  {path: 'icons/messages/send-backward.svg', name: 'send-backward'},
  {path: 'icons/messages/open-letter-2-minus.svg', name: 'open-letter-2-minus'},
  {path: 'icons/messages/message-square-double-2.svg', name: 'message-square-double-2'},
  {path: 'icons/messages/send-foreward.svg', name: 'send-foreward'},
  {path: 'icons/messages/open-letter-2-close.svg', name: 'open-letter-2-close'},
  {path: 'icons/messages/message-square-double-3.svg', name: 'message-square-double-3'},
  {path: 'icons/messages/open-letter-1.svg', name: 'open-letter-1'},
  {path: 'icons/messages/open-letter-2-check.svg', name: 'open-letter-2-check'},
  {path: 'icons/messages/edit-message-alt.svg', name: 'edit-message-alt'},
  {path: 'icons/messages/open-letter-1-plus.svg', name: 'open-letter-1-plus'},
  {path: 'icons/messages/open-letter-3-text.svg', name: 'open-letter-3-text'},
  {path: 'icons/messages/delivery-box.svg', name: 'delivery-box'},
  {path: 'icons/messages/open-letter-1-minus.svg', name: 'open-letter-1-minus'},
  {path: 'icons/messages/open-letter-3-heart.svg', name: 'open-letter-3-heart'},
  {path: 'icons/messages/share-double.svg', name: 'share-double'},
  {path: 'icons/messages/open-letter-1-close.svg', name: 'open-letter-1-close'},
  {path: 'icons/messages/open-letter-2-text.svg', name: 'open-letter-2-text'},
  {path: 'icons/messages/share.svg', name: 'share'},
  {path: 'icons/messages/open-letter-1-check.svg', name: 'open-letter-1-check'},
  {path: 'icons/messages/open-letter-2-heart.svg', name: 'open-letter-2-heart'}
];
