import { Icon } from '../icon';

export const iconsBusiness: Icon[] = [
  {path: 'icons/business/credit-card.svg', name: 'credit-card'},
  {path: 'icons/business/trend-down.svg', name: 'trend-down'},
  {path: 'icons/business/chart-box.svg', name: 'chart-box'},
  {path: 'icons/business/wallet.svg', name: 'wallet'},
  {path: 'icons/business/trend-up.svg', name: 'trend-up'},
  {path: 'icons/business/chart-circle.svg', name: 'chart-circle'},
  {path: 'icons/business/dollar.svg', name: 'dollar'},
  {path: 'icons/business/calculator-1.svg', name: 'calculator-1'},
  {path: 'icons/business/calculator-3.svg', name: 'calculator-3'},
  {path: 'icons/business/percent-circle.svg', name: 'percent-circle'},
  {path: 'icons/business/calculator-2.svg', name: 'calculator-2'},
  {path: 'icons/business/calculator-4.svg', name: 'calculator-4'},
  {path: 'icons/business/chart-line.svg', name: 'chart-line'},
  {path: 'icons/business/safe.svg', name: 'safe'},
  {path: 'icons/business/bank.svg', name: 'bank'},
  {path: 'icons/business/chart-trend.svg', name: 'chart-trend'},
  {path: 'icons/business/coins.svg', name: 'coins'},
  {path: 'icons/business/wallet-alt.svg', name: 'wallet-alt'},
  {path: 'icons/business/chart-bars.svg', name: 'chart-bars'},
  {path: 'icons/business/bars-alt-random.svg', name: 'bars-alt-random'},
  {path: 'icons/business/file-stamp.svg', name: 'file-stamp'},
  {path: 'icons/business/bars-box.svg', name: 'bars-box'},
  {path: 'icons/business/box-chart-upward.svg', name: 'box-chart-upward'},
  {path: 'icons/business/computer-chart.svg', name: 'computer-chart'},
  {path: 'icons/business/bars.svg', name: 'bars'},
  {path: 'icons/business/pie-chart-1.svg', name: 'pie-chart-1'},
  {path: 'icons/business/presentation-chart.svg', name: 'presentation-chart'},
  {path: 'icons/business/bars-upward.svg', name: 'bars-upward'},
  {path: 'icons/business/pie-chart-2.svg', name: 'pie-chart-2'},
  {path: 'icons/business/file-chart.svg', name: 'file-chart'},
  {path: 'icons/business/bars-alt.svg', name: 'bars-alt'},
  {path: 'icons/business/pie-chart-box.svg', name: 'pie-chart-box'},
  {path: 'icons/business/calendar-stamp.svg', name: 'calendar-stamp'},
  {path: 'icons/business/bars-alt-upward.svg', name: 'bars-alt-upward'},
  {path: 'icons/business/box-chart-downward.svg', name: 'box-chart-downward'},
  {path: 'icons/business/calendar-chart.svg', name: 'calendar-chart'},
  {path: 'icons/business/bars-alt-downward.svg', name: 'bars-alt-downward'},
  {path: 'icons/business/bars-user.svg', name: 'bars-user'},
  {path: 'icons/business/briefcase.svg', name: 'briefcase'},
  {path: 'icons/business/mind-map-1.svg', name: 'mind-map-1'},
  {path: 'icons/business/bars-alt-vertical-downward.svg', name: 'bars-alt-vertical-downward'},
  {path: 'icons/business/bullseye.svg', name: 'bullseye'},
  {path: 'icons/business/mind-map-2.svg', name: 'mind-map-2'},
  {path: 'icons/business/bars-alt-vertical-upward.svg', name: 'bars-alt-vertical-upward'},
  {path: 'icons/business/pie-chart-4.svg', name: 'pie-chart-4'},
  {path: 'icons/business/mind-map-3.svg', name: 'mind-map-3'},
  {path: 'icons/business/mind-map-4.svg', name: 'mind-map-4'},
  {path: 'icons/business/bounding-box.svg', name: 'bounding-box'},
  {path: 'icons/business/trophy.svg', name: 'trophy'},
  {path: 'icons/business/mind-map-5.svg', name: 'mind-map-5'},
  {path: 'icons/business/flag-1.svg', name: 'flag-1'},
  {path: 'icons/business/ribbon.svg', name: 'ribbon'},
  {path: 'icons/business/pie-chart-3.svg', name: 'pie-chart-3'},
  {path: 'icons/business/flag-2.svg', name: 'flag-2'},
  {path: 'icons/business/ribbon-dot.svg', name: 'ribbon-dot'},
  {path: 'icons/business/present.svg', name: 'present'},
  {path: 'icons/business/flag-3.svg', name: 'flag-3'},
  {path: 'icons/business/ribbon-user.svg', name: 'ribbon-user'},
  {path: 'icons/business/present-alt.svg', name: 'present-alt'},
  {path: 'icons/business/flag-double.svg', name: 'flag-double'},
  {path: 'icons/business/ribbon-alt.svg', name: 'ribbon-alt'},
  {path: 'icons/business/award-card.svg', name: 'award-card'},
  {path: 'icons/business/baloon.svg', name: 'baloon'},
  {path: 'icons/business/ribbon-alt-dot.svg', name: 'ribbon-alt-dot'},
  {path: 'icons/business/award-card-alt.svg', name: 'award-card-alt'},
  {path: 'icons/business/baloon-double.svg', name: 'baloon-double'}
];
