import { Action } from '@ngrx/store';
import { ExamForm, ExamFormSummary } from '../models/exam-form.model';

export enum ExamFormTypes {
  LoadOne = '[EXAM-FORM] LOAD ONE',
  LoadOneSuccess = '[EXAM-FORM] LOAD ONE SUCCESS',
  LoadOneError = '[EXAM-FORM] LOAD ONE ERROR',

  LoadAll = '[EXAM-FORM] LOAD ALL',
  LoadAllSuccess = '[EXAM-FORM] LOAD ALL SUCCESS',
  LoadAllError = '[EXAM-FORM] LOAD ALL ERROR',

  Reset = '[EXAM-FORM] RESET',

  Save = '[EXAM-FORM] SAVE',
  SaveSuccess = '[EXAM-FORM] SAVE SUCCESS',
  SaveError = '[EXAM-FORM] SAVE ERROR',

  Delete = '[EXAM-FORM] DELETE',
  DeleteSuccess = '[EXAM-FORM] DELETE SUCCESS',
  DeleteError = '[EXAM-FORM] DELETE ERROR',
}

/**
 * Load One
 */
export class LoadOneExamForm implements Action {
  readonly type = ExamFormTypes.LoadOne;
  constructor(public payload: number) {}
}

export class LoadOneExamFormSuccess implements Action {
  readonly type = ExamFormTypes.LoadOneSuccess;
  constructor(public payload: ExamForm) {}
}

export class LoadOneExamFormError implements Action {
  readonly type = ExamFormTypes.LoadOneError;
  constructor(public payload: any) {}
}

/**
 * Load All
 */
export class LoadAllExamForm implements Action {
  readonly type = ExamFormTypes.LoadAll;
}

export class LoadAllExamFormSuccess implements Action {
  readonly type = ExamFormTypes.LoadAllSuccess;
  constructor(public payload: ExamFormSummary[]) {}
}

export class LoadAllExamFormError implements Action {
  readonly type = ExamFormTypes.LoadAllError;
  constructor(public payload: any) {}
}

/**
 * Reset
 */
export class ResetExamForm implements Action {
  readonly type = ExamFormTypes.Reset;
}

/**
 * Save
 */
export class ExamFormSave implements Action {
  readonly type = ExamFormTypes.Save;
  constructor(public payload: ExamForm) {}
}

export class ExamFormSaveSuccess implements Action {
  readonly type = ExamFormTypes.SaveSuccess;
  constructor(public payload: ExamForm) {}
}

export class ExamFormSaveError implements Action {
  readonly type = ExamFormTypes.SaveError;
  constructor(public payload: any) {}
}

/**
 * Delete
 */
export class ExamFormDelete implements Action {
  readonly type = ExamFormTypes.Delete;
  constructor(public payload: number) {}
}

export class ExamFormDeleteSuccess implements Action {
  readonly type = ExamFormTypes.DeleteSuccess;
}

export class ExamFormDeleteError implements Action {
  readonly type = ExamFormTypes.DeleteError;
  constructor(public payload: any) {}
}

export type ExamFormAction = LoadOneExamForm
  | LoadOneExamFormSuccess
  | LoadOneExamFormError
  | LoadAllExamForm
  | LoadAllExamFormSuccess
  | LoadAllExamFormError
  | ResetExamForm
  | ExamFormSave
  | ExamFormSaveSuccess
  | ExamFormSaveError
  | ExamFormDelete
  | ExamFormDeleteSuccess
  | ExamFormDeleteError;
