import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { WaterMonitorsService } from '@btr/modules/water-monitors/services/water-monitors.service';
import { WaterMonitorsSave, WaterMonitorsSaveError, WaterMonitorsSaveSuccess, WaterMonitorsTypes, LoadAllWaterMonitors, LoadAllWaterMonitorsError, LoadAllWaterMonitorsSuccess, LoadAvailableStalls, LoadAvailableStallsError, LoadAvailableStallsSuccess, WaterMonitorsDelete, WaterMonitorsDeleteSuccess, WaterMonitorsDeleteError, LoadAvailableStallsForMonitor, LoadAvailableStallsForMonitorSuccess, LoadAvailableStallsForMonitorError } from '@btr/modules/water-monitors/stores/water-monitors.action';
import { WaterMonitor } from '@btr/modules/water-monitors/models/water-monitors.model';
import { Stall } from '@btr/modules/water-monitors/models/stall.model';

@Injectable()
export class WaterMonitorsEffect {
  constructor(private toastrService: ToastrService, private waterMonitorsService: WaterMonitorsService) {
  }

  loadAll$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<LoadAllWaterMonitors>(WaterMonitorsTypes.LoadAll),
      switchMap((action) => this.waterMonitorsService.loadAll().pipe(
          map((waterMonitors: WaterMonitor[]) => new LoadAllWaterMonitorsSuccess(waterMonitors)),
          catchError(error => of(new LoadAllWaterMonitorsError(error)))
        )
      )
    )
  );

  loadAvailableForMonitor$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<LoadAvailableStallsForMonitor>(WaterMonitorsTypes.LoadAvailableStallsForMonitor),
      switchMap((action) => this.waterMonitorsService.loadAvailableStallsForMonitor().pipe(
          map((stalls: Stall[]) => new LoadAvailableStallsForMonitorSuccess(stalls)),
          catchError(error => of(new LoadAvailableStallsForMonitorError(error)))
        )
      )
    )
  );

  loadAvailable$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<LoadAvailableStalls>(WaterMonitorsTypes.LoadAvailableStalls),
      switchMap((action) => this.waterMonitorsService.loadAvailableStalls().pipe(
          map((stalls: Stall[]) => new LoadAvailableStallsSuccess(stalls)),
          catchError(error => of(new LoadAvailableStallsError(error)))
        )
      )
    )
  );

  save$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<WaterMonitorsSave>(WaterMonitorsTypes.Save),
      switchMap((action) => this.waterMonitorsService.save(action.payload).pipe(
          map((waterMonitor: WaterMonitor) => new WaterMonitorsSaveSuccess(waterMonitor)),
          catchError(error => of(new WaterMonitorsSaveError(error)))
        )
      )
    )
  );

  saveSuccess$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<WaterMonitorsSaveSuccess>(WaterMonitorsTypes.SaveSuccess),
      tap(() => this.toastrService.success("Changes saved"))
    ), {dispatch: false}
  );

  delete$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<WaterMonitorsDelete>(WaterMonitorsTypes.Delete),
      switchMap((action) => this.waterMonitorsService.delete(action.payload).pipe(
          map(() => new WaterMonitorsDeleteSuccess()),
          catchError(error => of(new WaterMonitorsDeleteError(error)))
        )
      )
    )
  );

  error$ = createEffect(() =>
    inject(Actions).pipe(
      ofType(WaterMonitorsTypes.LoadAllError, WaterMonitorsTypes.LoadAvailableStallsError, WaterMonitorsTypes.SaveError, WaterMonitorsTypes.DeleteError),
      tap(_ => this.toastrService.error("An error has occured, try again"))
    ), {dispatch: false}
  );
}
