import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '../icon/icon.module';
import { TooltipService } from '@kit/modules/tooltip/tooltip.service';
import { TooltipDirective } from '@kit/modules/tooltip/tooltip.directive';
import { TooltipDirectiveComponent } from '@kit/modules/tooltip/tooltip-directive.component';

@NgModule({
  providers: [TooltipService],
  declarations: [TooltipDirective, TooltipDirectiveComponent],
  exports: [TooltipDirective, TooltipDirectiveComponent],
  imports: [CommonModule, IconModule]
})
export class TooltipModule {}
