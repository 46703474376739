import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@kit/modules/icon/icon.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RippleModule } from '@kit/modules/ripple/ripple.module';
import { TabsModule } from '@kit/modules/tabs/tabs.module';
import { DialogModule } from '@kit/modules/dialog/dialog.module';
import { ComboboxModule } from '@kit/modules/combobox/combobox.module';
import { DatePickerModule } from '@kit/modules/date-picker/date-picker.module';
import { UploadModule } from '@kit/modules/upload/upload.module';
import { TooltipModule } from '@kit/modules/tooltip/tooltip.module';
import { DropdownModule } from '@kit/modules/dropdown/dropdown.module';
import { MatIconModule } from '@angular/material/icon';

const modules = [
  ComboboxModule,
  DatePickerModule,
  DialogModule,
  DropdownModule,
  IconModule,
  RippleModule,
  TabsModule,
  UploadModule,
  TooltipModule,
  MatIconModule
];

@NgModule({
  declarations: [],
  exports: [...modules],
  imports: [CommonModule, ...modules, AngularSvgIconModule.forRoot()],
})
export class KitModule {}
