import { Icon } from '../icon';

export const iconsSounds: Icon[] = [
  {path: 'icons/sounds/music-note-1.svg', name: 'music-note-1'},
  {path: 'icons/sounds/headphones.svg', name: 'headphones'},
  {path: 'icons/sounds/music-wave.svg', name: 'music-wave'},
  {path: 'icons/sounds/repeat.svg', name: 'repeat'},
  {path: 'icons/sounds/headphones-alt.svg', name: 'headphones-alt'},
  {path: 'icons/sounds/music-stand.svg', name: 'music-stand'},
  {path: 'icons/sounds/speaker.svg', name: 'speaker'},
  {path: 'icons/sounds/radio.svg', name: 'radio'},
  {path: 'icons/sounds/vinyl-player.svg', name: 'vinyl-player'},
  {path: 'icons/sounds/music-note-2.svg', name: 'music-note-2'},
  {path: 'icons/sounds/earphones.svg', name: 'earphones'},
  {path: 'icons/sounds/microphone.svg', name: 'microphone'},
  {path: 'icons/sounds/music-note-3.svg', name: 'music-note-3'},
  {path: 'icons/sounds/sound-waves.svg', name: 'sound-waves'},
  {path: 'icons/sounds/disk.svg', name: 'disk'},
  {path: 'icons/sounds/music-note-4.svg', name: 'music-note-4'},
  {path: 'icons/sounds/radio.svg', name: 'radio'},
  {path: 'icons/sounds/drum.svg', name: 'drum'},
  {path: 'icons/sounds/music-note-5.svg', name: 'music-note-5'},
  {path: 'icons/sounds/radio-alt.svg', name: 'radio-alt'},
  {path: 'icons/sounds/musical-cassette.svg', name: 'musical-cassette'},
  {path: 'icons/sounds/music-note-6.svg', name: 'music-note-6'},
  {path: 'icons/sounds/speaker.svg', name: 'speaker'},
  {path: 'icons/sounds/arf.svg', name: 'arf'},
  {path: 'icons/sounds/music-note-7.svg', name: 'music-note-7'},
  {path: 'icons/sounds/music-disc.svg', name: 'music-disc'},
  {path: 'icons/sounds/music-file.svg', name: 'music-file'},
  {path: 'icons/sounds/music-note-8.svg', name: 'music-note-8'},
  {path: 'icons/sounds/boombox.svg', name: 'boombox'},
  {path: 'icons/sounds/playlist.svg', name: 'playlist'},
  {path: 'icons/sounds/music-note-9.svg', name: 'music-note-9'},
  {path: 'icons/sounds/metronome.svg', name: 'metronome'},
  {path: 'icons/sounds/microphone.svg', name: 'microphone'},
  {path: 'icons/sounds/music-note-10.svg', name: 'music-note-10'},
  {path: 'icons/sounds/sound-minus-alt.svg', name: 'sound-minus-alt'},
  {path: 'icons/sounds/full-volume.svg', name: 'full-volume'},
  {path: 'icons/sounds/sound.svg', name: 'sound'},
  {path: 'icons/sounds/sound-close-alt.svg', name: 'sound-close-alt'},
  {path: 'icons/sounds/low-volume-alt.svg', name: 'low-volume-alt'},
  {path: 'icons/sounds/sound-off.svg', name: 'sound-off'},
  {path: 'icons/sounds/mute.svg', name: 'mute'},
  {path: 'icons/sounds/medium-volume-alt.svg', name: 'medium-volume-alt'},
  {path: 'icons/sounds/sound-plus.svg', name: 'sound-plus'},
  {path: 'icons/sounds/mute-alt.svg', name: 'mute-alt'},
  {path: 'icons/sounds/full-volume-alt.svg', name: 'full-volume-alt'},
  {path: 'icons/sounds/sound-minus.svg', name: 'sound-minus'},
  {path: 'icons/sounds/low-volume.svg', name: 'low-volume'},
  {path: 'icons/sounds/sound-alt.svg', name: 'sound-alt'},
  {path: 'icons/sounds/sound-close.svg', name: 'sound-close'},
  {path: 'icons/sounds/medium-volume.svg', name: 'medium-volume'},
  {path: 'icons/sounds/sound-off-alt.svg', name: 'sound-off-alt'},
  {path: 'icons/sounds/sound-plus-alt.svg', name: 'sound-plus-alt'},
  {path: 'icons/sounds/fast-backward-alt-circle.svg', name: 'fast-backward-alt-circle'},
  {path: 'icons/sounds/minus-contour.svg', name: 'minus-contour'},
  {path: 'icons/sounds/fast-forward.svg', name: 'fast-forward'},
  {path: 'icons/sounds/foreward.svg', name: 'foreward'},
  {path: 'icons/sounds/minus-circle.svg', name: 'minus-circle'},
  {path: 'icons/sounds/fast-forward-circle.svg', name: 'fast-forward-circle'},
  {path: 'icons/sounds/play-circle.svg', name: 'play-circle'},
  {path: 'icons/sounds/plus-contour.svg', name: 'plus-contour'},
  {path: 'icons/sounds/fast-backward.svg', name: 'fast-backward'},
  {path: 'icons/sounds/backward.svg', name: 'backward'},
  {path: 'icons/sounds/plus-circle.svg', name: 'plus-circle'},
  {path: 'icons/sounds/fast-backward-circle.svg', name: 'fast-backward-circle'},
  {path: 'icons/sounds/record-circle.svg', name: 'record-circle'},
  {path: 'icons/sounds/close.svg', name: 'close'},
  {path: 'icons/sounds/fast-foreward-alt.svg', name: 'fast-foreward-alt'},
  {path: 'icons/sounds/pause.svg', name: 'pause'},
  {path: 'icons/sounds/close-circle.svg', name: 'close-circle'},
  {path: 'icons/sounds/fast-foreward-alt-circle.svg', name: 'fast-foreward-alt-circle'},
  {path: 'icons/sounds/pause-circle.svg', name: 'pause-circle'},
  {path: 'icons/sounds/play.svg', name: 'play'},
  {path: 'icons/sounds/fast-backward-alt.svg', name: 'fast-backward-alt'}
];
