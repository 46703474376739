import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from '@env/environment';
import {
  Consumption,
  ConsumptionMode,
  Consumptions,
  FilterMode
} from '@btr/modules/consumption/models/consumption.model';

@Injectable({providedIn: 'root'})
export class ConsumptionService {
  constructor(private http: HttpClient) {}

  loadAllByHorse(consumptionMode: ConsumptionMode): Observable<Consumptions> {
    return this.http.get<Consumptions>(`${environment.api()}/horse/${consumptionMode.horseId}/consumptions?mode=${consumptionMode.mode ?? FilterMode.TODAY}`);
  }

  loadOne(consumptionId: number): Observable<Consumption> {
    return this.http.get<Consumption>(`${environment.api()}/consumption/${consumptionId}`);
  }

  save(consumption: Consumption): Observable<Consumption> {
    return this.http.patch<Consumption>(`${environment.api()}/consumption/${consumption.id}`, consumption);
  }
}
