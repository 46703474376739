import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AppState } from '@core/store/app.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectAllBarn } from '@btr/modules/barn/stores/barn.selector';
import { LoadAllBarn } from '@btr/modules/barn/stores/barn.action';
import { Barn } from '@btr/modules/barn/models/barn.model';
import { selectMyBarnId } from '@btr/modules/login/stores/tenant.selector';
import { StorageService } from '@btr/modules/layout/services/storage.service';
import { SetBarnId } from '@btr/modules/login/stores/tenant.action';
import { Go } from '@core/store/router/router.action';
import { LoadDashboard, LoadWeather } from '@btr/modules/dashboard/stores/dashboard.action';
import { Weather } from '@btr/modules/dashboard/models/dashboard.model';
import { selectWeather } from '@btr/modules/dashboard/stores/dashboard.selector';

@Component({
  selector: 'btr-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopbarComponent implements OnInit {
  barns$: Observable<Barn[]> = this.store.select(selectAllBarn);
  myBarnId$: Observable<number> = this.store.select(selectMyBarnId);
  weather$: Observable<Weather> = this.store.select(selectWeather);

  constructor(private store: Store<AppState>, private storageService: StorageService) {}

  ngOnInit() {
    this.store.dispatch(new LoadAllBarn());
    this.startWeather();
  }

  myBarn(barns: Barn[], my: number): Barn {
    return barns.find(b => b.id === my);
  }

  onBarn(id: number) {
    this.storageService.postBarnId(id);
    this.store.dispatch(new SetBarnId(id));
    this.store.dispatch(new LoadDashboard());
    this.store.dispatch(new Go({path: ["/dashboard"]}));
  }

  onAddBarn() {
    // V2
  }

  private startWeather(){
    this.store.dispatch(new LoadWeather());

    setInterval(() => {
      this.store.dispatch(new LoadWeather());
    }, 180000); // 3s
  }
}
