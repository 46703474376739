import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { first, mergeMap } from "rxjs/operators";
import { environment } from '@env/environment';
import { selectTenant } from '@btr/modules/login/stores/tenant.selector';
import { Tenant } from '@btr/modules/login/models/tenant.model';
import { AppState } from '@core/store/app.reducer';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let selectorToken: Observable<Tenant> = this.store.select(selectTenant);

    return selectorToken.pipe(first(), mergeMap((tenant: Tenant) => {
        let authReq: HttpRequest<any> = this.setTokenHeader(req, tenant);
        return next.handle(authReq);
      })
    );
  }

  private setTokenHeader(req: HttpRequest<any>, tenant: Tenant): HttpRequest<any> {
    let basicHeaders = [];
    let complexeHeaders = [];

    if (!req.url.includes(environment.api())) {
      return req; // Headers are only for our backend calls
    }

    if (tenant && (typeof tenant === 'object')) {
      if (!!tenant.barnId) {
        complexeHeaders.push({key: 'barn', value: tenant.barnId});
      }
    }

    if (tenant.accessToken && req.url !== (environment.home() + 'login')) {
      basicHeaders.push({key: 'Authorization', value: 'Bearer ' + tenant.accessToken});
    }
    let authReq: HttpRequest<any>;
    if (basicHeaders.length > 0) {
      authReq = req.clone({headers: this.getRequestHeaders(req, basicHeaders, complexeHeaders)});
    } else {
      authReq = req;
    }
    return authReq;
  }

  private getRequestHeaders(req: HttpRequest<any>, basicHeaders: any[], complexeHeaders: any[]): HttpHeaders {
    let headers: any = {};
    basicHeaders.forEach(bh => headers[bh.key] = bh.value);
    req.headers.keys().forEach((key: string) => headers[key] = req.headers.get(key));

    if (complexeHeaders.length > 0) {
      headers[complexeHeaders[0].key] = complexeHeaders[0].value.toString();
    }

    return new HttpHeaders(headers);
  }
}
