import { Action } from '@ngrx/store';
import { Dashboard, Weather } from '@btr/modules/dashboard/models/dashboard.model';

export enum DashboardTypes {
  Load = '[DASHBOARD] LOAD',
  LoadSuccess = '[DASHBOARD] LOAD SUCCESS',
  LoadError = '[DASHBOARD] LOAD ERROR',

  LoadWeather = '[DASHBOARD] LOAD WEATHER',
  LoadWeatherSuccess = '[DASHBOARD] LOAD WEATHER SUCCESS',
  LoadWeatherError = '[DASHBOARD] LOAD WEATHER ERROR',
}

/**
 * Load all dashboard info
 */
export class LoadDashboard implements Action {
  readonly type = DashboardTypes.Load;
}
export class LoadDashboardSuccess implements Action {
  readonly type = DashboardTypes.LoadSuccess;
  constructor(public payload: Dashboard) {}
}
export class LoadDashboardError implements Action {
  readonly type = DashboardTypes.LoadError;
  constructor(public payload: any) {}
}

/**
 * Load weather
 */
export class LoadWeather implements Action {
  readonly type = DashboardTypes.LoadWeather;
}
export class LoadWeatherSuccess implements Action {
  readonly type = DashboardTypes.LoadWeatherSuccess;
  constructor(public payload: Weather) {}
}
export class LoadWeatherError implements Action {
  readonly type = DashboardTypes.LoadWeatherError;
  constructor(public payload: any) {}
}

export type DashboardAction = LoadDashboard | LoadDashboardSuccess | LoadDashboardError | LoadWeather | LoadWeatherSuccess | LoadWeatherError;
