import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TooltipService } from './tooltip.service';
import { IconColor } from '@kit/modules/icon/icon.component';

/**
 * Tooltip
 * @version 2.3 - 15/01/24
 */
@Component({
  selector: 'uikit-tooltip-directive',
  templateUrl: './tooltip-directive.component.html',
})
export class TooltipDirectiveComponent implements AfterViewInit {
  @Input() tooltipContent: TemplateRef<any> | null;
  @Input() color: 'default' = 'default';
  @Input() position: 'top' | 'bottom' | 'left' | 'right' = 'top';
  @ViewChild('tooltipChild') tooltipRef: ElementRef;
  @HostBinding('class') class: string = 'tailwind-app absolute m-0';

  tooltip: string = ''; // Bind vers [tooltipText]
  left: number = 0;
  xOffset: number = 0;
  elWidth: number = 0;
  top: number = 0;

  TOOLTIP_MARGIN = 10;

  constructor(private tooltipService: TooltipService, private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.positionTooltip();
    this.changeDetectorRef.detectChanges();
  }

  positionTooltip() {
    const tooltipRect = this.tooltipRef.nativeElement.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    let overflow: string | null = null;
    let amount: number | null = null;

    if (tooltipRect.top < 0 && tooltipRect.bottom) {
      amount = tooltipRect.top;
      overflow = 'top';
    }

    if (tooltipRect.bottom + tooltipRect.height > windowHeight) {
      amount = tooltipRect.bottom + tooltipRect.height - windowHeight;
      overflow = 'bottom';
    }

    if (tooltipRect.left < 0) {
      amount = tooltipRect.left + Math.sign(tooltipRect.left);
      overflow = 'left';
    }

    if (tooltipRect.x + tooltipRect.width > windowWidth) {
      amount = tooltipRect.x + tooltipRect.width - windowWidth;
      overflow = 'right';
    }

    if (overflow) {
      const sign = Math.sign(amount);
      amount = amount + (sign * this.TOOLTIP_MARGIN);
      this.tooltipService.notify({ overflow, amount });
    }
  }

  get getColorArrow(): string {
    switch (this.color) {
      case 'default':
      default:
        return IconColor['page-primary'];
    }
  }
}
