import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from '@env/environment';
import { ExamForm, ExamFormSummary } from '@btr/modules/exam-form/models/exam-form.model';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ExamFormService {
  constructor(private http: HttpClient) {}

  loadAll(): Observable<ExamFormSummary[]> {
    // TODO : à reprendre pour récupérer un objet différent
    return this.http.get<ExamFormSummary[]>(`${environment.api()}/horse-exam-form`);
  }

  load(examFormId: number): Observable<ExamForm> {
    return this.http.get<ExamForm>(`${environment.api()}/horse-exam-form/${examFormId}`);
  }

  save(examForm: ExamForm): Observable<ExamForm> {
    if (examForm.id) {
      return this.http.patch<ExamForm>(`${environment.api()}/horse-exam-form/${examForm.id}`, examForm);
    }
    return this.http.post<ExamForm>(`${environment.api()}/horse-exam-form`, examForm);
  }

  delete(examFormId: number): Observable<void> {
    return this.http.delete<void>(`${environment.api()}/horse-exam-form/${examFormId}`);
  }
}
