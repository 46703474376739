import { Action } from '@ngrx/store';
import { WaterMonitor } from '@btr/modules/water-monitors/models/water-monitors.model';
import { Stall } from '@btr/modules/water-monitors/models/stall.model';

export enum WaterMonitorsTypes {
  LoadAll = '[WM] LOAD ALL',
  LoadAllSuccess = '[WM] LOAD ALL SUCCESS',
  LoadAllError = '[WM] LOAD ALL ERROR',

  LoadAvailableStallsForMonitor = '[WM] LOAD AVAILABLE STALLS FOR MONITOR',
  LoadAvailableStallsForMonitorSuccess = '[WM] LOAD AVAILABLE STALLS FOR MONITOR SUCCESS',
  LoadAvailableStallsForMonitorError = '[WM] LOAD AVAILABLE STALLS FOR MONITOR ERROR',

  LoadAvailableStalls = '[WM] LOAD AVAILABLE STALLS',
  LoadAvailableStallsSuccess = '[WM] LOAD AVAILABLE STALLS SUCCESS',
  LoadAvailableStallsError = '[WM] LOAD AVAILABLE STALLS ERROR',

  Save = '[WM] SAVE',
  SaveSuccess = '[WM] SAVE SUCCESS',
  SaveError = '[WM] SAVE ERROR',

  Delete = '[WM] DELETE',
  DeleteSuccess = '[WM] DELETE SUCCESS',
  DeleteError = '[WM] DELETE ERROR',
}

/**
 * Load all water monitors
 */
export class LoadAllWaterMonitors implements Action {
  readonly type = WaterMonitorsTypes.LoadAll;
}
export class LoadAllWaterMonitorsSuccess implements Action {
  readonly type = WaterMonitorsTypes.LoadAllSuccess;
  constructor(public payload: WaterMonitor[]) {}
}
export class LoadAllWaterMonitorsError implements Action {
  readonly type = WaterMonitorsTypes.LoadAllError;
  constructor(public payload: any) {}
}

/**
 * Load available stalls without monitors
 */
export class LoadAvailableStallsForMonitor implements Action {
  readonly type = WaterMonitorsTypes.LoadAvailableStallsForMonitor;
}
export class LoadAvailableStallsForMonitorSuccess implements Action {
  readonly type = WaterMonitorsTypes.LoadAvailableStallsForMonitorSuccess;
  constructor(public payload: Stall[]) {}
}
export class LoadAvailableStallsForMonitorError implements Action {
  readonly type = WaterMonitorsTypes.LoadAvailableStallsForMonitorError;
  constructor(public payload: any) {}
}

/**
 * Load available stalls without horses
 */
export class LoadAvailableStalls implements Action {
  readonly type = WaterMonitorsTypes.LoadAvailableStalls;
}
export class LoadAvailableStallsSuccess implements Action {
  readonly type = WaterMonitorsTypes.LoadAvailableStallsSuccess;
  constructor(public payload: Stall[]) {}
}
export class LoadAvailableStallsError implements Action {
  readonly type = WaterMonitorsTypes.LoadAvailableStallsError;
  constructor(public payload: any) {}
}

/**
 * Save one water monitor
 */
export class WaterMonitorsSave implements Action {
  readonly type = WaterMonitorsTypes.Save;
  constructor(public payload: WaterMonitor) {}
}
export class WaterMonitorsSaveSuccess implements Action {
  readonly type = WaterMonitorsTypes.SaveSuccess;
  constructor(public payload: WaterMonitor) {}
}
export class WaterMonitorsSaveError implements Action {
  readonly type = WaterMonitorsTypes.SaveError;
  constructor(public payload: any) {}
}

/**
 * Delete one water monitor
 */
export class WaterMonitorsDelete implements Action {
  readonly type = WaterMonitorsTypes.Delete;
  constructor(public payload: WaterMonitor) {}
}
export class WaterMonitorsDeleteSuccess implements Action {
  readonly type = WaterMonitorsTypes.DeleteSuccess;
}
export class WaterMonitorsDeleteError implements Action {
  readonly type = WaterMonitorsTypes.DeleteError;
  constructor(public payload: any) {}
}

export type WaterMonitorsAction = LoadAllWaterMonitors
  | LoadAllWaterMonitorsSuccess
  | LoadAllWaterMonitorsError
  | LoadAvailableStallsForMonitor
  | LoadAvailableStallsForMonitorSuccess
  | LoadAvailableStallsForMonitorError
  | LoadAvailableStalls
  | LoadAvailableStallsSuccess
  | LoadAvailableStallsError
  | WaterMonitorsSave
  | WaterMonitorsSaveSuccess
  | WaterMonitorsSaveError
  | WaterMonitorsDelete
  | WaterMonitorsDeleteSuccess
  | WaterMonitorsDeleteError;
