import { Action } from '@ngrx/store';
import { Consumption, ConsumptionMode, Consumptions } from '@btr/modules/consumption/models/consumption.model';

export enum ConsumptionTypes {
  LoadAllByHorse = '[CONSUMPTION] LOAD ALL BY HORSE',
  LoadAllByHorseSuccess = '[CONSUMPTION] LOAD ALL BY HORSE SUCCESS',
  LoadAllByHorseError = '[CONSUMPTION] LOAD ALL BY HORSE ERROR',

  Save = '[CONSUMPTION] SAVE',
  SaveSuccess = '[CONSUMPTION] SAVE SUCCESS',
  SaveError = '[CONSUMPTION] SAVE ERROR',
}

/**
 * Load all consumptions by horse
 */
export class LoadAllConsumptionByHorse implements Action {
  readonly type = ConsumptionTypes.LoadAllByHorse;
  constructor(public payload: ConsumptionMode) {}
}
export class LoadAllConsumptionByHorseSuccess implements Action {
  readonly type = ConsumptionTypes.LoadAllByHorseSuccess;
  constructor(public payload: Consumption[]) {}
}
export class LoadAllConsumptionByHorseError implements Action {
  readonly type = ConsumptionTypes.LoadAllByHorseError;
  constructor(public payload: any) {}
}

/**
 * Save one consumption
 */
export class ConsumptionSave implements Action {
  readonly type = ConsumptionTypes.Save;
  constructor(public payload: Consumption) {}
}
export class ConsumptionSaveSuccess implements Action {
  readonly type = ConsumptionTypes.SaveSuccess;
  constructor(public payload: Consumption) {}
}
export class ConsumptionSaveError implements Action {
  readonly type = ConsumptionTypes.SaveError;
  constructor(public payload: any) {}
}

export type ConsumptionAction = LoadAllConsumptionByHorse
  | LoadAllConsumptionByHorseSuccess
  | LoadAllConsumptionByHorseError
  | ConsumptionSave
  | ConsumptionSaveSuccess
  | ConsumptionSaveError;
