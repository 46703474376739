<!-- TOOLTIP -->
<div class='fixed tailwind-app uikit-tooltip-{{position}}' [style.left]="left - xOffset + 'px'" [style.top]="top + 'px'">
  <div #tooltipChild class='uikit-tooltip-content uikit-tooltip-{{color}}'>
    <div *ngIf='tooltip' class='px-3 py-2' [innerHTML]='tooltip'></div>
    <ng-container *ngIf='tooltipContent'>
      <ng-container *ngTemplateOutlet='tooltipContent'>
      </ng-container>
    </ng-container>
  </div>
</div>
<!-- FLECHE -->
<div class='fixed tailwind-app z-9998 uikit-tooltip-{{position}}' [style.left]="left + 'px'" [style.top]="top + 'px'">
  <kit-icon name='triangle' class='uikit-tooltip-arrow uikit-tooltip-arrow-{{position}}' [fill]='getColorArrow'></kit-icon>
</div>
