import { Icon } from '../icon';

export const iconsDocuments: Icon[] = [
  {path: 'icons/documents/document-1.svg', name: 'document-1'},
  {path: 'icons/documents/file-1.svg', name: 'file-1'},
  {path: 'icons/documents/lock.svg', name: 'lock'},
  {path: 'icons/documents/document-2.svg', name: 'document-2'},
  {path: 'icons/documents/file-off.svg', name: 'file-off'},
  {path: 'icons/documents/lock-plus.svg', name: 'lock-plus'},
  {path: 'icons/documents/document-double.svg', name: 'document-double'},
  {path: 'icons/documents/file-2.svg', name: 'file-2'},
  {path: 'icons/documents/lock-minus.svg', name: 'lock-minus'},
  {path: 'icons/documents/document-3.svg', name: 'document-3'},
  {path: 'icons/documents/compress-file.svg', name: 'compress-file'},
  {path: 'icons/documents/lock-close.svg', name: 'lock-close'},
  {path: 'icons/documents/document-4.svg', name: 'document-4'},
  {path: 'icons/documents/file-3.svg', name: 'file-3'},
  {path: 'icons/documents/lock-check.svg', name: 'lock-check'},
  {path: 'icons/documents/document-5.svg', name: 'document-5'},
  {path: 'icons/documents/file-multiple.svg', name: 'file-multiple'},
  {path: 'icons/documents/opened-lock.svg', name: 'opened-lock'},
  {path: 'icons/documents/document-plus.svg', name: 'document-plus'},
  {path: 'icons/documents/file-4.svg', name: 'file-4'},
  {path: 'icons/documents/lock-plus-open.svg', name: 'lock-plus-open'},
  {path: 'icons/documents/document-minus.svg', name: 'document-minus'},
  {path: 'icons/documents/id-card.svg', name: 'id-card'},
  {path: 'icons/documents/lock-minus-open.svg', name: 'lock-minus-open'},
  {path: 'icons/documents/document-close.svg', name: 'document-close'},
  {path: 'icons/documents/server.svg', name: 'server'},
  {path: 'icons/documents/lock-close-open.svg', name: 'lock-close-open'},
  {path: 'icons/documents/document-check.svg', name: 'document-check'},
  {path: 'icons/documents/file-double.svg', name: 'file-double'},
  {path: 'icons/documents/lock-check-open.svg', name: 'lock-check-open'},
  {path: 'icons/documents/document-block.svg', name: 'document-block'},
  {path: 'icons/documents/document-7.svg', name: 'document-7'},
  {path: 'icons/documents/shield.svg', name: 'shield'},
  {path: 'icons/documents/document-heart.svg', name: 'document-heart'},
  {path: 'icons/documents/copy.svg', name: 'copy'},
  {path: 'icons/documents/shield-plus.svg', name: 'shield-plus'},
  {path: 'icons/documents/document-cloud.svg', name: 'document-cloud'},
  {path: 'icons/documents/copy-plus.svg', name: 'copy-plus'},
  {path: 'icons/documents/shield-minus.svg', name: 'shield-minus'},
  {path: 'icons/documents/document-user.svg', name: 'document-user'},
  {path: 'icons/documents/copy-minus.svg', name: 'copy-minus'},
  {path: 'icons/documents/shield-close.svg', name: 'shield-close'},
  {path: 'icons/documents/document-star.svg', name: 'document-star'},
  {path: 'icons/documents/copy-close.svg', name: 'copy-close'},
  {path: 'icons/documents/shield-check.svg', name: 'shield-check'},
  {path: 'icons/documents/document-6.svg', name: 'document-6'},
  {path: 'icons/documents/copy-check.svg', name: 'copy-check'},
  {path: 'icons/documents/shield-off.svg', name: 'shield-off'},
  {path: 'icons/documents/document-box.svg', name: 'document-box'},
  {path: 'icons/documents/book-minus.svg', name: 'book-minus'},
  {path: 'icons/documents/keyhole-box.svg', name: 'keyhole-box'},
  {path: 'icons/documents/document-box-heart.svg', name: 'document-box-heart'},
  {path: 'icons/documents/book-close.svg', name: 'book-close'},
  {path: 'icons/documents/keyhole-circle.svg', name: 'keyhole-circle'},
  {path: 'icons/documents/book.svg', name: 'book'},
  {path: 'icons/documents/book-check.svg', name: 'book-check'},
  {path: 'icons/documents/key.svg', name: 'key'},
  {path: 'icons/documents/book-plus.svg', name: 'book-plus'}
];
