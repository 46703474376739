import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from '@env/environment';
import { Account } from '@btr/modules/login/models/login.model';
import { map } from 'rxjs/operators';
import { KeycloakTenant, Tenant } from '@btr/modules/login/models/tenant.model';

@Injectable({providedIn: 'root'})
export class LoginService {
  constructor(private http: HttpClient) {}

  login(code: string): Observable<Tenant> {
    let body: URLSearchParams = new URLSearchParams();
    body.set('client_id', environment.clientId());
    body.set('client_secret', environment.clientSecret());
    body.set('grant_type', "authorization_code");
    body.set('redirect_uri', environment.home() + "/login/success");
    body.set('code', code);

    return this.postKeycloak(body);
  }

  refresh(refreshToken: string): Observable<Tenant> {
    let body: URLSearchParams = new URLSearchParams();
    body.set('client_id', environment.clientId());
    body.set('client_secret', environment.clientSecret());
    body.set('grant_type', "refresh_token");
    body.set('redirect_uri', environment.home() + "/login/success");
    body.set('refresh_token', refreshToken);

    return this.postKeycloak(body);
  }

  private postKeycloak(body: URLSearchParams): Observable<Tenant> {
    let options = {
      headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})
    };
    return this.http.post<Tenant | KeycloakTenant>(`${environment.keycloak()}/protocol/openid-connect/token`, body, options).pipe(map((kt: KeycloakTenant) => {
      return {accessToken: kt.access_token, idToken: kt.id_token, refreshToken: kt.refresh_token}; // Transform to local tenant
    }));
  }

  createAccount(account: Account): Observable<any> {
    // return this.http.post<any>(`${environment.api()}/create/account`, account);
    return of({token: null});
  }
}
