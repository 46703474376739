import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../services/login.service';
import {
  CreateAccount,
  CreateAccountError,
  CreateAccountSuccess,
  LoginBtr,
  LoginBtrError,
  LoginBtrSuccess,
  LogoutBtr,
  RefreshToken,
  RefreshTokenError,
  RefreshTokenSuccess,
  SetBarnId,
  TenantTypes
} from '@btr/modules/login/stores/tenant.action';
import { StorageService } from '@btr/modules/layout/services/storage.service';
import { Go } from '@core/store/router/router.action';
import { LoadProfile } from '@btr/modules/profile/stores/profile.action';

@Injectable()
export class TenantEffect {
  autoReload: number = 0;
  constructor(private toastrService: ToastrService, private loginService: LoginService, private storageService: StorageService) {}

  login$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<LoginBtr>(TenantTypes.LoginBtr),
      switchMap((action) => this.loginService.login(action.payload).pipe(
          map((keycloak: any) => new LoginBtrSuccess(keycloak)),
          catchError(error => of(new LoginBtrError(error)))
        )
      )
    )
  );

  loginSuccess$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<LoginBtrSuccess>(TenantTypes.LoginBtrSuccess),
      switchMap((action) => {
        this.storageService.postRefreshToken(action.payload.refreshToken); // Add tenant JWT to storage for F5
        return [new LoadProfile(), new Go({path: ["/dashboard"]})];
      }))
  );

  refresh$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<RefreshToken>(TenantTypes.RefreshToken),
      switchMap((action) => this.loginService.refresh(action.payload).pipe(
          map((keycloak: any) => new RefreshTokenSuccess(keycloak)),
          catchError(error => of(new RefreshTokenError(error)))
        )
      )
    )
  );

  refreshSuccess$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<RefreshTokenSuccess>(TenantTypes.RefreshTokenSuccess),
      switchMap((action) => {
        this.storageService.postRefreshToken(action.payload.refreshToken); // Add tenant JWT to storage for F5
        return [new LoadProfile()];
      }))
  );

  logout$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<LogoutBtr>(TenantTypes.Logout),
      tap(() => {
        this.storageService.clearStorage(); // Remove tenant & barn
      })
    ), {dispatch: false}
  );

  createAccount$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<CreateAccount>(TenantTypes.CreateAccount),
      switchMap((action) => this.loginService.createAccount(action.payload).pipe(
        map(() => new CreateAccountSuccess()),
        catchError(error => of(new CreateAccountError(error)))
      ))
    )
  );

  createAccountSuccess$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<CreateAccountSuccess>(TenantTypes.CreateAccountSuccess),
      map(() => {
        return new Go({path: ["/login"]});
      })
    )
  );

  setBarnId$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<SetBarnId>(TenantTypes.SetBarnId),
      tap((action) => {
        this.storageService.postBarnId(action.payload);
      })
    ), {dispatch: false}
  );

  loginError$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<LoginBtrError>(TenantTypes.LoginBtrError),
      tap(() => {
        this.toastrService.error("Your login and password are invalid");
      })
    ), {dispatch: false}
  );

  refreshError$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<RefreshTokenError>(TenantTypes.RefreshTokenError),
      map(() => {
        this.autoReload ++;
        this.toastrService.error("Your access is no longer valid");
        if (this.autoReload > 4) {
          return new Go({path: ["/logout"]});
        }
        return new Go({path: ["/login"]});
      })
    )
  );

  createAccountError$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<CreateAccountError>(TenantTypes.CreateAccountError),
      tap((action) => {
        this.toastrService.error(action.payload);
      })
    ), {dispatch: false}
  );
}
