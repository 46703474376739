import {Action} from '@ngrx/store';

export enum RouterActionTypes {
  Go = '[ROUTER] GO',
  Back = '[ROUTER] BACK',
}

export class Go implements Action {
  readonly type = RouterActionTypes.Go;
  constructor(public payload: { path: string[], params?: any }) {}
}

export class Back implements Action {
  readonly type = RouterActionTypes.Back;
  constructor(public payload: { path: string[], params?: any }) {}
}

export type RouterAction = Go | Back;
