import { Icon } from '../icon';

export const iconsNavigations: Icon[] = [
  {path: 'icons/navigations/directions.svg', name: 'directions'},
  {path: 'icons/navigations/pin-circle.svg', name: 'pin-circle'},
  {path: 'icons/navigations/my-location.svg', name: 'my-location'},
  {path: 'icons/navigations/directions-alt.svg', name: 'directions-alt'},
  {path: 'icons/navigations/pin-circle-check.svg', name: 'pin-circle-check'},
  {path: 'icons/navigations/gps.svg', name: 'gps'},
  {path: 'icons/navigations/route.svg', name: 'route'},
  {path: 'icons/navigations/pin-plus.svg', name: 'pin-plus'},
  {path: 'icons/navigations/earth.svg', name: 'earth'},
  {path: 'icons/navigations/swap-locations.svg', name: 'swap-locations'},
  {path: 'icons/navigations/pin-minus.svg', name: 'pin-minus'},
  {path: 'icons/navigations/earth-navigation.svg', name: 'earth-navigation'},
  {path: 'icons/navigations/globe-pin.svg', name: 'globe-pin'},
  {path: 'icons/navigations/pin-close.svg', name: 'pin-close'},
  {path: 'icons/navigations/map-navigation.svg', name: 'map-navigation'},
  {path: 'icons/navigations/solar-system.svg', name: 'solar-system'},
  {path: 'icons/navigations/pin-check.svg', name: 'pin-check'},
  {path: 'icons/navigations/navigation-framed.svg', name: 'navigation-framed'},
  {path: 'icons/navigations/map.svg', name: 'map'},
  {path: 'icons/navigations/pin-warning.svg', name: 'pin-warning'},
  {path: 'icons/navigations/search-n-pin.svg', name: 'search-n-pin'},
  {path: 'icons/navigations/map-search.svg', name: 'map-search'},
  {path: 'icons/navigations/map-check.svg', name: 'map-check'},
  {path: 'icons/navigations/flag-pin-1.svg', name: 'flag-pin-1'},
  {path: 'icons/navigations/pin-cancel.svg', name: 'pin-cancel'},
  {path: 'icons/navigations/globe-check.svg', name: 'globe-check'},
  {path: 'icons/navigations/flag-pin-2.svg', name: 'flag-pin-2'},
  {path: 'icons/navigations/pin-dashed.svg', name: 'pin-dashed'},
  {path: 'icons/navigations/globe-search.svg', name: 'globe-search'},
  {path: 'icons/navigations/flag-pin-3.svg', name: 'flag-pin-3'},
  {path: 'icons/navigations/pin-framed.svg', name: 'pin-framed'},
  {path: 'icons/navigations/bycicle.svg', name: 'bycicle'},
  {path: 'icons/navigations/location-pin-alt.svg', name: 'location-pin-alt'},
  {path: 'icons/navigations/airplane.svg', name: 'airplane'},
  {path: 'icons/navigations/speed-o-meter.svg', name: 'speed-o-meter'},
  {path: 'icons/navigations/traffic-cone.svg', name: 'traffic-cone'},
  {path: 'icons/navigations/bus.svg', name: 'bus'},
  {path: 'icons/navigations/map.svg', name: 'map'},
  {path: 'icons/navigations/map-pin.svg', name: 'map-pin'},
  {path: 'icons/navigations/hot-air-baloon.svg', name: 'hot-air-baloon'},
  {path: 'icons/navigations/location-pin.svg', name: 'location-pin'},
  {path: 'icons/navigations/globe.svg', name: 'globe'},
  {path: 'icons/navigations/ship-steering-wheel.svg', name: 'ship-steering-wheel'},
  {path: 'icons/navigations/location-pin-alt.svg', name: 'location-pin-alt'},
  {path: 'icons/navigations/car.svg', name: 'car'},
  {path: 'icons/navigations/road.svg', name: 'road'},
  {path: 'icons/navigations/navigation.svg', name: 'navigation'},
  {path: 'icons/navigations/boxes.svg', name: 'boxes'},
  {path: 'icons/navigations/truck.svg', name: 'truck'},
  {path: 'icons/navigations/gas-pump.svg', name: 'gas-pump'},
  {path: 'icons/navigations/zepelin.svg', name: 'zepelin'},
  {path: 'icons/navigations/traffic-lights.svg', name: 'traffic-lights'},
  {path: 'icons/navigations/directions.svg', name: 'directions'},
  {path: 'icons/navigations/gas.svg', name: 'gas'},
  {path: 'icons/navigations/danger.svg', name: 'danger'},
  {path: 'icons/navigations/compas.svg', name: 'compas'}
];
