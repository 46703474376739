import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from '@env/environment';
import { WaterMonitor } from '@btr/modules/water-monitors/models/water-monitors.model';
import { Stall } from '@btr/modules/water-monitors/models/stall.model';

@Injectable({providedIn: 'root'})
export class WaterMonitorsService {
  constructor(private http: HttpClient) {}

  loadAll(): Observable<WaterMonitor[]> {
    return this.http.get<WaterMonitor[]>(`${environment.api()}/water-monitors`);
  }

  loadAvailableStallsForMonitor(): Observable<Stall[]> {
    return this.http.get<Stall[]>(`${environment.api()}/stall/available-for-water-monitor`);
  }

  loadAvailableStalls(): Observable<Stall[]> {
    return this.http.get<Stall[]>(`${environment.api()}/stall/available`);
  }

  save(waterMonitor: WaterMonitor): Observable<WaterMonitor> {
    if (waterMonitor.id) {
      return this.http.patch<WaterMonitor>(`${environment.api()}/water-monitors/${waterMonitor.id}`, waterMonitor);
    }
    return this.http.post<WaterMonitor>(`${environment.api()}/water-monitors`, waterMonitor);
  }

  delete(waterMonitor: WaterMonitor): Observable<void> {
    return this.http.delete<void>(`${environment.api()}/water-monitors/${waterMonitor.id}`);
  }
}
