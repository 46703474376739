import { Component, Input } from '@angular/core';
import { SidebarButton } from '@btr/modules/layout/models/sidebar.model';

@Component({
  selector: 'app-sidebar-button',
  templateUrl: './sidebar-button.component.html',
  styleUrls: ['./sidebar-button.component.scss']
})
export class SidebarButtonComponent {
  @Input() button: SidebarButton;
  @Input() disabled: boolean;
  @Input() path: string;

  get isActive() {
    return this.path?.startsWith(this.button.routerLink);
  }
}
