import { UserAction, UserTypes } from './user.action';
import { User } from '@btr/modules/user/models/user.model';

export interface UserState {
  user: User;
  users: User[];
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  saved: boolean;
}

export const initialState: UserState = {
  user: null,
  users: null,
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
};

export function userReducer(state: UserState = initialState, action: UserAction) {
  switch (action.type) {
    case UserTypes.LoadOne: {
      return {
        ...state,
        user: null,
        loading: true,
        loaded: false
      }
    }
    case UserTypes.LoadOneSuccess: {
      return {
        ...state,
        user: action.payload,
        loading: false,
        loaded: true
      }
    }
    case UserTypes.LoadOneError: {
      return {
        ...state,
        loading: false,
        loaded: false
      }
    }

    case UserTypes.LoadAll: {
      return {
        ...state,
        users: null,
        loading: true,
        loaded: false
      }
    }
    case UserTypes.LoadAllSuccess: {
      return {
        ...state,
        users: action.payload,
        loading: false,
        loaded: true
      }
    }
    case UserTypes.LoadAllError: {
      return {
        ...state,
        users: [],
        loading: false,
        loaded: false
      }
    }

    case UserTypes.Save: {
      return {
        ...state,
        saving: true,
        saved: false
      }
    }
    case UserTypes.SaveSuccess: {
      return {
        ...state,
        saving: false,
        saved: true
      }
    }
    case UserTypes.SaveError: {
      return {
        ...state,
        saving: false,
        saved: false
      }
    }

    default: {
      return state;
    }
  }
}
