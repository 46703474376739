import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[kit-ripple]'
})
export class RippleDirective {
  @Input() rippleActive: boolean = true;

  private maxRippleDiameter = 125;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.rippleActive) {
      return;
    }
    const domEl = this.el.nativeElement;

    const ripple = this.renderer.createElement('span');
    this.renderer.addClass(ripple, 'kit-ripple');

    this.renderer.setStyle(domEl, 'position', 'relative');
    this.renderer.setStyle(domEl, 'overflow', 'hidden');

    const domElStyle = window.getComputedStyle(domEl);
    this.renderer.setStyle(ripple, 'background-color', this.getBackgroundWithoutOpacityValue(domElStyle.backgroundColor));
    this.renderer.setStyle(ripple, 'filter', 'saturate(3)');

    const domElMaxDiam = Math.max(domEl.offsetWidth, domEl.offsetHeight);
    const diameter = domElMaxDiam <= this.maxRippleDiameter ? domElMaxDiam : this.maxRippleDiameter;
    const radius = diameter / 2;
    const x = event.offsetX - radius;
    const y = event.offsetY - radius;

    this.renderer.setStyle(ripple, 'width', `${diameter}px`);
    this.renderer.setStyle(ripple, 'height', `${diameter}px`);
    this.renderer.setStyle(ripple, 'top', `${y}px`);
    this.renderer.setStyle(ripple, 'left', `${x}px`);

    this.renderer.appendChild(domEl, ripple);
    setTimeout(() => {
      this.cleanDom(domEl, ripple);
    }, 700);
  }

  private cleanDom(domEL: ElementRef, rippleEl: ElementRef): void {
    this.renderer.removeChild(domEL, rippleEl);
  }

  private getBackgroundWithoutOpacityValue(elementStyle: string) {
    if (elementStyle.includes('rgba')) {
      const res = elementStyle.substring(elementStyle.indexOf('(') + 1, elementStyle.indexOf(')'));
      const resArray = res.replace(/ /g, '').split(',');
      if (resArray?.[3] === '0') {
        return `rgb(${resArray[0]},${resArray[1]},${resArray[2]})`;
      }
    }
    return elementStyle;
  }
}
