import { ConsumptionAction, ConsumptionTypes } from './consumption.action';
import { Consumption } from '@btr/modules/consumption/models/consumption.model';

export interface ConsumptionState {
  consumptions: Consumption[];
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  saved: boolean;
}

export const initialState: ConsumptionState = {
  consumptions: [],
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
};

export function consumptionReducer(state: ConsumptionState = initialState, action: ConsumptionAction) {
  switch (action.type) {
    case ConsumptionTypes.LoadAllByHorse: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case ConsumptionTypes.LoadAllByHorseSuccess: {
      return {
        ...state,
        consumptions: action.payload,
        loading: false,
        loaded: true
      };
    }
    case ConsumptionTypes.LoadAllByHorseError: {
      return {
        ...state,
        consumptions: null,
        loading: false,
        loaded: false
      };
    }

    case ConsumptionTypes.Save: {
      return {
        ...state,
        saving: true,
        saved: false
      };
    }
    case ConsumptionTypes.SaveSuccess: {
      return {
        ...state,
        saving: false,
        saved: true
      };
    }
    case ConsumptionTypes.SaveError: {
      return {
        ...state,
        saving: false,
        saved: false
      };
    }

    default: {
      return state;
    }
  }
}
