import { WaterMonitorsAction, WaterMonitorsTypes } from './water-monitors.action';
import { WaterMonitor } from '@btr/modules/water-monitors/models/water-monitors.model';
import { Stall } from '@btr/modules/water-monitors/models/stall.model';

export interface WaterMonitorsState {
  waterMonitors: WaterMonitor[];
  waterMonitor: WaterMonitor;
  availableStalls: Stall[];
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  saved: boolean;
}

export const initialState: WaterMonitorsState = {
  waterMonitors: [],
  waterMonitor: null,
  availableStalls: null,
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
};

export function waterMonitorsReducer(state: WaterMonitorsState = initialState, action: WaterMonitorsAction) {
  switch (action.type) {
    case WaterMonitorsTypes.LoadAll: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case WaterMonitorsTypes.LoadAllSuccess: {
      return {
        ...state,
        waterMonitors: action.payload,
        loading: false,
        loaded: true
      };
    }
    case WaterMonitorsTypes.LoadAllError: {
      return {
        ...state,
        waterMonitors: null,
        loading: false,
        loaded: false
      };
    }

    case WaterMonitorsTypes.LoadAvailableStallsForMonitor:
    case WaterMonitorsTypes.LoadAvailableStalls: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case WaterMonitorsTypes.LoadAvailableStallsForMonitorSuccess:
    case WaterMonitorsTypes.LoadAvailableStallsSuccess: {
      return {
        ...state,
        availableStalls: action.payload,
        loading: false,
        loaded: true
      };
    }
    case WaterMonitorsTypes.LoadAvailableStallsForMonitorError:
    case WaterMonitorsTypes.LoadAvailableStallsError: {
      return {
        ...state,
        availableStalls: [],
        loading: false,
        loaded: false
      };
    }

    case WaterMonitorsTypes.Save: {
      return {
        ...state,
        saving: true,
        saved: false
      };
    }
    case WaterMonitorsTypes.SaveSuccess: {
      return {
        ...state,
        saving: false,
        saved: true
      };
    }
    case WaterMonitorsTypes.SaveError: {
      return {
        ...state,
        saving: false,
        saved: false
      };
    }

    case WaterMonitorsTypes.Delete: {
      return {
        ...state,
        saving: true,
        saved: false
      };
    }
    case WaterMonitorsTypes.DeleteSuccess: {
      return {
        ...state,
        saving: false,
        saved: true
      };
    }
    case WaterMonitorsTypes.DeleteError: {
      return {
        ...state,
        saving: false,
        saved: false
      };
    }

    default: {
      return state;
    }
  }
}
