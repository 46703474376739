import { iconsAnimals } from './templates/icon-animals';
import { iconsArrows } from './templates/icon-arrows';
import { iconsBusiness } from './templates/icon-business';
import { iconsClothing } from './templates/icon-clothing';
import { iconsCommerce } from './templates/icon-commerce';
import { iconsDocuments } from './templates/icon-documents';
import { iconsEssentials } from './templates/icon-essentials';
import { iconsFood } from './templates/icon-food';
import { iconsFurniture } from './templates/icon-furniture';
import { iconsInteractions } from './templates/icon-interactions';
import { iconsMedicine } from './templates/icon-medicine';
import { iconsMenus } from './templates/icon-menus';
import { iconsMessages } from './templates/icon-messages';
import { iconsNature } from './templates/icon-nature';
import { iconsNavigations } from './templates/icon-navigations';
import { iconsSoftware } from './templates/icon-software';
import { iconsSounds } from './templates/icon-sounds';
import { iconsTechnology } from './templates/icon-technology';
import { iconsTexts } from './templates/icon-texts';

export const icons: Icon[] = [
  ...iconsAnimals,
  ...iconsArrows,
  ...iconsBusiness,
  ...iconsClothing,
  ...iconsCommerce,
  ...iconsDocuments,
  ...iconsEssentials,
  ...iconsFood,
  ...iconsFurniture,
  ...iconsInteractions,
  ...iconsMedicine,
  ...iconsMenus,
  ...iconsMessages,
  ...iconsNature,
  ...iconsNavigations,
  ...iconsSoftware,
  ...iconsSounds,
  ...iconsTechnology,
  ...iconsTexts
];

export class Icon {
  name: string;
  path: string;
}
