import { Action } from '@ngrx/store';

export enum MetricsTypes {
  SetGallons = '[METRICS] SET GALLONS MODE',
}

/**
 * Set Gallons mode
 */
export class SetMetricsGallons implements Action {
  readonly type = MetricsTypes.SetGallons;
  constructor(public payload: boolean) {}
}

export type MetricsAction = SetMetricsGallons;
