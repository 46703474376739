import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoadingScreenComponent } from '@btr/modules/loading-screen/loading-screen.component';
import { TenantGuard } from '@core/guards/tenant-guard.service';

const routes: Routes = [
  {
    path: 'loading',
    data: {showSidebar: false, showTopbar: false},
    component: LoadingScreenComponent
  },
  {
    path: 'login',
    data: {showSidebar: false, showTopbar: false},
    loadChildren: () => import('@btr/modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'logout',
    data: {showSidebar: false, showTopbar: false},
    loadChildren: () => import('@btr/modules/logout/logout.module').then(m => m.LogoutModule)
  },
  {
    path: '',
    component: null,
    canActivate: [TenantGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('@btr/modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      // {
      //   path: 'notifications',
      //   loadChildren: () => import('@btr/modules/notification/notification.module').then(m => m.NotificationModule)
      // },
      // {
      //   path: 'tasks',
      //   loadChildren: () => import('@btr/modules/task/task.module').then(m => m.TaskModule)
      // },
      {
        path: 'profile',
        loadChildren: () => import('@btr/modules/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'horses',
        loadChildren: () => import('@btr/modules/horse/horse.module').then(m => m.HorseModule)
      },
      {
        path: 'users',
        loadChildren: () => import('@btr/modules/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'exam-forms',
        loadChildren: () => import('@btr/modules/exam-form/exam-form.module').then(m => m.ExamFormModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('@btr/modules/settings/settings.module').then(m => m.SettingsModule)
      }
    ]
  },
  {path: '**', redirectTo: '/loading'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
