export class DateUtils {

  static btrDateRegExp = /^\d{4}-\d\d-\d\d$/; // YYYY-mm-DD
  static btrDateTimeRegExp = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d$/; // YYYY-mm-DDTHH:MM:SS
  static btrDateTimeRegExpMs = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d\.\d\d\dZ$/; // YYYY-mm-DDTHH:MM:SS.sssZ

  static convertObjectsDateValues(body) {
    if (!body || typeof body !== 'object') {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];

      if (DateUtils.isBtrDateTime(value)) {
        body[key] = new Date(value);
      } else if (DateUtils.isBtrDate(value)) {
        const aa: number = parseInt(value.substring(0, 4), 10);
        const mm: number = parseInt(value.substring(5, 7), 10) - 1;
        const dd: number = parseInt(value.substring(8, 10), 10);
        body[key] = new Date(aa, mm, dd);
      } else if (typeof value === 'object') {
        DateUtils.convertObjectsDateValues(value);
      }
    }
  }

  static isBtrDateTime(value) {
    if (!value) {
      return false;
    }
    return DateUtils.btrDateTimeRegExp.test(value) || DateUtils.btrDateTimeRegExpMs.test(value);
  }

  static isBtrDate(value) {
    if (!value) {
      return false;
    }
    return DateUtils.btrDateRegExp.test(value);
  }

  static beforeDate(date: Date, date2: Date): boolean {
    const dateAvant = new Date(date);
    const dateApres = new Date(date2);
    dateAvant.setHours(0, 0, 0, 0);
    dateApres.setHours(0, 0, 0, 0);
    return dateAvant.getTime() < dateApres.getTime();
  }

  static sameDate(date: Date, date2: Date): boolean {
    const dateAvant = new Date(date);
    const dateApres = new Date(date2);
    dateAvant.setHours(0, 0, 0, 0);
    dateApres.setHours(0, 0, 0, 0);
    return dateAvant.getTime() === dateApres.getTime();
  }

  static afterDate(date: Date, date2: Date): boolean {
    const dateAvant = new Date(date);
    const dateApres = new Date(date2);
    dateAvant.setHours(0, 0, 0, 0);
    dateApres.setHours(0, 0, 0, 0);
    return dateAvant.getTime() > dateApres.getTime();
  }

  /**
   * Compare le mois de la date aux mois de l'année
   * @param mois1 COMMENCE à 1 et fini à 12
   * @param mois2 COMMENCE à 1 et fini à 12
   * @param date
   */
  static betweenMonths(mois1: number, mois2: number, date: Date) {
    if (!date) {
      return false;
    }
    return date.getMonth() >= mois1 - 1 && date.getMonth() <= mois2 - 1;
  }

  static sameDateAsToday(date: Date): boolean {
    const today = new Date();
    return this.sameDate(today, date);
  }

  static getDayDifference(date1: Date, date2: Date): number {
    const dateAvant = new Date(date1);
    const dateApres = new Date(date2);
    dateAvant.setHours(0, 0, 0, 0);
    dateApres.setHours(0, 0, 0, 0);

    return (dateAvant.getTime() - dateApres.getTime()) / (1000 * 3600 * 24);
  }

  static dateNoHours(): Date {
    let date: Date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }

  /**
   * Parse une date au format dd/MM/yyyy
   * @param value
   */
  static parseFormatFr(value: any): Date | null {
    if (typeof value === 'string' && value.indexOf('/') > -1) {
      const val: string = value.replace('_', '');

      if (val.length === 10) {
        const str = val.split('/');
        const year = Number(str[2]);
        const month = Number(str[1]) - 1;
        const date = Number(str[0]);
        return new Date(year, month, date);
      }
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  static getMoisString(local: string, monthNumber: number, size: 'short' | 'long'): string {
    const date = new Date();
    date.setMonth(monthNumber);
    return date.toLocaleString(local, { month: size });
  }

  static getAnneeStringNMoinsX(date: Date, x: number) : string {
    const annee = date.getFullYear() - x;
    return annee.toString();
  }
}
