<ng-container>
  <div class="relative">
    <div class="inline-block w-full rounded-md">
      <button class="btr-input w-full {{fc.invalid && (fc.dirty || fc.touched) ? 'input-error' : ''}}" [matMenuTriggerFor]="menu" [disabled]="fc?.disabled">
        <div class="flex items-center space-x-2">
          <div *ngIf="el?.avatar" class="h-7 w-7 rounded-md overflow-hidden shadow-md bg-black">
            <img [src]="el.avatar" [alt]="el.avatar" class="m-auto"/>
          </div>
          <label class="block truncate text-txt-secondary">{{!!el ? el.label : (placeholder ?? "&nbsp;")}}</label>
        </div>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
          <kit-icon name="chevron-down" size="small" [fill]="fc.invalid && (fc.dirty || fc.touched) ? 'red-500' : 'page-primary'"></kit-icon>
        </div>
      </button>
    </div>
  </div>
</ng-container>

<mat-menu #menu="matMenu" class="kit-combo-mat-panel scrollbar">
  <ul *ngIf="elements?.length > 0; else noElements" class="max-h-56">
    <li *ngFor="let element of elements" role="option" class="kit-combo-li {{fc.value === element.value ? 'bg-bg-component-pressed' : ''}}" (click)="onChoose(element)">
      <div class="flex items-center space-x-2">
        <div *ngIf="element?.avatar" class="h-7 w-7 rounded-md overflow-hidden shadow-md bg-black">
          <img [src]="element.avatar" [alt]="element.avatar" class="m-auto"/>
        </div>
        <div class="block truncate text-sm {{fc.value === element.value ? 'font-semibold' : ''}}">{{element.label === "" ? "&nbsp;" : element.label}}</div>
      </div>
    </li>
  </ul>
</mat-menu>

<ng-template #noElements>
  <div class="kit-combo-li italic">{{empty ?? "No element"}}</div>
</ng-template>
