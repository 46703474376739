import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Dashboard, Weather } from '@btr/modules/dashboard/models/dashboard.model';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import * as data from './dashboard.json'; // TODO: Remove this part when Task are ready to go

@Injectable({providedIn: 'root'})
export class DashboardService {
  constructor(private http: HttpClient) {}

  date: Date = new Date();

  load(): Observable<Dashboard> {
    return this.http.get<Dashboard>(`${environment.api()}/dashboard`).pipe(map((dashboard) => {
      const dashboardBarn: Dashboard = {...dashboard};
      // @ts-ignore
      dashboardBarn.tasks = data.default; // TODO: Remove this part when Task are ready to go
      return dashboardBarn;
    }));
  }

  loadWeather(): Observable<Weather> {
    return this.http.get<Weather>(`${environment.api()}/weather`);
  }
}
