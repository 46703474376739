import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'kit-icon',
  templateUrl: './icon.component.html'
})
export class IconComponent {
  @HostBinding('class') class = 'flex';
  @Input() name: string;
  @Input() fill: IconColor | string = IconColor['icon'];
  @Input() size: 'small' | 'default' | 'xs' | 'md' = 'default';
}

export enum IconColor {
  'current' = 'current',
  'icon' = 'icon',
  'secondary' = 'secondary',
  'inverse' = 'inverse',
  'inverse-hover' = 'inverse-hover',
  'disabled' = 'disabled',
  'success' = 'success',
  'success-hover' = 'success-hover',
  'onsuccess' = 'onsuccess',
  'brand' = 'brand',
  'onbrand' = 'onbrand',
  'warning' = 'warning',
  'onwarning' = 'onwarning',
  'info' = 'info',
  'info-hover' = 'info-hover',
  'oninfo' = 'oninfo',
}
