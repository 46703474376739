import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from '../services/profile.service';
import { ProfileTypes, LoadProfile, LoadProfileError, LoadProfileSuccess, SaveProfile, SaveProfileError, SaveProfileSuccess } from '@btr/modules/profile/stores/profile.action';
import { Profile } from '@btr/modules/profile/models/profile.model';
import { SetBarnId } from '@btr/modules/login/stores/tenant.action';
import { StorageService } from '@btr/modules/layout/services/storage.service';

@Injectable()
export class ProfileEffect {
  constructor(private toastrService: ToastrService, private profileService: ProfileService, private storageService: StorageService) {}

  load$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<LoadProfile>(ProfileTypes.Load),
      withLatestFrom(of(this.storageService.getBarnId())),
      switchMap(([_, barnId]) => this.profileService.load().pipe(
        switchMap((profile: Profile) => {
          if (profile?.barnIds?.includes(+barnId)) {
            return [new SetBarnId(+barnId), new LoadProfileSuccess(profile)];
          }
          return [new SetBarnId(profile?.barnIds[0]), new LoadProfileSuccess(profile)];
        }),
        catchError(error => of(new LoadProfileError(error)))
      ))
    )
  );

  save$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<SaveProfile>(ProfileTypes.Save),
      switchMap((action) => this.profileService.save(action.payload).pipe(
          map((profile: Profile) => new SaveProfileSuccess(profile)),
          catchError(error => of(new SaveProfileError(error)))
        )
      )
    )
  );

  saveSuccess$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<SaveProfileSuccess>(ProfileTypes.SaveSuccess),
      tap(_ => {
        this.toastrService.success("Your profile has been saved");
      })
    ), {dispatch: false}
  );

  error$ = createEffect(() =>
      inject(Actions).pipe(
        ofType<SaveProfileError>(ProfileTypes.SaveError),
        tap(_ => {
          this.toastrService.error("An error has occured, try again");
        })),
    {dispatch: false}
  );
}
