<div *ngIf="fc" class="text-txt-secondary">
  <label *ngIf="label" class="block mb-2">{{label}}</label>
  <div class="relative {{state !== 'disabled' ? 'cursor-pointer' : 'cursor-not-allowed'}}">
    <div class="absolute inset-y-0 left-0 flex items-center pl-2.5">
      <kit-icon name="calendar" size="small" fill="{{fc.invalid && (fc.dirty || fc.touched) ? 'warning' : 'icon'}}" (click)="picker.open()"></kit-icon>
    </div>

    <input readonly class="btr-input-date w-full {{fc.invalid && (fc.dirty || fc.touched) ? 'input-error' : ''}}" [min]="min" [max]="max"
           [matDatepicker]="picker" [formControl]="fc" [placeholder]="placeholder" (dateInput)="dateSelected($event)" (click)="calendrierOnly($event, picker)">

    <div *ngIf="clear && !!fc.value" class="absolute inset-y-0 right-0 flex items-center pr-2.5">
      <kit-icon name="close-circle" size="small" fill="{{fc.invalid && (fc.dirty || fc.touched) ? 'warning' : 'icon'}}" (click)="onClear()"></kit-icon>
    </div>

    <mat-datepicker panelClass='uikit-date-picker-panel' #picker [disabled]="fc.disabled"></mat-datepicker>
  </div>
</div>
