import { Icon } from '../icon';

export const iconsCommerce: Icon[] = [
  {path: 'icons/commerce/percent-dashed.svg', name: 'percent-dashed'},
  {path: 'icons/commerce/shopping-basket-circle.svg', name: 'shopping-basket-circle'},
  {path: 'icons/commerce/receipt-plus.svg', name: 'receipt-plus'},
  {path: 'icons/commerce/shopping-basket-close.svg', name: 'shopping-basket-close'},
  {path: 'icons/commerce/shopping-cart-dashed.svg', name: 'shopping-cart-dashed'},
  {path: 'icons/commerce/shopping-bag-framed.svg', name: 'shopping-bag-framed'},
  {path: 'icons/commerce/shopping-basket-check.svg', name: 'shopping-basket-check'},
  {path: 'icons/commerce/shopping-cart-box.svg', name: 'shopping-cart-box'},
  {path: 'icons/commerce/receipt-minus.svg', name: 'receipt-minus'},
  {path: 'icons/commerce/chart-underlined.svg', name: 'chart-underlined'},
  {path: 'icons/commerce/shopping-cart-circle.svg', name: 'shopping-cart-circle'},
  {path: 'icons/commerce/outside-tab.svg', name: 'outside-tab'},
  {path: 'icons/commerce/percent-discounts.svg', name: 'percent-discounts'},
  {path: 'icons/commerce/discount-heart.svg', name: 'discount-heart'},
  {path: 'icons/commerce/receipt-close.svg', name: 'receipt-close'},
  {path: 'icons/commerce/shopping-search.svg', name: 'shopping-search'},
  {path: 'icons/commerce/discount-70.svg', name: 'discount-70'},
  {path: 'icons/commerce/tag-dashed.svg', name: 'tag-dashed'},
  {path: 'icons/commerce/double-shopping-bag.svg', name: 'double-shopping-bag'},
  {path: 'icons/commerce/discount-50.svg', name: 'discount-50'},
  {path: 'icons/commerce/receipt-check.svg', name: 'receipt-check'},
  {path: 'icons/commerce/shopping-cart-dashed.svg', name: 'shopping-cart-dashed'},
  {path: 'icons/commerce/discount-30.svg', name: 'discount-30'},
  {path: 'icons/commerce/fast-shopping.svg', name: 'fast-shopping'},
  {path: 'icons/commerce/scan-code.svg', name: 'scan-code'},
  {path: 'icons/commerce/discount-15.svg', name: 'discount-15'},
  {path: 'icons/commerce/shopping-bag-happy.svg', name: 'shopping-bag-happy'},
  {path: 'icons/commerce/shopping-basket-box.svg', name: 'shopping-basket-box'},
  {path: 'icons/commerce/buy-tag.svg', name: 'buy-tag'},
  {path: 'icons/commerce/chat-percent.svg', name: 'chat-percent'},
  {path: 'icons/commerce/scan-barcode.svg', name: 'scan-barcode'},
  {path: 'icons/commerce/new-tag.svg', name: 'new-tag'},
  {path: 'icons/commerce/shopping-bag-plus.svg', name: 'shopping-bag-plus'},
  {path: 'icons/commerce/shopping-bag-alt.svg', name: 'shopping-bag-alt'},
  {path: 'icons/commerce/search-percentage.svg', name: 'search-percentage'},
  {path: 'icons/commerce/shopping-bag-minus.svg', name: 'shopping-bag-minus'},
  {path: 'icons/commerce/cart-n-phone.svg', name: 'cart-n-phone'},
  {path: 'icons/commerce/scan-qr.svg', name: 'scan-qr'},
  {path: 'icons/commerce/shopping-bag-close.svg', name: 'shopping-bag-close'},
  {path: 'icons/commerce/shopping-basket-plus.svg', name: 'shopping-basket-plus'},
  {path: 'icons/commerce/receipt.svg', name: 'receipt'},
  {path: 'icons/commerce/shopping-bag-check.svg', name: 'shopping-bag-check'},
  {path: 'icons/commerce/shopping-basket-minus.svg', name: 'shopping-basket-minus'},
  {path: 'icons/commerce/receipt-return.svg', name: 'receipt-return'},
  {path: 'icons/commerce/fire-circle.svg', name: 'fire-circle'},
  {path: 'icons/commerce/shopping-basket.svg', name: 'shopping-basket'},
  {path: 'icons/commerce/shopping-cart.svg', name: 'shopping-cart'},
  {path: 'icons/commerce/ticket-alt.svg', name: 'ticket-alt'},
  {path: 'icons/commerce/shopping-bag.svg', name: 'shopping-bag'},
  {path: 'icons/commerce/ticket.svg', name: 'ticket'},
  {path: 'icons/commerce/shop.svg', name: 'shop'}
];
