import { inject, Injectable } from "@angular/core";
import { Observable, of, switchMap, tap } from "rxjs";
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from '@core/store/app.reducer';
import { Go } from '@core/store/router/router.action';
import { StorageService } from '@btr/modules/layout/services/storage.service';
import { selectTenantLoaded } from '@btr/modules/login/stores/tenant.selector';
import { catchError, filter, map, take } from 'rxjs/operators';
import { RefreshToken } from '@btr/modules/login/stores/tenant.action';

@Injectable({providedIn: 'root'})
export class TenantService {
  constructor(protected store: Store<AppState>, private storageService: StorageService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select(selectTenantLoaded).pipe(
      tap(loaded => {
        if (!loaded) {
          const refreshToken: string = this.storageService.getRefreshToken();
          if (!refreshToken) {
            this.store.dispatch(new Go({path: ['/login']}));
          } else {
            this.store.dispatch(new RefreshToken(refreshToken));
          }
        }
      }),
      filter(loaded => !!loaded),
      take(1),
      switchMap(() => this.store.select(selectTenantLoaded)),
      filter(enabled => !!enabled), // Attendre que le profil soit bien chargé
      map((enabled: boolean) => {
        if (!enabled) {
          this.store.dispatch(new Go({path: ['/login']}));
        }
        return enabled;
      }),
      catchError(() => of(false))
    );
  }
}

export const TenantGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  return inject(TenantService).canActivate(next, state);
};
