import {
  Component,
  OnDestroy,
  Inject,
  ViewChild,
  ViewContainerRef,
  ComponentRef,
  EventEmitter,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DialogContainer } from '../models/dialog-container.model';
import { KitDialogConfig } from '../models/dialog-config.model';

@Component({
  selector: 'kit-dialog',
  templateUrl: './dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent implements OnDestroy, AfterViewInit {
  @ViewChild('container', { read: ViewContainerRef }) container: any;

  componentRef: ComponentRef<DialogContainer>;
  sub: Subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: KitDialogConfig,
    private dialogRef: MatDialogRef<DialogComponent>,
    private changeDetectorRef: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    if (this.data?.component) {
      this.createComponent();
    }
  }

  createComponent() {
    this.container?.clear();
    this.componentRef = this.container.createComponent(this.data.component);
    const hostedComponent = this.componentRef.instance;
    hostedComponent.dialogRef = this.dialogRef;

    // Bind les inputs
    if (this.data.inputs) {
      Object.keys(this.data.inputs).forEach(inputName => {
        hostedComponent[inputName] = this.data.inputs[inputName];
      });
    }

    // Bind les output events
    Object.keys(hostedComponent).filter(prop => hostedComponent[prop] instanceof EventEmitter).forEach(output => {
      this[output] = new EventEmitter();
      this.sub.add(hostedComponent[output].subscribe((data: any) => this[output].emit(data)));
    });

    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.componentRef.destroy();
    this.sub?.unsubscribe();
  }

  closeAction() {
    this.dialogRef.close();
  }
}
