import { ExamFormAction, ExamFormTypes } from './exam-form.action';
import { ExamForm, ExamFormSummary } from '@btr/modules/exam-form/models/exam-form.model';

export interface ExamFormState {
  examForm: ExamForm;
  examForms: ExamFormSummary[];
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  saved: boolean;
}

export const initialState: ExamFormState = {
  examForm: null,
  examForms: null,
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
};

export function examFormReducer(state: ExamFormState = initialState, action: ExamFormAction) {
  switch (action.type) {
    case ExamFormTypes.LoadOne: {
      return {
        ...state,
        examForm: null,
        loading: true,
        loaded: false
      }
    }
    case ExamFormTypes.LoadOneSuccess: {
      return {
        ...state,
        examForm: action.payload,
        loading: false,
        loaded: true
      }
    }
    case ExamFormTypes.LoadOneError: {
      return {
        ...state,
        loading: false,
        loaded: false
      }
    }

    case ExamFormTypes.LoadAll: {
      return {
        ...state,
        examForms: null,
        loading: true,
        loaded: false
      }
    }
    case ExamFormTypes.LoadAllSuccess: {
      return {
        ...state,
        examForms: action.payload,
        loading: false,
        loaded: true
      }
    }
    case ExamFormTypes.LoadAllError: {
      return {
        ...state,
        examForms: [],
        loading: false,
        loaded: false
      }
    }

    case ExamFormTypes.Reset: {
      return {
        ...state,
        examForm: null
      }
    }

    case ExamFormTypes.Save:
    case ExamFormTypes.Delete: {
      return {
        ...state,
        saving: true,
        saved: false
      }
    }
    case ExamFormTypes.SaveSuccess:
    case ExamFormTypes.DeleteSuccess:{
      return {
        ...state,
        saving: false,
        saved: true
      }
    }
    case ExamFormTypes.SaveError:
    case ExamFormTypes.DeleteError: {
      return {
        ...state,
        saving: false,
        saved: false
      }
    }

    default: {
      return state;
    }
  }
}
