import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { metaReducers, reducers } from './store/app.reducer';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule, RouterStateSerializer, FullRouterStateSerializer } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DATE_PIPE_DEFAULT_OPTIONS, DatePipe } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { ToastrModule } from 'ngx-toastr';
import { CustomSerializer } from './store/router/router.reducer';
import { BarnEffect } from '@btr/modules/barn/stores/barn.effect';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { DateHttpInterceptor } from './interceptors/date-http.interceptor';
import { RouterEffect } from '@core/store/router/router.effect';
import { HorseEffect } from '@btr/modules/horse/stores/horse.effect';
import { ProfileEffect } from '@btr/modules/profile/stores/profile.effect';
import { TenantEffect } from '@btr/modules/login/stores/tenant.effect';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { UserEffect } from '@btr/modules/user/stores/user.effect';
import { DashboardEffect } from '@btr/modules/dashboard/stores/dashboard.effect';
import { NoteEffect } from '@btr/modules/note/stores/note.effect';
import { WaterMonitorsEffect } from '@btr/modules/water-monitors/stores/water-monitors.effect';
import { ConsumptionEffect } from '@btr/modules/consumption/stores/consumption.effect';
import { TaskEffect } from '@btr/modules/task/stores/task.effect';
import { ExamFormEffect } from '@btr/modules/exam-form/stores/exam-form.effect';

let modules = [
  StoreModule.forRoot(reducers, {
    metaReducers,
    runtimeChecks: {
      strictStateImmutability: true,
      strictActionImmutability: true
    }
  }),
  ToastrModule.forRoot({positionClass: 'toast-bottom-center'}),
  EffectsModule.forRoot([
    BarnEffect,
    ConsumptionEffect,
    DashboardEffect,
    ExamFormEffect,
    HorseEffect,
    NoteEffect,
    ProfileEffect,
    TenantEffect,
    TaskEffect,
    UserEffect,
    WaterMonitorsEffect,
    RouterEffect
  ]),
  StoreRouterConnectingModule.forRoot({serializer: FullRouterStateSerializer}),
  StoreDevtoolsModule.instrument({maxAge: 50}),
  BrowserAnimationsModule
];

@NgModule({
  imports: [...modules],
  exports: [BrowserAnimationsModule, ReactiveFormsModule],
  providers: [
    {provide: LOCALE_ID, useValue: 'en-CA'},
    {provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: {timezone:'+0000'}},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: DateHttpInterceptor, multi: true},
    {provide: RouterStateSerializer, useClass: CustomSerializer},
    DatePipe
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}

function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(
      `${moduleName} module has already been loaded elsewhere. Do not attempt to load it many times (only in AppModule)`
    );
  }
}
