<div class="sidebar-btn {{disabled ? 'cursor-not-allowed' : 'cursor-pointer'}}"
     tooltip
     tooltipPosition="right"
     [tooltipEnabled]="disabled"
     [tooltipText]="'Unavailable'"
     [ngClass]="{'bg-bg-inverse-pressed': isActive}"
     [routerLink]="!isActive && !disabled ? button.routerLink : null">
  <kit-icon class="sidebar-btn-icon" fill="inverse" [name]="button.icon"></kit-icon>
  <div class="sidebar-btn-text">{{button.text}}</div>
</div>
