import { Action } from '@ngrx/store';
import { User, UserRequest } from '../models/user.model';

export enum UserTypes {
  LoadOne = '[USER] LOAD ONE',
  LoadOneSuccess = '[USER] LOAD ONE SUCCESS',
  LoadOneError = '[USER] LOAD ONE ERROR',

  LoadMe = '[USER] LOAD ME',
  LoadMeSuccess = '[USER] LOAD ME SUCCESS',
  LoadMeError = '[USER] LOAD ME ERROR',

  LoadAll = '[USER] LOAD ALL',
  LoadAllSuccess = '[USER] LOAD ALL SUCCESS',
  LoadAllError = '[USER] LOAD ALL ERROR',

  Save = '[USER] SAVE',
  SaveSuccess = '[USER] SAVE SUCCESS',
  SaveError = '[USER] SAVE ERROR',
}

/**
 * Load One
 */
export class LoadOneUser implements Action {
  readonly type = UserTypes.LoadOne;
  constructor(public payload: number) {}
}

export class LoadOneUserSuccess implements Action {
  readonly type = UserTypes.LoadOneSuccess;
  constructor(public payload: User) {}
}

export class LoadOneUserError implements Action {
  readonly type = UserTypes.LoadOneError;
  constructor(public payload: any) {}
}

/**
 * Load Me
 */
export class LoadMe implements Action {
  readonly type = UserTypes.LoadMe;
}

export class LoadMeSuccess implements Action {
  readonly type = UserTypes.LoadMeSuccess;
  constructor(public payload: User) {}
}

export class LoadMeError implements Action {
  readonly type = UserTypes.LoadMeError;
  constructor(public payload: any) {}
}

/**
 * Load All
 */
export class LoadAllUser implements Action {
  readonly type = UserTypes.LoadAll;
}

export class LoadAllUserSuccess implements Action {
  readonly type = UserTypes.LoadAllSuccess;
  constructor(public payload: User[]) {}
}

export class LoadAllUserError implements Action {
  readonly type = UserTypes.LoadAllError;
  constructor(public payload: any) {}
}

/**
 * Save
 */
export class UserSave implements Action {
  readonly type = UserTypes.Save;
  constructor(public payload: UserRequest) {}
}

export class UserSaveSuccess implements Action {
  readonly type = UserTypes.SaveSuccess;
  constructor(public payload: User) {}
}

export class UserSaveError implements Action {
  readonly type = UserTypes.SaveError;
  constructor(public payload: any) {}
}

export type UserAction = LoadOneUser
  | LoadOneUserSuccess
  | LoadOneUserError
  | LoadMe
  | LoadMeSuccess
  | LoadMeError
  | LoadAllUser
  | LoadAllUserSuccess
  | LoadAllUserError
  | UserSave
  | UserSaveSuccess
  | UserSaveError;
