import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HorseService } from '@btr/modules/horse/services/horse.service';
import { HorseFavoriteSave, HorseFavoriteSaveError, HorseFavoriteSaveSuccess, HorseSave, HorseSaveError, HorseSaveSuccess, HorseTypes, LoadAllHorse, LoadAllHorseError, LoadAllHorseSuccess, LoadOneHorse, LoadOneHorseError, LoadOneHorseSuccess } from '@btr/modules/horse/stores/horse.action';
import { Horse } from '@btr/modules/horse/models/horse.model';

@Injectable()
export class HorseEffect {
  constructor(private toastrService: ToastrService, private horseService: HorseService) {}

  loadAll$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<LoadAllHorse>(HorseTypes.LoadAll),
      switchMap((action) => this.horseService.loadAll().pipe(
          map((horses: Horse[]) => new LoadAllHorseSuccess(horses)),
          catchError(error => of(new LoadAllHorseError(error)))
        )
      )
    )
  );

  loadOne$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<LoadOneHorse>(HorseTypes.LoadOne),
      switchMap((action) => this.horseService.loadOne(action.payload).pipe(
          map((horse: Horse) => new LoadOneHorseSuccess(horse)),
          catchError(error => of(new LoadOneHorseError(error)))
        )
      )
    )
  );

  save$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<HorseSave>(HorseTypes.Save),
      switchMap((action) => this.horseService.save(action.payload).pipe(
          map((horse: Horse) => new HorseSaveSuccess(horse)),
          catchError(error => of(new HorseSaveError(error)))
        )
      )
    )
  );

  saveSuccess$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<HorseSaveSuccess>(HorseTypes.SaveSuccess),
      tap((action) => {
        this.toastrService.success("Changes saved");
      })
    ), {dispatch: false}
  );

  saveFavorite$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<HorseFavoriteSave>(HorseTypes.SaveFavorite),
      switchMap((action) => this.horseService.saveFavorite(action.payload).pipe(
          switchMap((horse: Horse) => [new HorseFavoriteSaveSuccess(horse), new LoadAllHorse()]),
          catchError(error => of(new HorseFavoriteSaveError(error)))
        )
      )
    )
  );

  error$ = createEffect(() =>
    inject(Actions).pipe(
      ofType(HorseTypes.LoadAllError, HorseTypes.LoadOneError, HorseTypes.SaveError, HorseTypes.SaveFavoriteError),
      tap(_ => {
        this.toastrService.error("An error has occured, try again");
      })
    ), {dispatch: false}
  );
}
