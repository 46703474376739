import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from '@btr/modules/dashboard/services/dashboard.service';
import { DashboardTypes, LoadDashboard, LoadDashboardError, LoadDashboardSuccess, LoadWeather, LoadWeatherError, LoadWeatherSuccess } from '@btr/modules/dashboard/stores/dashboard.action';
import { Dashboard, Weather } from '@btr/modules/dashboard/models/dashboard.model';

@Injectable()
export class DashboardEffect {
  constructor(private toastrService: ToastrService, private dashboardService: DashboardService) {}

  load$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<LoadDashboard>(DashboardTypes.Load),
      switchMap((action) => this.dashboardService.load().pipe(
          map((dashboard: Dashboard) => new LoadDashboardSuccess(dashboard)),
          catchError(error => of(new LoadDashboardError(error)))
        )
      )
    )
  );

  loadWeather$ = createEffect(() =>
    inject(Actions).pipe(
      ofType<LoadWeather>(DashboardTypes.LoadWeather),
      switchMap((action) => this.dashboardService.loadWeather().pipe(
          map((dashboard: Weather) => new LoadWeatherSuccess(dashboard)),
          catchError(error => of(new LoadWeatherError(error)))
        )
      )
    )
  );

  error$ = createEffect(() =>
      inject(Actions).pipe(
        ofType<LoadDashboardError | LoadWeatherError>(DashboardTypes.LoadError, DashboardTypes.LoadWeatherError),
        tap(_ => {
          this.toastrService.error("An error has occured, try again");
        })),
    {dispatch: false}
  );
}
