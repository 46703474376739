import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { GlobalErrorHandler } from '@core/interceptors/global-error-handler';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { CoreModule } from '@core/core.module';
import { LoadingScreenComponent } from '@btr/modules/loading-screen/loading-screen.component';
import { LayoutModule } from '@btr/modules/layout/layout.module';
import { KitModule } from '@kit/kit.module';
import { ServiceWorkerModule } from '@angular/service-worker';

const materialModules = [
  MatNativeDateModule,
  MatDialogModule,
  MatIconModule,
  MatFormFieldModule,
  MatCheckboxModule,
  MatTooltipModule,
  MatChipsModule,
  MatSlideToggleModule,
  MatDatepickerModule,
  MatSelectModule,
  MatTableModule
];

@NgModule({
  declarations: [
    AppComponent,
    LoadingScreenComponent
  ],
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    KitModule,
    LayoutModule,
    ServiceWorkerModule.register('worker.client.js', {
      enabled: true,
      registrationStrategy: 'registerWhenStable:30000'
    }),

    ...materialModules
  ],
  exports: [...materialModules],
  providers: [
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {provide: MatDialogRef, useValue: {}},
    DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
