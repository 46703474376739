import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Note } from '@btr/modules/note/models/note.model';
import { environment } from '@env/environment';

@Injectable({providedIn: 'root'})
export class NoteService {
  constructor(private http: HttpClient) {}

  loadAll(horseId: string): Observable<Note[]> {
    return this.http.get<Note[]>(`${environment.api()}/horse/${horseId}/notes`);
  }

  save(note: Note, horseId: string): Observable<Note> {
    if (note.id) {
      return this.http.patch<Note>(`${environment.api()}/horse/${horseId}/notes/${note.id}`, note);
    }
    return this.http.post<Note>(`${environment.api()}/horse/${horseId}/notes`, note);
  }

  delete(noteId: string, horseId: string): Observable<void> {
    return this.http.delete<void>(`${environment.api()}/horse/${horseId}/notes/${noteId}`);
  }
}
