import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { environment } from '@env/environment';
import { ToastrService } from 'ngx-toastr';
import { AppState } from '@core/store/app.reducer';
import { Go } from '@core/store/router/router.action';
import { StorageService } from '@btr/modules/layout/services/storage.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>, private storageService: StorageService, private toastrService: ToastrService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers: HttpHeaders = req.headers;
    headers = headers.delete('skip-error-interceptor');

    return this.interceptHttpError(next, req.clone({headers}));
  }

  /**
   * Intercept every response from the server and dispatch a toastr accordingly
   * Will certainly disconnect the user if 503 or 4XX
   */
  private interceptHttpError(next: HttpHandler, req: HttpRequest<any>): Observable<any> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (req.url.startsWith(environment.keycloak())) {
          if (error.error?.error_description === "Token is not active") {
            this.storageService.clearStorage(); // Too late to refresh the token. Hard reset of app
          }
          this.store.dispatch(new Go({path: ['/login']}));
        }

        if (!req.url.startsWith(environment.api())) {
          return throwError(() => new Error(error.message));
        }

        switch (error.status) {
          case 500:
            if (error.error && error.error instanceof String && error.error.includes('request was larger than')) {
              return throwError(() => new Error('File transmitted has a size larger than 10Mb'));
            }
            return throwError(() => new Error(error.message));
          case 503:
          case 400:
            return throwError(() => new Error(error.message));
          case 401:
            this.toastrService.error('You are not authorized', 'Authentication failed');
            this.store.dispatch(new Go({path: ['/login']}));
            return throwError(() => new Error(error.message));
          case 404:
            this.toastrService.error('The page requested is not available', 'Technical error');
            return throwError(() => new Error(error.message));
          default:
            return throwError(() => new Error(error.message));
        }
      })
    );
  }
}
