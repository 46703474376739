import { Action } from '@ngrx/store';
import { Profile } from '../models/profile.model';

export enum ProfileTypes {
  Load = '[PROFILE] LOAD',
  LoadSuccess = '[PROFILE] LOAD SUCCESS',
  LoadError = '[PROFILE] LOAD ERROR',

  Save = '[PROFILE] SAVE',
  SaveSuccess = '[PROFILE] SAVE SUCCESS',
  SaveError = '[PROFILE] SAVE ERROR',
}

/**
 * Load
 */
export class LoadProfile implements Action {
  readonly type = ProfileTypes.Load;
}

export class LoadProfileSuccess implements Action {
  readonly type = ProfileTypes.LoadSuccess;
  constructor(public payload: Profile) {}
}

export class LoadProfileError implements Action {
  readonly type = ProfileTypes.LoadError;
  constructor(public payload: any) {}
}

/**
 * Save
 */
export class SaveProfile implements Action {
  readonly type = ProfileTypes.Save;
  constructor(public payload: Profile) {}
}

export class SaveProfileSuccess implements Action {
  readonly type = ProfileTypes.SaveSuccess;
  constructor(public payload: Profile) {}
}

export class SaveProfileError implements Action {
  readonly type = ProfileTypes.SaveError;
  constructor(public payload: any) {}
}

export type ProfileAction = LoadProfile
  | LoadProfileSuccess
  | LoadProfileError
  | SaveProfile
  | SaveProfileSuccess
  | SaveProfileError;
