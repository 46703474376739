import { Icon } from '../icon';

export const iconsFood: Icon[] = [
  {path: 'icons/food/soup.svg', name: 'soup'},
  {path: 'icons/food/bread.svg', name: 'bread'},
  {path: 'icons/food/tea-cup.svg', name: 'tea-cup'},
  {path: 'icons/food/coffee.svg', name: 'coffee'},
  {path: 'icons/food/martini.svg', name: 'martini'},
  {path: 'icons/food/double-soup.svg', name: 'double-soup'},
  {path: 'icons/food/pan.svg', name: 'pan'},
  {path: 'icons/food/casserole.svg', name: 'casserole'},
  {path: 'icons/food/pineapple.svg', name: 'pineapple'},
  {path: 'icons/food/avocado.svg', name: 'avocado'},
  {path: 'icons/food/champagne.svg', name: 'champagne'},
  {path: 'icons/food/coffee-cup.svg', name: 'coffee-cup'},
  {path: 'icons/food/pear.svg', name: 'pear'},
  {path: 'icons/food/cherry.svg', name: 'cherry'},
  {path: 'icons/food/cookie.svg', name: 'cookie'},
  {path: 'icons/food/watermelon.svg', name: 'watermelon'},
  {path: 'icons/food/tea-bag.svg', name: 'tea-bag'},
  {path: 'icons/food/dish.svg', name: 'dish'},
  {path: 'icons/food/glove.svg', name: 'glove'},
  {path: 'icons/food/wine-bottle.svg', name: 'wine-bottle'},
  {path: 'icons/food/chicken.svg', name: 'chicken'},
  {path: 'icons/food/carrot.svg', name: 'carrot'},
  {path: 'icons/food/wine-bottle-2.svg', name: 'wine-bottle-2'},
  {path: 'icons/food/ice-cream.svg', name: 'ice-cream'},
  {path: 'icons/food/lemon.svg', name: 'lemon'},
  {path: 'icons/food/meat.svg', name: 'meat'},
  {path: 'icons/food/pizza.svg', name: 'pizza'},
  {path: 'icons/food/chefs-hat.svg', name: 'chefs-hat'},
  {path: 'icons/food/wine-bottle-and-glass.svg', name: 'wine-bottle-and-glass'},
  {path: 'icons/food/beer.svg', name: 'beer'},
  {path: 'icons/food/tea-bag.svg', name: 'tea-bag'},
  {path: 'icons/food/salt-&-peper.svg', name: 'salt-&-peper'},
  {path: 'icons/food/donut.svg', name: 'donut'},
  {path: 'icons/food/sauce.svg', name: 'sauce'},
  {path: 'icons/food/dishes.svg', name: 'dishes'},
  {path: 'icons/food/burger.svg', name: 'burger'},
  {path: 'icons/food/ice-cream.svg', name: 'ice-cream'},
  {path: 'icons/food/rice.svg', name: 'rice'},
  {path: 'icons/food/scale.svg', name: 'scale'},
  {path: 'icons/food/cake.svg', name: 'cake'}
];
