import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kit-upload',
  templateUrl: './upload.component.html',
})
export class UploadComponent {
  @Input() files: FileBack[] = [];
  @Input() limit: number = 1;
  @Input() fileSize: number = 5;
  @Output() error: EventEmitter<string> = new EventEmitter<string>();
  @Output() onFiles: EventEmitter<FileBack[]> = new EventEmitter<FileBack[]>();
  @Output() open: EventEmitter<number> = new EventEmitter<number>();

  acceptTypes: string = 'image/jpeg,image/jpg,image/png';

  handleFileInput(event: any) {
    const files: FileList = event.files;
    if (!files || files.length <= 0) {
      return;
    }
    const myFile: File = files.item(0);

    event.value = ''; // Allow to add the same file again

    if (!!this.limit && (this.files.length + 1) > this.limit) {
      this.error.emit("Only " + this.limit + " files allowed");
      return;
    }

    if (myFile.size > +(this.fileSize + "000000")) {
      this.error.emit("File size must be under " + this.fileSize + "Mb");
      return;
    }

    this.files.push({file: myFile});
    this.onFiles.emit(this.files);
  }
}

export class FileBack {
  file: File;
  id?: number;
}
